import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import * as firebase from "firebase";
import Button from "@material-ui/core/Button";

class AircraftDeleteView extends Component {
  constructor(props) {
    super(props);
    this.deleteAircraft = this.deleteAircraft.bind(this);
  }
  deleteAircraft() {
    var db = firebase.firestore();
    db.collection("aircraft")
      .doc(this.props.resourceID)
      .delete()
      .then(function () {
        console.log("Document successfully deleted!");
        window.location.reload(true);
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  }
  render() {
    return (
      <Card>
        <h1>Are you sure you want to delete {this.props.resourceID}?</h1>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={this.deleteAircraft}
        >
          Yes
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={this.props.onClose}
        >
          No
        </Button>
      </Card>
    );
  }
}
export default AircraftDeleteView;
