import React, { Component } from "react";
import * as firebase from "firebase";
import Button from '@mui/material/Button'
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Backdrop from '@mui/material/Backdrop'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close';
class PaymentScreen extends Component {
    constructor(props){
        super(props)
        this.state = {
            date : props.date,
            course : props.course,
            unit : props.unit,
            instructor : props.instructor,
            aircraftMake : props.aircraftMake,
            durationOfFlight : parseFloat(props.durationOfFlight),
            hobbsRate : parseFloat(props.hobbsRate),
            oralRate : parseFloat(props.oralRate),
            oralTime : parseFloat(props.oralTime),
            paid : props.paid,
            totalActivityCost : props.totalActivityCost,
            backdropOpen : false,
            fuelRate : 44
        }
        this.toggleBackdrop = this.toggleBackdrop.bind(this)
    }

    toggleBackdrop(){
        this.setState({backdropOpen : !this.state.backdropOpen})
        console.log(this.state.durationOfFlight)
    }
    render(){
        return(
                <TableRow>
                    <TableCell>{this.state.date}</TableCell>
                    <TableCell>{this.state.course}</TableCell>
                    <TableCell>{this.state.unit}</TableCell>
                    <TableCell>{this.state.instructor}</TableCell>
                    <TableCell><Button variant="contained" onClick = {this.toggleBackdrop}>Pay</Button></TableCell>
                    <Backdrop open = {this.state.backdropOpen}>
                        <Card style={{width: "75%",align: "center",marginLeft: "15%"}}>
                            <Grid container>
                                <Grid item xs = {11}>
                                    <h1>Pay Activity - {this.state.date}</h1>
                                </Grid>
                                <Grid item xs = {1}>
                                    <IconButton style = {{marginLeft : '50%'}} onClick = {this.toggleBackdrop}><CloseIcon/></IconButton>
                                </Grid>
                            </Grid>
                            <Divider></Divider>
                            <h2>Activity Info</h2>
                            <Grid container>
                                <Grid item xs = {3}><TextField label = "Instructor" value = {this.state.instructor} disabled></TextField></Grid>
                                <Grid item xs = {3}><TextField label = "Course" value = {this.state.course} disabled></TextField></Grid>
                                <Grid item xs = {3}><TextField label = "Unit" value = {this.state.unit} disabled></TextField></Grid>
                                <Grid item xs = {3}><TextField label = "Activity Duration" value = {this.state.durationOfFlight + this.state.oralTime} disabled></TextField></Grid>
                            </Grid>
                            <Divider></Divider>
                            <Grid container>
                                <Grid item xs = {2.4}>
                                    <h3>Fuel Surchage</h3>
                                    <TextField label={"Fuel Surcharge @ $" + this.state.fuelRate + "/hr " + this.state.durationOfFlight+' hr'} value={this.state.durationOfFlight*this.state.fuelRate} disabled variant="outlined" type="number"></TextField>
                                </Grid>
                                <Grid item xs = {2.4}>
                                    <h3>Aircraft Time</h3>
                                    <TextField label={"Hobbs Time @ $" + this.state.hobbsRate +"/hr " + this.state.durationOfFlight+' hr'} variant="outlined" type="number" value={this.state.durationOfFlight * this.state.hobbsRate} disabled></TextField>
                                </Grid>
                                <Grid item xs = {2.4}>
                                    <h3>Oral Time</h3>
                                    <TextField label={"Oral Time @ $" + this.state.oralRate + "/hr"} variant="outlined" type="number" value={this.state.oralTime * this.state.oralRate} disabled></TextField>
                                </Grid>
                                <Grid item xs = {2.4}>
                                    <h3>Service Fee</h3>
                                    <TextField label="Service Fee @ $1.5/hr" variant="outlined" type="number" value={this.state.oralTime * 1.5 + this.state.durationOfFlight * 1.5} disabled></TextField>
                                </Grid>
                                <Grid item xs = {2.4}>
                                    <h3>Total Activity Cost</h3>
                                    <TextField label="Activity Cost" variant="outlined" type="number" value={this.state.totalActivityCost} disabled></TextField>
                                </Grid>
                            </Grid>
                            <Button variant="contained" style={{marginTop : '5px', width : '100%'}}>Pay Activity</Button>
                        </Card>
                        
                    </Backdrop>
                </TableRow>

        
        )
    }
}

export default PaymentScreen