import React, { Component } from "react";
import * as firebase from "firebase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import GridList from "@material-ui/core/GridList";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
class InstructorActivityGrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityOGUMI: this.props.activityCI,
      activityObjective: this.props.activityObjective,
      order: this.props.order,
      grade: this.props.grade,
      comment: this.props.comment,
      commentError: false,
      updateUnitGrade: this.props.updateUnitGrades,
      previousGrade : props.previousGrade,
      disableCheck : props.disableCheck,
      spacer : props.spacer
    };
    this.updateGrade = this.updateGrade.bind(this);
    this.updateComment = this.updateComment.bind(this);
    this.sendUpdate = this.sendUpdate.bind(this);
  }
  updateGrade(event) {
    this.setState({ grade: event.target.value });
    console.log(event.target.value);
    if (event.target.value == "U") {
      this.setState({ commentError: true }, () => this.sendUpdate());
    } else {
      this.setState({ commentError: false }, () => this.sendUpdate());
    }
  }
  sendUpdate() {
    this.props.update(
      this.state.order,
      this.state.grade,
      this.state.comment,
      this.state.commentError,
      this.state.activityObjective
    );
  }
  checkComment() {
    if (this.commentError == true && this.comment == "") {
      return false;
    } else {
      return true;
    }
  }
  updateComment(event) {
    if (event.target.value != "") {
      this.setState({ comment: event.target.value }, () => this.sendUpdate());
      this.setState({ commentError: false });
    } else {
      this.setState({ commentError: true });
    }
  }
  render() {

    if((this.state.previousGrade != "U" && this.state.previousGrade != "I") && this.state.previousGrade != ""){
      if (this.state.activityOGUMI == true) {

        return (
          <Card
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <FormControl
              style={{
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <FormLabel component="legend">
                {this.props.activityObjective + this.props.spacer + this.state.previousGrade}
              </FormLabel>
              <RadioGroup
                aria-label="ActivityObjective"
                name="ActivityObjective"
                onChange={this.updateGrade}
                value={this.state.grade}
                disabled
              >
                <div>
                  <FormControlLabel value="O" control={<Radio />} label="O" disabled={this.state.disableCheck}/>
                  <FormControlLabel value="G" control={<Radio />} label="G" disabled={this.state.disableCheck}/>
                  <FormControlLabel value="M" control={<Radio />} label="M" disabled={this.state.disableCheck}/>
                  <FormControlLabel value="U" control={<Radio />} label="U" disabled={this.state.disableCheck}/>
                  <FormControlLabel value="I" control={<Radio />} label="I" disabled={this.state.disableCheck}/>
                  <FormControlLabel value="N/A" control={<Radio />} label="N/A" disabled={this.state.disableCheck}/>
                  <TextField
                    id="activityComments"
                    error={this.state.commentError}
                    label="Comments"
                    placeholder=""
                    multiline
                    onChange={this.updateComment}
                    style={{ justifyContent: "center", alignItems: "center" }}
                    disabled={this.state.disableCheck}
                    defaultValue = {this.state.comment}
                  ></TextField>
                </div>
              </RadioGroup>
            </FormControl>
          </Card>
        );
      } else {
        return (
          <Card
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <FormControl
              style={{
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <FormLabel component="legend">
                {this.props.activityObjective + this.state.spacer + this.state.previousGrade}
              </FormLabel>
              <RadioGroup
                aria-label="ActivityObjective"
                name="ActivityObjective"
                onChange={this.updateGrade}
              >
                <div>
                  <FormControlLabel value="C" control={<Radio />} label="C" disabled={this.state.disableCheck}/>
                  <FormControlLabel value="I" control={<Radio />} label="I" disabled={this.state.disableCheck}/>
                  <FormControlLabel value="N/A" control={<Radio />} label="N/A" disabled={this.state.disableCheck}/>
                  <TextField
                    id="activityComments"
                    error={this.state.commentError}
                    label="Comments"
                    placeholder=""
                    multiline
                    onChange={this.updateComment}
                    style={{ justifyContent: "center", alignItems: "center" }}
                    disabled={this.state.disableCheck}
                    defaultValue = {this.state.comment}
                  ></TextField>
                </div>
              </RadioGroup>
            </FormControl>
          </Card>
        );
      }
    }else{




    if (this.state.activityOGUMI == true) {

      return (
        <Card
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <FormControl
            style={{
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <FormLabel component="legend">
              {this.props.activityObjective + this.state.spacer + this.state.previousGrade}
            </FormLabel>
            <RadioGroup
              aria-label="ActivityObjective"
              name="ActivityObjective"
              onChange={this.updateGrade}
              value={this.state.grade}
              disabled
            >
              <div>
                <FormControlLabel value="O" control={<Radio />} label="O" disabled={this.state.disableCheck}/>
                <FormControlLabel value="G" control={<Radio />} label="G" disabled={this.state.disableCheck}/>
                <FormControlLabel value="M" control={<Radio />} label="M" disabled={this.state.disableCheck}/>
                <FormControlLabel value="U" control={<Radio />} label="U" disabled={this.state.disableCheck}/>
                <FormControlLabel value="I" control={<Radio />} label="I" disabled={this.state.disableCheck}/>
                <TextField
                  id="activityComments"
                  error={this.state.commentError}
                  label="Comments"
                  placeholder=""
                  multiline
                  onChange={this.updateComment}
                  style={{ justifyContent: "center", alignItems: "center" }}
                  disabled={this.state.disableCheck}
                  defaultValue = {this.state.comment}
                ></TextField>
              </div>
            </RadioGroup>
          </FormControl>
        </Card>
      );
    } else {
      return (
        <Card
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <FormControl
            style={{
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <FormLabel component="legend">
              {this.props.activityObjective + this.state.spacer + this.state.previousGrade}
            </FormLabel>
            <RadioGroup
              aria-label="ActivityObjective"
              name="ActivityObjective"
              onChange={this.updateGrade}
            >
              <div>
                <FormControlLabel value="C" control={<Radio />} label="C" disabled={this.state.disableCheck}/>
                <FormControlLabel value="I" control={<Radio />} label="I" disabled={this.state.disableCheck}/>
                <TextField
                  id="activityComments"
                  error={this.state.commentError}
                  label="Comments"
                  placeholder=""
                  multiline
                  onChange={this.updateComment}
                  style={{ justifyContent: "center", alignItems: "center" }}
                  disabled={this.state.disableCheck}
                  defaultValue = {this.state.comment}
                ></TextField>
              </div>
            </RadioGroup>
          </FormControl>
        </Card>
      );
    }
  }
    
  }
}

InstructorActivityGrade.defaultProps = {
  previousGrade : '',
  spacer : '',
  comment : ''
}

export default InstructorActivityGrade;
