import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Navigator from './componets/navigation'
import Hook from './componets/test'
import Paperbase from './components/Paperbase'
import Paperbase2 from './components/Paperbase2'
import {BrowserRouter as Router} from 'react-router-dom'
import * as firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import Login from './components/login'
const firebaseConfig = {
  apiKey: "AIzaSyDSK29MYp1-Jq21hICa7aqTRH05BW6RDJ4",
  authDomain: "newflightsystem.firebaseapp.com",
  databaseURL: "https://newflightsystem.firebaseio.com",
  projectId: "newflightsystem",
  storageBucket: "newflightsystem.appspot.com",
  messagingSenderId: "163803743182",
  appId: "1:163803743182:web:44a7654a346414c1ed619f",
  measurementId: "G-R1VCTD8G9V"
};
firebase.initializeApp(firebaseConfig)
//export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export default firebase
/*
firebase.auth().signInWithEmailAndPassword("john.rees@seerflightsystems.com", "Johnrees1").then(function(){
  console.log(firebase.auth().currentUser)
  
}).catch(function(error) {
  // Handle Errors here.
  var errorCode = error.code;
  var errorMessage = error.message;
  console.log(errorCode)
  console.log(errorMessage)
  console.log(firebase.auth.currentUser)
  // ...
});
*/


if(firebase.auth().currentUser == null){
  
}else{
  ReactDOM.render(
    <Router>
    <Paperbase2></Paperbase2>
    </Router>,
    document.getElementById('root')
  );
}

firebase.auth().onAuthStateChanged(function(user){
  if(user){
    ReactDOM.render(
    <Router>
    <Paperbase2></Paperbase2>
    </Router>,
    document.getElementById('root')
  );
  }else{
    ReactDOM.render(
      <Login></Login>,
      document.getElementById('root')
    )
  }
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
