import React, { Component } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button'
import { Typography } from "@mui/material";
import Draggable from 'react-draggable'
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import * as firebase from "firebase";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import SchedulerActivity from './schedulerActivity'
class DayScheduler extends Component {
    constructor(props){
        super(props)
        this.state = {
            scheduleDate : '12-13-2021',
            resource : 'all',
            acRows : []
        }
        this.incrementDate = this.incrementDate.bind(this)
        this.decrementDate = this.decrementDate.bind(this)
        this.refetchData = this.refetchData.bind(this)
        this.changeResource = this.changeResource.bind(this)
        this.setSchedule = this.setSchedule.bind(this)
      }
    componentDidMount(){
        let d = new Date()
        let dateString = (d.getMonth()+1)+'-'+(d.getDate()+1)+'-'+d.getFullYear()
        this.setState({scheduleDate:dateString})
        var acCarry = []
        var a = this
        let db = firebase.firestore()
        //let acDocRef = db.collection('aircraft').where('aircraftType','==',this.state.resource)
        let acDocRef = db.collection('aircraft')
        acDocRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                acCarry.push(<SchedulerActivity unit = {doc.data().unit} course = {doc.data().unit} instructor = {doc.data().instructor} student = {doc.data().student} scheduleDate = {a.state.scheduleDate} tailNumber = {doc.id} hobbsTime = {doc.data().hobbsTime} isDowned = {doc.data().isDowned} nextEventTime = {doc.data().nextEventTime}></SchedulerActivity>)
            })
            a.setState({acRows : acCarry})
        })
    }
    getActivites(){
        let db = firebase.firestore()
        //let actiivtyRef = db.collection('schedules').doc(this.state.scheduleDate).collection('activities').where('resourceType','==',this.state.resource)
        let actiivtyRef = db.collection('schedules').doc(this.state.scheduleDate).collection('activities').where('resourceType','==',this.state.resource)
        actiivtyRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                
            })
        })
    }
    incrementDate(){
        let d = new Date(this.state.scheduleDate)
        console.log(d)
        var sd = new Date()
        sd.setDate(d.getDate() + 1)
        let scheduleDate = sd.getMonth() + 1 + "-" + (sd.getDate()) + "-" + sd.getFullYear()
        console.log(scheduleDate)
        this.setState({scheduleDate : scheduleDate}, () => this.refetchData())
    }
    decrementDate(){
        let d = new Date(this.state.scheduleDate)
        console.log(d)
        var sd = new Date()
        sd.setDate(d.getDate() - 1)
        let scheduleDate = sd.getMonth() + 1 + "-" + (sd.getDate()) + "-" + sd.getFullYear()
        console.log(scheduleDate)
        this.setState({scheduleDate : scheduleDate}, () => this.refetchData())
    }
    refetchData(){
        this.setState({acRows : []})
        console.log('refetching data!')
        var acCarry = []
        var a = this
        let db = firebase.firestore()
        //let acDocRef = db.collection('aircraft').where('aircraftType','==',this.state.resource)
        var acDocRef = db.collection('aircraft')
        if(this.state.resource != 'all'){
            acDocRef = db.collection('aircraft').where('aircraftType','==',this.state.resource)
        }
        acDocRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                console.log(doc)
                acCarry.push(<SchedulerActivity unit = {doc.data().unit} course = {doc.data().unit} instructor = {doc.data().instructor} student = {doc.data().student} scheduleDate = {a.state.scheduleDate} tailNumber = {doc.id} hobbsTime = {doc.data().hobbsTime} isDowned = {doc.data().isDowned} nextEventTime = {doc.data().nextEventTime}></SchedulerActivity>)
            })
            a.setState({acRows : acCarry}, ()=>console.log('setACROWs'))
        })
    }
    changeResource(event){
        this.setState({resource:event.target.value},()=>this.refetchData())
    }
    setSchedule(){
        let a = this
        let db = firebase.firestore()
        let docRef = db.collection('schedules').doc(this.state.scheduleDate).collection('activities')
        docRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                let newDocRef = db.collection('schedules').doc(a.state.scheduleDate).collection('activities').doc(doc.id)
                newDocRef.update({status : 'Scheduled'})
            })
        })
        
    }
    render(){
        return(
            <div>
            <Card>
            <Grid container>
                <Grid item xs = {11}/>
                <Grid item xs = {1}><Button variant = 'contained' style = {{backgroundColor : '#337ab7'}} onClick = {this.setSchedule}>Set Schedule</Button></Grid>
            </Grid>
            
            <Grid container>
                <Grid item xs = {1}><h3>View Date:</h3></Grid>
                <Grid item xs = {1}><h3>{this.state.scheduleDate}</h3></Grid>
                <Grid item xs = {1}><IconButton style = {{marginTop : "5%"}} onClick = {this.decrementDate}><ArrowBackIosIcon></ArrowBackIosIcon></IconButton></Grid>
                <Grid item xs = {1}><IconButton style = {{marginTop : "5%"}} onClick = {this.incrementDate}><ArrowForwardIosIcon></ArrowForwardIosIcon></IconButton></Grid>
                <Grid item xs = {1}></Grid>
                <Grid item xs = {1}></Grid>
                <Grid item xs = {1}></Grid>
                <Grid item xs = {1}></Grid>
                <Grid item xs = {1}></Grid>
                <Grid item xs = {1}><h4>Resource Type</h4></Grid>
                <Grid item xs = {2}><Select value = {this.state.resource} onChange = {this.changeResource}>
                                        <MenuItem value = "all">All</MenuItem>
                                        <MenuItem value = 'c172'>C172</MenuItem>
                                        <MenuItem value = 'da42'>DA42</MenuItem>
                                        <MenuItem value = '8KCAB'>8KCAB</MenuItem>
                                        <MenuItem value = 'C172-ULTRA-AATD'>SIM</MenuItem>
                                    </Select></Grid>
                

            </Grid>
            
            <div style = {{minWidth : '1243.63636364px', maxWidth : '1243.63636364px'}}>
            <Grid container spacing={0}>
            <Grid item xs={1}>
              <h3>{this.state.scheduleDate}</h3>
              
            </Grid>
            <Grid item xs={11}>
              <Grid container>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>0500</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>0600</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>0700</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>0800</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>0900</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}} ><h3>1000</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1100</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1200</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1300</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1400</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1500</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1600</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1700</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1800</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1900</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>2000</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>2100</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>2200</h3></Grid>
              <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>2300</h3></Grid>
              </Grid>
            </Grid>

            {this.state.acRows}
           
            
          </Grid>
            </div>
            
           
            </Card>
            </div>
        )
    }
}

export default DayScheduler

/*            
<Grid item xs={1}>
              <Grid container>
              <Grid item xs = {6}><h3>Reesj4(1)</h3></Grid>
              <Grid item xs = {6}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={11}>

            </Grid>

*/