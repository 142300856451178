import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { spacing } from "@material-ui/system";
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: "none",

    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  button: {},
});

class ActivityView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityUnitInfo: this.props.activityUnitInfo,
      status: this.props.status,
      startTime: this.props.startTime,
      stopTime: this.props.stopTime,
      personell: this.props.personell,
      date: this.props.date,
      res: this.props.res,
    };
  }
  render() {
    return (
      <Card style={wrapperStyle}>
        <CardContent>
          <Grid container >
            <Grid item xs={6}>
              <h1 style={unitTextElements}>{this.state.activityUnitInfo}</h1>
            </Grid>
            <Grid item xs={6}>
              <h1 style={statusTextelements}>
                {this.state.status} {this.state.res}
              </h1>
            </Grid>
            <Grid item xs={6}>
              <h1 style={startTimeElement}>Start Time</h1>
            </Grid>
            <Grid item xs={6}>
              <h1 style={stopTextelements}>Stop Time</h1>
            </Grid>
            <Grid item xs={6}>
              <h1 style={startTimeElementNum}>{this.state.startTime}</h1>
            </Grid>
            <Grid item xs={6}>
              <h1 style={stopTextelementsNum}>{this.state.stopTime}</h1>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <h1 style={startTimeElement}>
                Instructor : {this.state.personell}
              </h1>
            </Grid>
            <Grid item xs={6}>
              <h1 style={stopTextelements}>Date : {this.props.date}</h1>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}
const wrapperStyle = {
  marginBottom: "50px"
};
const cardStyle = {
  background: "white",
  borderRadius: "20px",
};
const gridStyle = {
  marginBottom: "0px",
};
const statusStyle = {
  left: "10px",
  textAlign: "right",
};
const unitTextElements = {
  color: "#8f9091",
  fontSize: "24px",
  position: "relative",
  left: "10px",
};
const statusTextelements = {
  color: "#8f9091",
  fontSize: 24,
  position: "relative",
  float: "right",
  right: "10px",
};
const startTimeElement = {
  color: "black",
  fontSize: 20,
  position: "relative",
  left: "10px",
};
const stopTextelements = {
  color: "black",
  fontSize: 20,
  position: "relative",
  float: "right",
  right: "10px",
};
const startTimeElementNum = {
  color: "#a39161",
  fontSize: 24,
  position: "relative",
  left: "10px",
};
const stopTextelementsNum = {
  color: "#a39161",
  fontSize: 24,
  position: "relative",
  float: "right",
  right: "10px",
};
export default withStyles(styles)(ActivityView);
