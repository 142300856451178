import React, { Component } from "react";
import * as firebase from "firebase";
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
class StudentProfile extends Component {
    constructor(props){
        super(props)
        this.state = {
            studentName : props.studentName,
            phone : '',
            email : '',
            SFSID : '',
            course : '',
            instructor : '',
            lastFlown : '',
            flightBlock : '',
            backdropOpen : false,
            selectedTab : 0,
            isEditing : false,
            firstName : '',
            lastName : '',
            middleName : '',
            comments : [],
            newCommentDialogOpen : false,
            date : '',
            commentText : '',
            team : ''
        }
        this.openBackDrop = this.openBackDrop.bind(this)
        this.changeTab = this.changeTab.bind(this)
        this.toggleCommentDialog = this.toggleCommentDialog.bind(this)
        this.submitNewComment = this.submitNewComment.bind(this)
        this.updateCommentText = this.updateCommentText.bind(this)
        this.deleteComment = this.deleteComment.bind(this)
    }
    componentDidMount(){
        let a = this
        let db = firebase.firestore()
        var docRef = db.collection('users').doc(this.state.studentName)
        docRef.get().then(function(doc){
            a.setState({team : doc.data().team,firstName : doc.data().firstName, lastName : doc.data().lastName, middleName : doc.data().middleName, phone : doc.data().phone, email : doc.data().email, SFSID : doc.data().SFSID, course : doc.data().course, instructor : doc.data().instructor, lastFlown : doc.data().lastFlown, flightBlock : doc.data().flightBlock})
        })
        let d = new Date()
        let dateString = d.getMonth()+1 + '/' + d.getDate() + '/' + d.getFullYear()
        this.setState({date:dateString})
    }
    openBackDrop(){
        this.setState({backdropOpen : !this.state.backdropOpen})
    }
    changeTab(event){
        console.log(event.target)
        this.setState({selectedTab:parseInt(event.target.id)})
        if(parseInt(event.target.id) == 3){
            this.getComments()
        }
    }
    toggleCommentDialog(){
        this.setState({newCommentDialogOpen : !this.state.newCommentDialogOpen})
    }
    getComments(){
        if(this.state.comments.length == 0){
        let a = this
        let db = firebase.firestore()
        var docRef = db.collection('users').doc(this.state.studentName).collection('comments').orderBy('date')
        var comments = []
        var d = new Date()
        docRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                console.log(doc.data())
                comments.push(<TableRow>
                    <TableCell>
                        <p>{doc.data().comment}</p>
                    </TableCell>
                    <TableCell>
                        {doc.data().date}
                    </TableCell>
                    <TableCell>
                        {doc.data().enteredBy}
                    </TableCell>
                    <TableCell>
                        <IconButton disabled={(Math.round(d.getTime() / 1000)>doc.data().lastEditied+300)}><EditIcon/></IconButton>
                        <IconButton disabled={(Math.round(d.getTime() / 1000)>doc.data().lastEditied+300)} onClick = {()=>a.deleteComment(doc.id)}><DeleteIcon/></IconButton>
                    </TableCell>
                </TableRow>)
                a.setState({comments : comments, commentText : ''})
            })
        })
    }
    }
    submitNewComment(){
        let db = firebase.firestore()
        let docRef = db.collection('users').doc(this.state.studentName).collection('comments')
        let d = new Date()
        docRef.doc().set({
            comment : this.state.commentText,
            date : this.state.date,
            enteredBy : 'reesj4',
            lastEditied : Math.round(d.getTime() / 1000)
        })
        this.setState({comments : [], newCommentDialogOpen : false}, () =>  this.getComments())
        console.log(firebase.auth().currentUser.displayName)
    }
    updateCommentText(event){
        this.setState({commentText : event.target.value})
    }
    deleteComment(id){
        let db = firebase.firestore()
        let docRef = db.collection('users').doc(this.state.studentName).collection('comments').doc(id)
        docRef.delete().then(()=>{
            this.setState({comments : []},()=>this.getComments())
        })
    }
    render(){
        return(
   
            <Grid item xs = {3}><Card style = {{width : '90%', alignItems :"center"}}>
                <Grid container>
                    <Grid item xs = {10}> <h1>{this.state.studentName}</h1></Grid>
                    <Grid item xs = {2}><IconButton color="primary" onClick={this.openBackDrop} style = {{marginLeft : '40%'}}><InfoIcon></InfoIcon></IconButton></Grid>
                    
                </Grid>
                <h3>Phone : {this.state.phone}</h3>
                <h3>Email : {this.state.email}</h3>
                <h3>SFSID : {this.state.SFSID}</h3>
                <h3>Course : {this.state.course}</h3>
                <h3>Instructor : {this.state.instructor}</h3>
                <h3>Last Flown : {this.state.lastFlown}</h3>
                <h3>Flight Block : {this.state.flightBlock}</h3>
            </Card>
            
            <Backdrop open={this.state.backdropOpen}>
                <Card style={{width: "70%",align: "center",marginLeft: "15%", height : '75vh', marginTop : '15vh'}}>
                    <Grid container>
                        <Grid item xs = {11}>
                            <h2>Person: {this.state.lastName}, {this.state.firstName} {this.state.middleName}</h2>
                        </Grid>
                        <Grid item xs = {1}>
                            <IconButton style = {{marginLeft : '50%'}} onClick = {this.openBackDrop}><CloseIcon/></IconButton>
                        </Grid>
                    </Grid>
                    
                    <Tabs variant="scrollable" scrollButtons="auto" value = {this.state.selectedTab} onChange = {this.changeTab}>
                        <Tab label="Details" wrapped id = {0}/>
                        <Tab label="Addresses" wrapped id = {1}/>
                        <Tab label="Availability" wrapped id = {2}/>
                        <Tab label="Comments" wrapped id = {3}/>
                        <Tab label="Currencies" wrapped id = {4}/>
                        <Tab label="Documents" wrapped id = {5}/>
                        <Tab label="Emergency Contact" wrapped id = {6}/>
                        <Tab label="Grounds" wrapped id = {7}/>
                        <Tab label="Holds" wrapped id = {8}/>
                        <Tab label="Information Release" wrapped id = {9}/>
                        <Tab label="No-Shows" wrapped id = {10}/>
                        <Tab label="Pilot Log" wrapped id = {11}/>
                        <Tab label="Qualifications" wrapped id = {12}/>
                        <Tab label="Rates" wrapped id = {13}/>
                        <Tab label="Role Assignment" wrapped id = {14}/>
                        <Tab label="Schedule" wrapped id = {15}/>
                        <Tab label="Training History" wrapped id = {16}/>
                        <Tab label="Training Status" wrapped id = {17}/>
                        <Tab label="Training Records" wrapped id = {18}/>
                    </Tabs>

                   <Card hidden = {!(this.state.selectedTab == 0)}>
                        <h1>Student Details</h1>
                        <Grid container>
                            <Grid item xs = {4}>
                                <TextField disabled value = {this.state.firstName} label = "First Name" style = {{marginTop : '10px'}}></TextField>
                            </Grid>
                            <Grid item xs = {4}>
                                <TextField disabled value = {this.state.middleName} label = "Middle Name" style = {{marginTop : '10px'}}></TextField>
                            </Grid>
                            <Grid item xs = {4}>
                                <TextField disabled value = {this.state.lastName} label = "Last Name" style = {{marginTop : '10px'}}></TextField>
                            </Grid>
                            <Grid item xs = {4}>
                                <TextField disabled value = {this.state.SFSID} label = "SFS ID" style = {{marginTop : '10px'}}></TextField>
                            </Grid>
                            <Grid item xs = {4}>
                                <TextField disabled value = {this.state.phone} label = "Phone Number" style = {{marginTop : '10px'}}></TextField>
                            </Grid>
                            <Grid item xs = {4}>
                                <TextField disabled value = {this.state.email} label = "Email" style = {{marginTop : '10px'}}></TextField>
                            </Grid>
                            <Grid item xs = {3}>
                                <TextField disabled value = {this.state.course} label = "Active Course" style = {{marginTop : '10px'}}></TextField>
                            </Grid>
                            <Grid item xs = {3}>
                                <TextField disabled value = {this.state.instructor} label = "Instructor" style = {{marginTop : '10px'}}></TextField>
                            </Grid>
                            <Grid item xs = {3}>
                                <TextField disabled value = {this.state.team} label = "Team" style = {{marginTop : '10px'}}></TextField>
                            </Grid>
                            <Grid item xs = {3}>
                                <TextField disabled value = {this.state.flightBlock} label = "Flight Block" style = {{marginTop : '10px'}}></TextField>
                            </Grid>
                        </Grid>
                   </Card>

                   <Card hidden = {!(this.state.selectedTab == 3)}>
                        <Button style = {{marginLeft : '90%'}} onClick = {this.toggleCommentDialog} variant = {'contained'}>New</Button>
                        <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Comment</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Entered By</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.comments}
                        </TableBody>
                    </Table>
                </TableContainer>
                   </Card>

                </Card>
            </Backdrop>
            
            <Backdrop open={this.state.newCommentDialogOpen}>
                <Card style = {{width : '50%'}}>
                    <h1>New Comment</h1>
                    <TextField multiline style = {{width : '100%'}} onChange = {this.updateCommentText}>
                    </TextField>
                    <h3>Date Entered : {this.state.date}</h3>
                    <Grid container> 
                        <Grid item xs = {3}>
                            <TextField label = 'PIN' type="password"></TextField>
                        </Grid>
                        <Grid item xs = {6}/>
                        <Grid item xs = {3}>
                            <Button variant = 'contained' onClick = {this.submitNewComment}>Save</Button>
                            <Button style = {{alignSelf : 'right'}} onClick = {this.toggleCommentDialog}>Cancel</Button>
                        </Grid>
                    </Grid>
                </Card>
            </Backdrop>
            
            </Grid>
         
)
    }
}
export default StudentProfile