import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CouseCreateObject from "./courseCreateObject";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
class CouseCreateUnit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitObjectives: 0,
      id: this.props.id,
      pushBack: this.props.pushBack,
      data: [],
      type: "oral",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.pushNewData = this.pushNewData.bind(this);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.id;
    console.log("target : " + target + " value : " + value + " name : " + name);
    this.setState({ [name]: value }, () =>
      this.state.pushBack(this.state.id, this.state.type, [
        this.state.unitName,
        this.state.data,
      ])
    );
  }

  pushNewData(index, data) {
    var carry = this.state.data;
    carry[index] = data;
    console.log("DATA:");
    console.log(carry);
    console.log(carry[index]);
    console.log(index);
    this.setState({ data: carry }, () =>
      this.state.pushBack(this.state.id, this.state.type, [
        this.state.unitName,
        this.state.data,
      ])
    );
  }

  generateCourseObjectives() {
    if (this.state.units != 0) {
      var coms = [];
      for (var i = 0; i < this.state.units; i++) {
        console.log("I IS EQUAL TO " + i);
        coms.push(
          <CouseCreateObject
            id={i}
            pushBack={this.pushNewData}
          ></CouseCreateObject>
        );
      }
      return coms;
    } else {
      return null;
    }
  }
  componentWillMount() {
    this.state.pushBack(this.state.id, this.state.type, [
      this.state.unitName,
      this.state.data,
    ]);
  }
  render() {
    return (
      <Card style={cardStyle}>
        <Grid>
          <Grid container>
            <TextField
              style={courseNameStyle}
              onChange={this.handleInputChange}
              id="unitName"
              label="Unit Name"
              variant="outlined"
            ></TextField>
            <TextField
              style={courseUnitStyle}
              onChange={this.handleInputChange}
              id="unitObjectives"
              label="Unit Objectives"
              variant="outlined"
            ></TextField>
            <Grid>
              <RadioGroup>
                <Grid>
                  <FormControlLabel
                    style={radioButtonStyle}
                    checked={this.state.type === "oral"}
                    onChange={this.handleInputChange}
                    value="oral"
                    id="type"
                    control={<Radio color="default" id="type" />}
                    label="Oral"
                  />
                  <FormControlLabel
                    style={radioButtonStyle}
                    checked={this.state.type === "sim"}
                    onChange={this.handleInputChange}
                    value="sim"
                    id="type"
                    control={<Radio color="default" id="type" />}
                    label="Sim"
                  />
                  <FormControlLabel
                    style={radioButtonStyle}
                    checked={this.state.type === "flight"}
                    onChange={this.handleInputChange}
                    value="flight"
                    id="type"
                    control={<Radio color="default" id="type" />}
                    label="Flight"
                  />
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container>
            <CouseCreateObject
              objs={this.state.unitObjectives}
              pushBack={this.pushNewData}
            ></CouseCreateObject>
          </Grid>
        </Grid>
      </Card>
    );
  }
}
const cardStyle = {
  marginTop: "10px",
  width: "100%",
};
const courseNameStyle = {
  marginTop: "10px",
  align: "left",
  marginBottom: "10px",
};
const courseUnitStyle = {
  marginTop: "10px",
  align: "center",
  marginBottom: "10px",
};
const radioButtonStyle = {
  marginTop: "15px",
};
const generateCourseButtonStyle = {
  marginTop: "12px",
  align: "left",
  spacingBottom: "10px",
};
export default CouseCreateUnit;
