import React, { Component } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button'
import * as firebase from "firebase";
import SchedulingActivityRow from "./schedulingActivityRow";
class ActivityList extends Component {
    constructor(props){
        super(props)
        this.state = {
            activites : []
        }

    }

    componentDidMount(){
        let a = this
        let db = firebase.firestore()
        let scheduleRef = db.collection('schedules').doc('12-13-2021').collection('activities')
        var carryActivites = []
        scheduleRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                carryActivites.push(<SchedulingActivityRow instructor = {doc.data().instructor} student = {doc.data().student} startTime = {doc.data().startTime.seconds} endTime = {doc.data().endTime.seconds} course = {doc.data().course} unit = {doc.data().unit}></SchedulingActivityRow>)
                a.setState({activites : carryActivites})
            })
        })
    }

    render(){
        return(
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Instructor</TableCell>
                            <TableCell>Student</TableCell>
                            <TableCell>Start Time</TableCell>
                            <TableCell>End Time</TableCell>
                            <TableCell>Course</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.activites}
                    </TableBody>
                </Table>
            </TableContainer>

        )
    }
}

export default ActivityList