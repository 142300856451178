import React, { Component } from "react";
import Scheduler from "devextreme-react/scheduler";
import { data } from "./testScheduleData.js";
import CalendarViewTemplate from "./calendarViewTemplate";
import Query from "devextreme/data/query";
import Button from "@material-ui/core/Button";
import * as firebase from "firebase";
import Moment from "moment";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/GridList";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import TimePicker from "react-time-picker";

let d = new Date();

const currentDate = new Date();
const views = ["week", "month", "day"];
var workingData = [];
var courses = [];
var lessons = [];
const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
//TODO:
//Add pulling and display of data based on date
//get current date
//clear activity list on date based on new submit

//ITS REWRITE TIME BITCHES!

class ActivityScheduler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workingData: [],
      courses: [],
      lessons: [],
      students: [],
      stdMenuItems: [],
      opsRequestOpen: false,
      courseMenuHolder: [],
      courseUnitHolder: [],
      opsRequestStudent: "",
      opsRequestCourse: "",
      opsRequestUnit: "",
      resourceRequest: "",
      opsRequestSuggestedFlightTime: "",
      opsRequestSuggestedBreifTime: "",
      startBrief: 0,
      endBrief: 0,
      requestStartTime: "05:30",
      requestStopTime: "23:30",
    };
    this.onAppointmentFormOpening = this.onAppointmentFormOpening.bind(this);
    this.submitToScheduling = this.submitToScheduling.bind(this);
    this.submitSchedule = this.submitSchedule.bind(this);
    this.submitActivities = this.submitActivities.bind(this);
    this.refresh = this.refresh.bind(this);
    this.printWorkingData = this.printWorkingData.bind(this);
    this.openRequestModal = this.openRequestModal.bind(this);
    this.courseSelectChanged = this.courseSelectChanged.bind(this);
    this.unitSelectChanged = this.unitSelectChanged.bind(this);
    this.studentSelectChanged = this.studentSelectChanged.bind(this);
    this.resourceSelectChanged = this.resourceSelectChanged.bind(this);
    this.closeRequestModal = this.closeRequestModal.bind(this);
    this.briefTimeChanged = this.briefTimeChanged.bind(this);
    this.submitOpsRequest = this.submitOpsRequest.bind(this);
    this.timeChange = this.timeChange.bind(this);
    this.endTimeChange = this.endTimeChange.bind(this);
  }
  printWorkingData() {
    console.log(this.state.workingData);
  }
  componentWillMount() {
    workingData = [];
    courses = [];
    lessons = [];
    var db = firebase.firestore();
    var docRef = db.collection("courses");
    let a = this;
    docRef
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          console.log(doc.data().lessons);
          console.log(doc.id);
          courses.push(doc.id);
          lessons.push(doc.data().lessons);
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
    db.collection("users")
      .doc("TEST_INSTRUCTOR")
      .get()
      .then(function (doc) {
        var std = doc.data().students;
        a.setState({ students: std });
        for (var i = 0; i < std.length; i++) {
          var holder = a.state.stdMenuItems;
          holder.push(<MenuItem value={std[i]}>{std[i]}</MenuItem>);
          a.setState({ stdMenuItems: holder });
        }
      });
    var d = new Date();
    var monthHolder = d.getMonth();
    var dayHolder = d.getDate();
    var yearHolder = d.getFullYear();
    var numOfDays = 7;
    //NEED TO ACCOUNT FOR LEAP YEAR

    /*
        for(var i = 1; i < numOfDays; i++){
            
            if((dayHolder + i) > 31 || ((monthHolder == 3 || 5 || 10) && (dayHolder + i) > 30) || (monthHolder == 1 && (dayHolder + i) > 28)){
                dayHolder = 0;
                console.log("MONTH HOLDER IS : " + monthHolder)
                monthHolder = d.getMonth() + 1;
            }
            console.log("GETTING DOC DATA FOR")
            console.log((monthHolder+1)  + "-" + (dayHolder + i) + "-" + yearHolder)
            console.log(monthHolder+i)
            var acRef = db.collection("schedules").doc((monthHolder+1)  + "-" + (dayHolder + i) + "-" + yearHolder).collection("activities")
            acRef.where("instructor", "==", "TEST_INSTRUCTOR").get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                var s = new Date(doc.data().startTime["seconds"] * 1000)
                var e = new Date(doc.data().endTime["seconds"] * 1000)
                console.log(s)
                workingData.push({startDate : s, endDate : e,text : doc.data().student,courseId : doc.data().course, unitId : doc.data().unit, resourcetId : doc.data().resourceType,startBreifTime : doc.data().startBreifTime, endBreifTime : doc.data().endBreifTime})
                console.log("WORKING DATA:")
                console.log(workingData)
            });
        });
        }
        */

    for (var i = 1; i < numOfDays; i++) {
      var e = new Date();
      e.setDate(e.getDate() + i);
      console.log(e.getDate());
      //console.log((e.getMonth() + 1 ) + "-" + e.getDate() + "-" + e.getFullYear())
      var acRef = db
        .collection("schedules")
        .doc(e.getMonth() + 1 + "-" + e.getDate() + "-" + e.getFullYear())
        .collection("activities");
      acRef
        .where("instructor", "==", "TEST_INSTRUCTOR")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var s = new Date(doc.data().startTime["seconds"] * 1000);
            var e = new Date(doc.data().endTime["seconds"] * 1000);
            //workingData.push({startDate : s, endDate : e,text : doc.data().student,courseId : doc.data().course, unitId : doc.data().unit, resourcetId : doc.data().resourceType,startBreifTime : doc.data().startBreifTime, endBreifTime : doc.data().endBreifTime})
            let holder = a.state.workingData;
            holder.push({
              startDate: s,
              endDate: e,
              text: doc.data().student,
              courseId: doc.data().course,
              unitId: doc.data().unit,
              resourcetId: doc.data().resourceType,
              startBreifTime: doc.data().startBreifTime,
              endBreifTime: doc.data().endBreifTime,
            });
            a.setState({ workingData: holder });
          });
        });
    }
  }

  refresh() {
    workingData = [];
    courses = [];
    lessons = [];
    var db = firebase.firestore();
    var docRef = db.collection("courses");
    let a = this;
    docRef
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          console.log(doc.data().lessons);
          console.log(doc.id);
          courses.push(doc.id);
          lessons.push(doc.data().lessons);
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });

    var d = new Date();
    var monthHolder = d.getMonth();
    var dayHolder = d.getDate();
    var yearHolder = d.getFullYear();
    var numOfDays = 7;
    //NEED TO ACCOUNT FOR LEAP YEAR

    /*
        for(var i = 1; i < numOfDays; i++){
            
            if((dayHolder + i) > 31 || ((monthHolder == 3 || 5 || 10) && (dayHolder + i) > 30) || (monthHolder == 1 && (dayHolder + i) > 28)){
                dayHolder = 0;
                console.log("MONTH HOLDER IS : " + monthHolder)
                monthHolder = d.getMonth() + 1;
            }
            console.log("GETTING DOC DATA FOR")
            console.log((monthHolder+1)  + "-" + (dayHolder + i) + "-" + yearHolder)
            console.log(monthHolder+i)
            var acRef = db.collection("schedules").doc((monthHolder+1)  + "-" + (dayHolder + i) + "-" + yearHolder).collection("activities")
            acRef.where("instructor", "==", "TEST_INSTRUCTOR").get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                var s = new Date(doc.data().startTime["seconds"] * 1000)
                var e = new Date(doc.data().endTime["seconds"] * 1000)
                console.log(s)
                workingData.push({startDate : s, endDate : e,text : doc.data().student,courseId : doc.data().course, unitId : doc.data().unit, resourcetId : doc.data().resourceType,startBreifTime : doc.data().startBreifTime, endBreifTime : doc.data().endBreifTime})
                console.log("WORKING DATA:")
                console.log(workingData)
            });
        });
        }
        */

    for (var i = 1; i < numOfDays; i++) {
      var e = new Date();
      e.setDate(e.getDate() + i);
      console.log(e.getDate());
      //console.log((e.getMonth() + 1 ) + "-" + e.getDate() + "-" + e.getFullYear())
      var acRef = db
        .collection("schedules")
        .doc(e.getMonth() + 1 + "-" + e.getDate() + "-" + e.getFullYear())
        .collection("activities");
      acRef
        .where("instructor", "==", "TEST_INSTRUCTOR")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var s = new Date(doc.data().startTime["seconds"] * 1000);
            var e = new Date(doc.data().endTime["seconds"] * 1000);
            workingData.push({
              startDate: s,
              endDate: e,
              text: doc.data().student,
              courseId: doc.data().course,
              unitId: doc.data().unit,
              resourcetId: doc.data().resourceType,
              startBreifTime: doc.data().startBreifTime,
              endBreifTime: doc.data().endBreifTime,
            });
          });
        });
    }
  }

  establishCourses() {
    console.log("ESATBLISHING COURSES");
    var db = firebase.firestore();
    for (var i = 0; i < courses.length; i++) {
      let ref = courses[i];
      var docRef = db.collection("courses").doc(ref);
      docRef.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
        });
      });
    }
  }
  getCourseIndex(id) {
    console.log("GETTING ID");
    console.log(id);
    for (var i = 0; i < courses.length; i++) {
      if (courses[i] === id.value || courses[i] === id) {
        return i;
      }
    }
    return null;
  }

  submitSchedule() {
    console.log("::::NEW FUNC");
    var db = firebase.firestore();
    let numOfDays = 7;
    let a = this;

    for (var j = 0; j < numOfDays; j++) {
      let z = new Date();
      z.setDate(z.getDate() + j);
      console.log(z.getMonth() + 1 + "-" + z.getDate() + "-" + z.getFullYear());
      var docRef = db
        .collection("schedules")
        .doc(z.getMonth() + 1 + "-" + z.getDate() + "-" + z.getFullYear())
        .collection("activities");
      docRef
        .where("instructor", "==", "TEST_INSTRUCTOR")
        .get()
        .then(function (querySnapshot) {
          var index = 0;
          console.log(querySnapshot.size);
          querySnapshot.forEach(function (doc) {
            console.log(doc.id);
            if (doc.data().status == "Scheduling") {
              doc.ref.delete();
            }

            index = index + 1;
          });
        });
      console.log(j);
      console.log(j + "???????????" + numOfDays);
      if (j == numOfDays - 1) {
        //this.submitActivities()
      }
      if (j == numOfDays - 1) {
        wait(0.2 * 1000)
          .then(() => {
            console.log("waited for 4 seconds");
            throw new Error("error occurred");
          })
          .catch(() => {
            this.submitActivities();
          });
      }
    }
  }

  clearActivities(_callback) {}

  submitActivities() {
    console.log("SUMBITTING::::");
    var db = firebase.firestore();
    let c = new Date();
    let a = this;
    for (var i = 0; i < a.state.workingData.length; i++) {
      console.log(i);
      let d = new Date(a.state.workingData[i].startDate);
      var monthHolder = d.getMonth();
      var dayHolder = d.getDate();
      var yearHolder = d.getFullYear();
      let docRef = db
        .collection("schedules")
        .doc(monthHolder + 1 + "-" + dayHolder + "-" + yearHolder)
        .collection("activities");
      docRef
        .doc()
        .set({
          course: a.state.workingData[i].courseId,
          unit: a.state.workingData[i].unitId,
          student: a.state.workingData[i].text,
          instructor: "TEST_INSTRUCTOR",
          resourceType: a.state.workingData[i].resourcetId,
          startTime: a.state.workingData[i].startDate,
          endTime: a.state.workingData[i].endDate,
          startBreifTime: a.state.workingData[i].startBreifTime,
          endBreifTime: a.state.workingData[i].endBreifTime,
          status: "Scheduling",
        })
        .then(console.log("submitted"));
    }
  }

  //BUG duplicate
  submitToScheduling() {
    var db = firebase.firestore();
    let numOfDays = 7;
    console.log("submitting:::::");
    let d = new Date();
    var monthHolder = d.getMonth();
    var dayHolder = d.getDate();
    var yearHolder = d.getFullYear();
    var hourHolder = d.getHours();
    for (var j = 1; j < numOfDays; j++) {
      if (
        dayHolder + j > 31 ||
        ((monthHolder == 3 || 5 || 10) && dayHolder + j > 30) ||
        (monthHolder == 1 && dayHolder + j > 28)
      ) {
        dayHolder = 0;
        console.log("MONTH HOLDER IS : " + monthHolder);
        monthHolder = d.getMonth() + 1;
      }
      var docRef = db
        .collection("schedules")
        .doc(monthHolder + 1 + "-" + (dayHolder + j) + "-" + yearHolder)
        .collection("activities");
      console.log(dayHolder + 1 + "-" + (monthHolder + j) + "-" + yearHolder);
      docRef
        .where("instructor", "==", "TEST_INSTRUCTOR")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            if (doc.data().status == "Scheduling") {
              doc.ref.delete();
            }
          });
        });
    }

    wait(1 * 1000)
      .then(() => {
        console.log("waited for 4 seconds");
        throw new Error("error occurred");
      })
      .catch(() => {
        this.failureCallback();
      });
  }

  failureCallback() {
    var db = firebase.firestore();
    let c = new Date();
    let a = this;
    for (var i = 0; i < workingData.length; i++) {
      if (i == 0 && c.getHours() < 1500) {
        let d = new Date(workingData[i].startDate);
        var monthHolder = d.getMonth();
        var dayHolder = d.getDate();
        var yearHolder = d.getFullYear();
        let docRef = db
          .collection("schedules")
          .doc(monthHolder + 1 + "-" + dayHolder + "-" + yearHolder)
          .collection("activities");
        docRef.doc().set({
          course: workingData[i].courseId,
          unit: workingData[i].unitId,
          student: workingData[i].text,
          instructor: "TEST_INSTRUCTOR",
          resourceType: workingData[i].resourcetId,
          startTime: workingData[i].startDate,
          endTime: workingData[i].endDate,
          startBreifTime: workingData[i].startBreifTime,
          endBreifTime: workingData[i].endBreifTime,
          status: "Scheduling",
        });
      } else {
        let d = new Date(workingData[i].startDate);
        var monthHolder = d.getMonth();
        var dayHolder = d.getDate();
        var yearHolder = d.getFullYear();
        let docRef = db
          .collection("schedules")
          .doc(monthHolder + 1 + "-" + dayHolder + "-" + yearHolder)
          .collection("activities");
        docRef.doc().set({
          course: workingData[i].courseId,
          unit: workingData[i].unitId,
          student: workingData[i].text,
          instructor: "TEST_INSTRUCTOR",
          resourceType: workingData[i].resourcetId,
          startTime: workingData[i].startDate,
          endTime: workingData[i].endDate,
          startBreifTime: workingData[i].startBreifTime,
          endBreifTime: workingData[i].endBreifTime,
          status: "Scheduling",
        });
      }
    }
  }
  openRequestModal() {
    this.setState({ opsRequestOpen: true });
    for (var i = 0; i < courses.length; i++) {
      var holder = this.state.courseMenuHolder;
      holder.push(<MenuItem value={courses[i]}>{courses[i]}</MenuItem>);
      this.setState({ courseMenuHolder: holder });
    }
  }
  closeRequestModal() {
    this.setState({ opsRequestOpen: false });
    this.setState({ opsRequestStudent: "" });
    this.setState({ opsRequestCourse: "" });
    this.setState({ courseMenuHolder: [] });
    this.setState({ courseUnitHolder: [] });
    this.setState({ resourceRequest: "" });
  }
  studentSelectChanged(event) {
    this.setState({ opsRequestStudent: event.target.value });
    var holder = [];
    for (var i = 0; i < courses.length; i++) {
      holder.push(<MenuItem value={courses[i]}>{courses[i]}</MenuItem>);
      if (i == courses.length - 1) {
        this.setState({ courseMenuHolder: holder });
      }
    }
  }
  courseSelectChanged(event) {
    this.setState({ opsRequestCourse: event.target.value });
    this.setState({ courseUnitHolder: [] });
    console.log("EVENT.TARGET.VALUE " + event.target.value);
    console.log(lessons[this.getCourseIndex(event.target.value)]);
    var h = lessons[this.getCourseIndex(event.target.value)];
    let holder = [];
    for (var i = 0; i < h.length; i++) {
      holder.push(<MenuItem value={h[i]}>{h[i]}</MenuItem>);
      if (i == h.length - 1) {
        this.setState({ courseUnitHolder: holder });
      }
    }
  }
  unitSelectChanged(event) {
    console.log("UNIT CHANGED");
    let a = this;
    this.setState({ opsRequestUnit: event.target.value });
    let db = firebase.firestore();
    let docRef = db.collection("courses").doc(this.state.opsRequestCourse);
    let activityInfoRef = db
      .collection("courses")
      .doc(this.state.opsRequestCourse)
      .collection(event.target.value)
      .doc("info");
    activityInfoRef.get().then(function (doc) {
      if (doc.data().type == "ORAL") {
        a.setState({ resourceRequest: "briefRoom" });
      } else {
        docRef.get().then(function (doc) {
          console.log(doc.data().acType);
          a.setState({ resourceRequest: doc.data().acType });
        });
      }
    });
  }
  resourceSelectChanged(event) {
    this.setState({ resourceRequest: event.target.value });
  }
  briefTimeChanged(event) {
    console.log(event);
    //console.log(event.target.name)
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }
  submitOpsRequest() {
    /*
      What we need 
      *course 
      
      *instructor 
      *resourceType 
      
      *student 
      *unit 
      startResTime 
      endResTime
      endBreifTime 
      startBriefTime 
      startTime 
      status 
    */
    let d = new Date();
    let s = new Date();
    let e = new Date();
    var startSplit = this.state.requestStartTime.split(":");
    s.setHours(startSplit[0]);
    s.setMinutes(startSplit[1]);
    let rs = new Date(s.getFullYear(), s.getMonth(), s.getDate(), s.getHours(), s.getMinutes() + (parseFloat(this.state.startBrief) * 60))
    console.log(s)
    console.log(rs)


    var endSplit = this.state.requestStopTime.split(":");
    e.setHours(endSplit[0]);
    e.setMinutes(endSplit[1]);
    let re =  new Date(e.getFullYear(), e.getMonth(), e.getDate(), e.getHours(), e.getMinutes() - (parseFloat(this.state.endBrief) * 60))
    
    let db = firebase.firestore();

    db.collection("schedules")
      .doc(d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear())
      .collection("activities")
      .doc()
      .set({
        course: this.state.opsRequestCourse,
        unit: this.state.opsRequestUnit,
        instructor: "TEST_INSTRUCTOR",
        student: this.state.opsRequestStudent,
        status: "Ops-Request",
        startTime: s,
        endTime: e,
        startResTime: rs,
        endResTime: re,
        resourceType : this.state.resourceRequest,
      });
      this.setState({opsRequestOpen : false})
  }
  timeChange(time) {
    console.log(time);
    this.setState({ requestStartTime: time });
  }
  endTimeChange(time) {
    this.setState({ requestStopTime: time });
  }
  render() {
    return (
      <div>
        <Card>
          <Button onClick={this.printWorkingData}>print workingData</Button>
          <Button onClick={this.submitSchedule}>Submit To Scheduling</Button>
          <Button onClick={this.openRequestModal}>New Ops Request</Button>
          <Scheduler
            dataSource={this.state.workingData}
            views={views}
            defaultCurrentView="day"
            defaultCurrentDate={currentDate}
            height={1200}
            startDayHour={5}
            onAppointmentFormOpening={this.onAppointmentFormOpening}
          />
        </Card>
        <Modal
          open={this.state.opsRequestOpen}
          onClose={this.closeRequestModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Card
            style={{
              textAlign: "center",
              width: "45%",
              marginLeft: "30%",
              marginTop: "2%",
            }}
          >
            <h3>New Ops Request</h3>
            <h3>Student</h3>
            <Select onChange={this.studentSelectChanged}>
              {this.state.stdMenuItems}
            </Select>
            <h3>Course</h3>
            <Select onChange={this.courseSelectChanged}>
              {this.state.courseMenuHolder}
            </Select>
            <h3>Unit</h3>
            <Select onChange={this.unitSelectChanged}>
              {this.state.courseUnitHolder}
            </Select>
            <h3>Resource</h3>
            <Select value={this.state.resourceRequest}>
              <MenuItem value={"c172"}>C-172</MenuItem>
              <MenuItem value={"c172SIM"}>C-172 SIM</MenuItem>
              <MenuItem value={"da42"}>DA42</MenuItem>
              <MenuItem value={"da42SIM"}>DA42 SIM</MenuItem>
              <MenuItem value={"C182"}>C-182</MenuItem>
              <MenuItem value={"8KCAB"}>8KCAB</MenuItem>
              <MenuItem value={"briefRoom"}>Brief Room</MenuItem>
            </Select>
            <h3>Start Time : </h3>
            <TimePicker
              locale="sv-sv"
              name="requestStartTime"
              onChange={this.timeChange}
              value={this.state.requestStartTime}
            ></TimePicker>
            <h3>End Time : </h3>
            <TimePicker
              locale="sv-sv"
              name="endTimeChange"
              onChange={this.endTimeChange}
              value={this.state.requestStopTime}
            ></TimePicker>
            <h3>Start Brief Time:</h3>
            <Select
              name="startBrief"
              value={this.state.startBrief}
              onChange={this.briefTimeChanged}
            >
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={0.1}>.1</MenuItem>
              <MenuItem value={0.2}>.2</MenuItem>
              <MenuItem value={0.3}>.3</MenuItem>
              <MenuItem value={0.4}>.4</MenuItem>
              <MenuItem value={0.5}>.5</MenuItem>
              <MenuItem value={0.6}>.6</MenuItem>
              <MenuItem value={0.7}>.7</MenuItem>
              <MenuItem value={0.8}>.8</MenuItem>
              <MenuItem value={0.9}>.9</MenuItem>
              <MenuItem value={1.0}>1.0</MenuItem>
            </Select>
            <h3>End Brief Time:</h3>
            <Select
              name="endBrief"
              value={this.state.endBrief}
              onChange={this.briefTimeChanged}
            >
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={0.1}>.1</MenuItem>
              <MenuItem value={0.2}>.2</MenuItem>
              <MenuItem value={0.3}>.3</MenuItem>
              <MenuItem value={0.4}>.4</MenuItem>
              <MenuItem value={0.5}>.5</MenuItem>
              <MenuItem value={0.6}>.6</MenuItem>
              <MenuItem value={0.7}>.7</MenuItem>
              <MenuItem value={0.8}>.8</MenuItem>
              <MenuItem value={0.9}>.9</MenuItem>
              <MenuItem value={1.0}>1.0</MenuItem>
            </Select>
            <h3></h3>
            <Button onClick={this.submitOpsRequest}>Submit</Button>
          </Card>
        </Modal>
      </div>
    );
  }

  onAppointmentFormOpening(data) {
    console.log(workingData);
    let a = this;
    let form = data.form,
      startDate = data.appointmentData.startDate,
      textRef = data.appointmentData.text;
    var stud_name = data.appointmentData.text;
    var course_id = data.appointmentData.text;
    var unit_id = data.appointmentData.text;
    var resource_id = data.appointmentData.text;
    console.log("DATA : ");
    console.log(data);
    textRef = "this is a test";
    console.log(textRef);
    console.log(data.appointmentData.text);
    data.appointmentData.text = "text";
    console.log(data.appointmentData.text);
    var courseRef = 1;
    form.option("items", [
      {
        label: {
          text: "Student",
        },
        editorType: "dxSelectBox",
        dataField: "text",
        editorOptions: {
          items: this.state.students,
          onValueChanged: function (args) {
            textRef = "This is a test";
          },
        },
      },
      {
        label: {
          text: "Course",
        },
        editorType: "dxSelectBox",
        dataField: "courseId",
        editorOptions: {
          items: courses,
          onValueChanged: function (args) {
            form
              .getEditor("unitId")
              .option("items", lessons[a.getCourseIndex(args)]);
          },
        },
      },
      {
        label: {
          text: "Unit",
        },
        editorType: "dxSelectBox",
        dataField: "unitId",

        editorOptions: {
          items: [],
          onValueChanged: function (args) {},
        },
      },
      {
        label: {
          text: "Resource",
        },
        editorType: "dxSelectBox",
        dataField: "resourcetId",
        editorOptions: {
          items: [
            "C-172",
            "C-172 TOP HAWK",
            "C-182",
            "DA-42",
            "8KCAB",
            "C-172 SIM",
            "DA-42 SIM",
            "BRIEF ROOM",
          ],
          onValueChanged: function (args) {},
        },
      },
      {
        dataField: "startDate",
        editorType: "dxDateBox",
        editorOptions: {
          width: "100%",
          type: "datetime",
          onValueChanged: function (args) {
            startDate = args.value;
            form
              .getEditor("endDate")
              .option("value", new Date(startDate.getTime() + 60 * 1000 * 60));
          },
        },
      },
      {
        dataField: "endDate",
        editorType: "dxDateBox",
        editorOptions: {
          width: "100%",
          type: "datetime",
        },
      },
      {
        label: {
          text: "Start Brief Time",
        },
        editorType: "dxSelectBox",
        dataField: "startBreifTime",
        editorOptions: {
          items: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        },
      },
      {
        label: {
          text: "End Brief Time",
        },
        editorType: "dxSelectBox",
        dataField: "endBreifTime",
        editorOptions: {
          items: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        },
      },
    ]);
  }
}

export default ActivityScheduler;
