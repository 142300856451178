import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import * as firebase from "firebase";
import Button from "@material-ui/core/Button";
import { CSVReader } from "react-papaparse";
import { jsonToCSV } from "react-papaparse";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CourseAdminCourse from "./courseAdminCourse";

class CourseAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      importDialogOpen: false,
      unitList: [],
    };
    const fileRef = React.createRef();
    this.closeImportModal = this.closeImportModal.bind(this);
    this.openImportModal = this.openImportModal.bind(this);
  }
  componentWillMount() {
    console.log("DID MOUNT");
    var db = firebase.firestore();
    var docRef = db.collection("courses");
    let a = this;
    docRef
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          console.log(doc.id);
          var holder = a.state.courses;
          holder.push(
            <CourseAdminCourse
              courseName={doc.id}
              downlaod={doc.data().raw}
            ></CourseAdminCourse>
          );
          a.setState({ courses: holder });
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  closeImportModal() {
    this.setState({ importDialogOpen: false });
  }
  openImportModal() {
    this.setState({ importDialogOpen: true });
  }

  handleOnDrop = (data) => {
    console.log(data)
    let v = jsonToCSV(data)
    console.log(v)
    let a = this;
    let db = firebase.firestore();
    console.log('DATA@@@@@@@@@@@@@@@' + data);
    console.log(data[1]["data"][0]);
    if (data[0]["data"][0] == "NAME") {
      let name = data[1]["data"][0];
      let acType = data[1]["data"][1];
      let acClass = data[1]["data"][3];
      db.collection("courses").doc(name).set({
        acType: acType,
        acClass: acClass,
      });
      for (var i = 8; i < data.length; i++) {
        if (data[i]["data"][0] != "" && data[i]["data"][0] != null) {
          let holder = a.state.unitList;
          holder.push(data[i]["data"][0]);
          a.setState({ unitList: holder });

          db.collection("courses").doc(name).update({
            lessons: holder,
            raw : []
          });

          let docRef = db
            .collection("courses")
            .doc(name)
            .collection(data[i]["data"][0]);
          docRef.doc("info").set({
            type: data[i]["data"][2],
            aircraftTime: data[i]["data"][3],
            aircraftTrainingReceived: data[i]["data"][4],
            solo: data[i]["data"][5],
            simTotal: data[i]["data"][6],
            PIC: data[i]["data"][7],
            IFRAircraft: data[i]["data"][8],
            IFRSim: data[i]["data"][10],
            XCDual: data[i]["data"][11],
            XCSolo: data[i]["data"][12],
            XCPic: data[i]["data"][13],
            NTDual: data[i]["data"][14],
            NTSolo: data[i]["data"][15],
            NTPIC: data[i]["data"][16],
            NTToLdg: data[i]["data"][17],
            PrePost: data[i]["data"][18],
            gndTime: data[i]["data"][19],
            isCheckRide: data[i]["data"][20],
            isSolo: data[i]["data"][21],
            taaReq: data[i]["data"][22],
            
          });
          console.log(data[i]["data"][24]);
          //set C/I units
          let GUntis = data[i]["data"][23].split(",");
          if (data[i]["data"][23] != null && data[i]["data"][23] != "") {
            console.log(data[i]["data"][23].split(","));
            //TRUE
            //let untis = data[i]['data'][24].split(',')
            for (var j = 0; j < GUntis.length; j++) {
              docRef.doc(GUntis[j]).set({
                isGraded: false,
                order: j,
              });
            }
          }

          if (data[i]["data"][24] != null && data[i]["data"][24] != "") {
            let CUnits = data[i]["data"][24].split(",");
            for (var k = 0; k < CUnits.length; k++) {
              docRef.doc(CUnits[k]).set({
                isGraded: true,
                order: GUntis.length + k,
              });
            }
          }
        }
      }
    }
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    this.setState({ importHoldActivities: [] });
    this.setState({ importActivities: [] });
  };
  render() {
    return (
      <div>
        <Card style = {{marginBottom : '25px'}}>
          <Grid container>
            <h3>Course Admin</h3>
            <Button onClick={this.openImportModal}>Import Template</Button>
          </Grid>
        </Card>

        <Modal
          aria-labelledby="simple-dialog-title"
          open={this.state.importDialogOpen}
          BackDropComponent={Backdrop}
          onClose={this.closeImportModal}
        >
          <Card
            style={{ width: "75%", textAlign: "center", overflowX: "auto" }}
          >
            <h1>Import From Template</h1>
            <CSVReader
              ref={this.fileRef}
              onDrop={this.handleOnDrop}
              onError={this.handleOnError}
              addRemoveButton
              onRemoveFile={this.handleOnRemoveFile}
            >
              <span>Drop CSV file here or click to upload.</span>
            </CSVReader>
          </Card>
        </Modal>
        <div>{this.state.courses}</div>
      </div>
    );
  }
}

export default CourseAdmin;
