import React, { Component } from "react";
import * as firebase from "firebase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AircraftStatusRow from "./aircraftStatusRow";
import Card from "@material-ui/core/Card";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { Menu } from "@material-ui/core";
class AircraftStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pulledAircraft: [],
      slectedAircraft: "all",
      selectedStatus: "all",
      xcToggle: "all",
    };
    this.adjustFilter = this.adjustFilter.bind(this);
    this.refreshAircraft = this.refreshAircraft.bind(this);
  }
  componentWillMount() {
    let db = firebase.firestore();
    var docRef = db.collection("aircraft");
    let a = this;
    if (this.state.slectedAircraft == "all") {
      docRef.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          var s = "";
          if (doc.data().isDowned) {
            s = "DOWNED";
          }
          if (doc.data().isAssigned) {
            s = "Assigned";
          }
          if (doc.data().isRampedOut) {
            s = "Ramped-Out";
          }
          if (
            !doc.data().isRampedOut &&
            !doc.data().isAssigned &&
            !doc.data().isDowned
          ) {
            s = "Available";
          }
          var holder = a.state.pulledAircraft;
          holder.push(
            <AircraftStatusRow
              resource={doc.id}
              aircraftType={doc.data().aircraftType}
              status={s}
              hobbsTime={doc.data().hobbsTime}
              tachTime={doc.data().tachTime}
              nextEventName={doc.data().nextEventName}
              nextEventTime={doc.data().nextEventTime}
            ></AircraftStatusRow>
          );
          a.setState({ pulledAircraft: holder });
          console.log(doc.data());
        });
      });
    } else {
      docRef
        .where("aircraftType", "=", this.state.slectedAircraft)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var s = "";
            if (doc.data().isDowned) {
              s = "DOWNED";
            }
            if (doc.data().isAssigned) {
              s = "Assigned";
            }
            if (doc.data().isRampedOut) {
              s = "Ramped-Out";
            }
            if (
              !doc.data().isRampedOut &&
              !doc.data().isAssigned &&
              !doc.data().isDowned
            ) {
              s = "Available";
            }
            var holder = a.state.pulledAircraft;
            holder.push(
              <AircraftStatusRow
                resource={doc.id}
                aircraftType={doc.data().aircraftType}
                status={s}
                hobbsTime={doc.data().hobbsTime}
                tachTime={doc.data().tachTime}
                nextEventName={doc.data().nextEventName}
                nextEventTime={doc.data().nextEventTime}
              ></AircraftStatusRow>
            );
            a.setState({ pulledAircraft: holder });
            console.log(doc.data());
          });
        });
    }
  }
  refreshAircraft() {
    let db = firebase.firestore();
    var docRef = db.collection("aircraft");
    let a = this;
    if (this.state.slectedAircraft == "all") {
      docRef.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          var s = "";
          if (doc.data().isDowned) {
            s = "DOWNED";
          }
          if (doc.data().isAssigned) {
            s = "Assigned";
          }
          if (doc.data().isRampedOut) {
            s = "Ramped-Out";
          }
          if (
            !doc.data().isRampedOut &&
            !doc.data().isAssigned &&
            !doc.data().isDowned
          ) {
            s = "Available";
          }
          if (
            (s == "DOWNED" && a.state.selectedStatus == "Downed") ||
            (s == "Available" && a.state.selectedStatus == "Available") ||
            a.state.selectedStatus == "all"
          ) {
            if (
              a.state.xcToggle == "all" ||
              (a.state.xcToggle == "XC" &&
                doc.data().nextEventTime - doc.data().tachTime > 10)
            ) {
              var holder = a.state.pulledAircraft;
              holder.push(
                <AircraftStatusRow
                  resource={doc.id}
                  aircraftType={doc.data().aircraftType}
                  status={s}
                  hobbsTime={doc.data().hobbsTime}
                  tachTime={doc.data().tachTime}
                  nextEventName={doc.data().nextEventName}
                  nextEventTime={doc.data().nextEventTime}
                ></AircraftStatusRow>
              );
              a.setState({ pulledAircraft: holder });
              console.log(doc.data());
            }
          }
        });
      });
    } else {
      docRef
        .where("aircraftType", "==", this.state.slectedAircraft)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var s = "";
            if (doc.data().isDowned) {
              s = "DOWNED";
            }
            if (doc.data().isAssigned) {
              s = "Assigned";
            }
            if (doc.data().isRampedOut) {
              s = "Ramped-Out";
            }
            if (
              !doc.data().isRampedOut &&
              !doc.data().isAssigned &&
              !doc.data().isDowned
            ) {
              s = "Available";
            }
            if (
              (s == "DOWNED" && a.state.selectedStatus == "Downed") ||
              (s == "Available" && a.state.selectedStatus == "Available") ||
              a.state.selectedStatus == "all"
            ) {
              if (
                a.state.xcToggle == "all" ||
                (a.state.xcToggle == "XC" &&
                  doc.data().nextEventTime - doc.data().tachTime > 10)
              ) {
                var holder = a.state.pulledAircraft;
                holder.push(
                  <AircraftStatusRow
                    resource={doc.id}
                    aircraftType={doc.data().aircraftType}
                    status={s}
                    hobbsTime={doc.data().hobbsTime}
                    tachTime={doc.data().tachTime}
                    nextEventName={doc.data().nextEventName}
                    nextEventTime={doc.data().nextEventTime}
                  ></AircraftStatusRow>
                );
                a.setState({ pulledAircraft: holder });
                console.log(doc.data());
              }
            }
          });
        });
    }
  }

  adjustFilter(event) {
    console.log(event);
    console.log(event.target.id);
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ pulledAircraft: [] }, () => this.refreshAircraft());
  }

  render() {
    return (
      <dir>
        <Card>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Filter</TableCell>
                <TableCell>
                  <Select
                    name={"slectedAircraft"}
                    value={this.state.slectedAircraft}
                    onChange={this.adjustFilter}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"c172"}>C-172</MenuItem>
                    <MenuItem value={"da42"}>DA-42</MenuItem>
                    <MenuItem value={"c182"}>C-182</MenuItem>
                    <MenuItem value={"8KCAB"}>8KCAB</MenuItem>
                    <MenuItem value={"C172-ULTRA-AATD"}>Sim</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    value={this.state.selectedStatus}
                    name={"selectedStatus"}
                    onChange={this.adjustFilter}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"Available"}>Available</MenuItem>
                    <MenuItem value={"up"}>Up</MenuItem>
                    <MenuItem value={"Downed"}>Downed</MenuItem>
                  </Select>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Select
                    value={this.state.xcToggle}
                    name={"xcToggle"}
                    onChange={this.adjustFilter}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"XC"}>XC</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Resource</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Hobbs Time</TableCell>
                <TableCell>Tach Time</TableCell>
                <TableCell>Next Event Due</TableCell>
                <TableCell>Next Event Time</TableCell>
                <TableCell>Time To Next Event</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.state.pulledAircraft}</TableBody>
          </Table>
        </Card>
      </dir>
    );
  }
}

export default AircraftStatus;
