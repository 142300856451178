import React, { Component } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button'
import { Typography } from "@mui/material";
import Draggable from 'react-draggable'
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import * as firebase from "firebase";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import { fontWeight } from "@mui/system";
class SchedulingActivityEdit extends Component {
    constructor(props){
        super(props)
        this.state={
            docRef : props.docRef,
            closeBackdrop : props.closeBackdrop,
            requestDate : '12-18-2021'
        }
        this.updateDocData = this.updateDocData.bind(this)
    }
    componentDidMount(){
        console.log('MOUNT')
        if(this.props.docRef!=''){


            
            console.log(this.props.docRef.startTime)
            if(this.props.docRef.startTime.seconds > 0){
            let requestDate = new Date(this.props.docRef.startTime.seconds)
            let requestDateString = (requestDate.getMonth()+1) + '-' + requestDate.getDate() + requestDate.getFullYear()
            this.setState({requestDate : requestDateString})
            }
        }
    }
    updateDocData(){

    }
    render(){
        return(

            <Card style={{width: "75%",align: "center",marginLeft: "15%"}}>
            <div style = {{marginTop : '1%'}}>
                <Grid container>
                    <Grid item xs = {11}>
                        <h1>Edit Activity</h1>
                    </Grid>
                    <Grid item xs = {1}>
                        <IconButton onClick = {this.state.closeBackdrop}><CloseIcon></CloseIcon></IconButton>
                    </Grid>
                    <Grid item xs = {6}><TextField type = "date" label = "Request Date" value = {this.state.requestDate} onChange = {this.changeAdminRequestDate} style = {{marginBotton : '5px'}}></TextField></Grid>
                    <Grid item xs = {6}><Select label = "Request Type" onChange = {this.changeAdminParams} name = {"adminRequestType"} value = {this.adminRequestType}>
                            <MenuItem value={'C172-ANY'} name = {"adminRequestType"}>C172(ANY)</MenuItem>
                            <MenuItem value={'C172-SPIN'} name = {"adminRequestType"}>C172-SPIN</MenuItem>
                            <MenuItem value={'C172-TAA'} name = {"adminRequestType"}>C172-TAA</MenuItem>
                            <MenuItem value={'C172-NAVIII'} name = {"adminRequestType"}>C172-NAVIII</MenuItem>
                            <MenuItem value={'C172-WESTRAMP'} name = {"adminRequestType"}>C172-WESTRAMP</MenuItem>
                            <MenuItem value={'C182RG'} name = {"adminRequestType"}>C182RG</MenuItem>
                            <MenuItem value={'C150'} name = {"adminRequestType"}>C150</MenuItem>
                            <MenuItem value={'DA42NG'} name = {"adminRequestType"}>DA42NG</MenuItem>
                            <MenuItem value={'8KCAB'} name = {"adminRequestType"}>8KCAB</MenuItem>
                            <MenuItem value={'CESSNA-CPT'} name = {"adminRequestType"}>CESSNA-CPT</MenuItem>
                            <MenuItem value={'CLASSROOM'} name = {"adminRequestType"}>CLASSROOM</MenuItem>
                            <MenuItem value={'CONFERENCE-ROOM'} name = {"adminRequestType"}>CONFERENCE-ROOM</MenuItem>
                            <MenuItem value={'FTD-BAYS'} name = {"adminRequestType"}>FTD-BAYS</MenuItem>
                            <MenuItem value={'ORAL-ROOM'} name = {"adminRequestType"}>ORAL-ROOM</MenuItem>
                            <MenuItem value={'C172-LITE-AATD'} name = {"adminRequestType"}>C172-LITE-AATD</MenuItem>
                            <MenuItem value={'C172-RTD-AATD'} name = {"adminRequestType"}>C172-RTD-AATD</MenuItem>
                            <MenuItem value={'C172-TRUFLT AATD'} name = {"adminRequestType"}>172-TRUFLT AATD</MenuItem>
                            <MenuItem value={'C172-ULTRA-AATD'} name = {"adminRequestType"}>C172-ULTRA-AATD</MenuItem>
                            <MenuItem value={'DA42-TRUFLT-AATD'} name = {"adminRequestType"}>DA42-TRUFLT-AATD</MenuItem>
                            <MenuItem value={'DA-42-ULTRA-AATD'} name = {"adminRequestType"}>DA-42-ULTRA-AATD</MenuItem>
                        </Select></Grid>
                    <h4> </h4>
                    <Grid itexm xs = {4}><TextField label = "PIC/Inst" disabled defaultValue = {this.state.instructor}></TextField></Grid>
                    <Grid itexm xs = {4}><TextField label = "Pilot 2/ Observer 1"></TextField></Grid>
                    <Grid itexm xs = {4}><TextField label = "Observer 2"></TextField></Grid>
                    <Grid item xs = {2.4}><h4>Start</h4><TextField type = "time" onChange = {this.changeAdminStartTime} defaultValue = {this.state.adminStartTime}></TextField></Grid>
                    <Grid item xs = {2.4}><h4>Pre-Breif</h4><TextField id = "adminPreBreif" value={this.state.adminPreBreif} onChange = {this.adminTimingChanged} type="number" inputProps = {{step : .1}}></TextField></Grid>
                    <Grid item xs = {2.4}><h4>A/C Time</h4><TextField value={this.state.adminACTime} id = "adminACTime" onChange = {this.adminTimingChanged} type="number" inputProps = {{step : .1}}></TextField></Grid>
                    <Grid item xs = {2.4}><h4>Post-Brief</h4><TextField value={this.state.adminPostBreif} id = "adminPostBreif" onChange = {this.adminTimingChanged} type="number" inputProps = {{step : .1}}></TextField></Grid>
                    <Grid item xs = {2.4}><h4>End</h4><TextField type = "time" value = {this.state.adminEndTime}></TextField></Grid>
                    <h4>Comments</h4>
                        <TextField style = {{width : '100%'}} onChange = {this.changeAdminParams} value = {this.props.docRef.comment} name = "adminComment" disabled></TextField>
                    </Grid>
                </div>
                
            </Card>
        )
    }
}

export default SchedulingActivityEdit