import React, { Component } from "react";
import * as firebase from "firebase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
class AircraftFlightSupRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceName: this.props.resource,
      status: this.props.status,
      hobbsTime: this.props.hobbsTime,
      tachTime: this.props.tachTime,
      aircraftType: this.props.aircraftType,
      nextEventName: this.props.nextEventName,
      nextEventTime: this.props.nextEventTime,
    };
    this.setSettings = this.setSettings.bind(this);
    this.updateAircraft = this.updateAircraft.bind(this);
  }

  setSettings(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  updateAircraft() {
    let db = firebase.firestore();
    let a = this;
    let docRef = db.collection("aircraft").doc(this.state.resourceName);

    if (this.state.status == "Available") {
      docRef.update({
        isAssigned: false,
        isDowned: false,
        isRampedOut: false,
        nextEventName: a.state.nextEventName,
        nextEventTime: a.state.nextEventTime,
      });
    }

    if (this.state.status == "DOWNED") {
      docRef.update({
        isAssigned: false,
        isDowned: true,
        isRampedOut: false,
        nextEventName: a.state.nextEventName,
        nextEventTime: a.state.nextEventTime,
      });
    }

    if (this.state.status == "Ramped-In") {
      docRef.update({
        isAssigned: false,
        isDowned: false,
        isRampedOut: false,
        nextEventName: a.state.nextEventName,
        nextEventTime: a.state.nextEventTime,
      });
    }

    if (this.state.status == "Ramped-Out") {
      docRef.update({
        isAssigned: true,
        isDowned: false,
        isRampedOut: true,
        nextEventName: a.state.nextEventName,
        nextEventTime: a.state.nextEventTime,
      });
    }
  }
  render() {
    return (
      <TableRow>
        <TableCell>{this.state.resourceName}</TableCell>
        <TableCell>{this.state.aircraftType}</TableCell>
        <TableCell>
          <Select
            name={"status"}
            value={this.state.status}
            onChange={this.setSettings}
          >
            <MenuItem value={"Available"}>Available</MenuItem>
            <MenuItem value={"Ramped-In"}>Ramped-In</MenuItem>
            <MenuItem value={"Ramped-Out"}>Ramped-Out</MenuItem>
            <MenuItem value={"DOWNED"}>Downed</MenuItem>
          </Select>
        </TableCell>
        <TableCell>{this.state.hobbsTime}</TableCell>
        <TableCell>{this.state.tachTime}</TableCell>
        <TableCell>
          <Select
            name={"nextEventName"}
            value={this.state.nextEventName}
            onChange={this.setSettings}
          >
            <MenuItem value={"Phase #1"}>Phase #1</MenuItem>
            <MenuItem value={"Phase #2"}>Phase #2</MenuItem>
            <MenuItem value={"Phase #3"}>Phase #3</MenuItem>
          </Select>
        </TableCell>
        <TableCell>
          <TextField
            defaultValue={this.state.nextEventTime}
            name={"nextEventTime"}
            onChange={this.setSettings}
          ></TextField>
        </TableCell>
        <TableCell>{this.state.nextEventTime - this.state.tachTime}</TableCell>
        <TableCell>
          <Button onClick={this.updateAircraft}>Update</Button>
        </TableCell>
      </TableRow>
    );
  }
}

export default AircraftFlightSupRow;
