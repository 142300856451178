import React, { Component } from "react";
import * as firebase from "firebase";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { ThemeProvider } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
class DispatcherActivityView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: props.student,
      instructor: props.instructor,
      startTime: props.startTime,
      stopTime: props.stopTime,
      status: props.status,
      resourceType: props.resourceType,
      resource: props.resource,
      id: props.id,
      nextEventName: props.nextEventName,
      nextEventTime: props.nextEventTime,
      assignResourceDialogIsOpen: false,
      resHolder: props.resource,
      rampOutDialogIsOpen: false,
      hobbsTimeError: true,
      tachTimeError: true,
      hobbsTime: "",
      tachTime: "",
      discrepency: true,
      pulledHobbsTime: 0,
      pulledTachTime: 0,
      rampInDialogIsOpen: false,
      actTime: "",
      pulledAircraft: [],
      checkInDialogOpen: false,
      checkInSelection: "Checked-In",
      destinations: [],
      destination: props.destination,
      dispatcherComment: props.dispatcherComment,
      refresh: this.props.refresh,
      hobbsDiscrepancy: "",
      tachDiscrepancy: "",
      aircraftDowned: false,
      downAircraft: false,
      startResTime: this.props.startResTime,
      dueback: this.props.dueback,
      adjustedDueBack: 0,
      adjustedDueBackTime: this.props.dueback * 1000,
    };
    this.returnIfEnabled = this.returnIfEnabled.bind(this);
    this.checkInButtonPressed = this.checkInButtonPressed.bind(this);
    this.assignResourceButtonPressed = this.assignResourceButtonPressed.bind(
      this
    );
    this.rampOutButtonPressed = this.rampOutButtonPressed.bind(this);
    this.rampInbuttonPressed = this.rampInbuttonPressed.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.aircraftResourceChange = this.aircraftResourceChange.bind(this);
    this.submitResourceAssignment = this.submitResourceAssignment.bind(this);
    this.handleRampoutClose = this.handleRampoutClose.bind(this);
    this.onTxtChange = this.onTxtChange.bind(this);
    this.checkForError = this.checkForError.bind(this);
    this.submitRampOut = this.submitRampOut.bind(this);
    this.handleRampInClose = this.handleRampInClose.bind(this);
    this.checkForRampInError = this.checkForRampInError.bind(this);
    this.onRampInTxtChange = this.onRampInTxtChange.bind(this);
    this.submitRampIn = this.submitRampIn.bind(this);
    this.handleCheckInClose = this.handleCheckInClose.bind(this);
    this.activityCheckIn = this.activityCheckIn.bind(this);
    this.handleCheckInChange = this.handleCheckInChange.bind(this);
    this.setPracticeArea = this.setPracticeArea.bind(this);
    this.setHobbsDiscrepancy = this.setHobbsDiscrepancy.bind(this);
    this.setTachDiscrepancy = this.setTachDiscrepancy.bind(this);
    this.downAircraft = this.downAircraft.bind(this);
    this.changeSquakReason = this.changeSquakReason.bind(this);
    this.adjustDuebackTime = this.adjustDuebackTime.bind(this);
    this.displayDuebackTime = this.displayDuebackTime.bind(this);
    this.getAircraft = this.getAircraft.bind(this)
  }
  componentWillMount() {
    console.log(this.state.resourceType)
    this.setState({ pulledAircraft: [] });
    var db = firebase.firestore();
    var a = this;
    if (this.state.resource != null) {
      var acDocRef = db.collection("aircraft").doc(this.props.resource);
      acDocRef.get().then(function (doc) {
        if (doc.exists) {
          a.setState({ pulledHobbsTime: doc.data().hobbsTime });
          a.setState({ pulledTachTime: doc.data().tachTime });
          a.setState({ hobbsTimeError: true });
          a.setState({ tachTimeError: true });
          a.setState({ discrepency: true });
          a.setState({ nextEventName: doc.data().nextEventName });
          a.setState({ nextEventTime: doc.data().nextEventTime });
        }
      });
    }
    var acDocRef = db.collection("aircraft");
    acDocRef
      .where("isAssigned", "==", false)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          console.log(doc.data().aircraftType + ' ' + a.state.resourceType)
          console.log(doc.data().aircraftType == a.state.resourceType)
          if (doc.data().isDowned != true && doc.data().aircraftType == a.state.resourceType) {
            var holder = a.state.pulledAircraft;
            holder.push(<MenuItem value={doc.id}>{doc.id}</MenuItem>);
            a.setState({ pulledAircraft: holder });
          }
        });
      });
    var areaDocRef = db.collection("destinations");
    areaDocRef.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        var holder = a.state.destinations;
        holder.push(<MenuItem value={doc.id}>{doc.id}</MenuItem>);
        a.setState({ destinations: holder });
      });
    });
    if (this.state.resHolder != null || this.state.resHolder != "") {
      var holder = this.state.pulledAircraft;
      this.state.pulledAircraft.push(
        <MenuItem value={this.state.resHolder}>{this.state.resHolder}</MenuItem>
      );
      this.setState({ pulledAircraft: holder });
    }
    if (this.state.dispatcherComment == null) {
      this.setState({ dispatcherComment: "" });
    }
    this.displayDuebackTime();
  }

  getAircraft(){

  }

  returnTime(convertTime) {
    let d = new Date(convertTime * 1000);
    return Moment(d).format("HH:mm");
  }

  returnIfEnabled(buttonId) {
    let currentStatus = this.state.status;
    if (buttonId == "Check-In" && currentStatus == "Scheduled") {
      return false;
    }
    if (buttonId == "Resource" && currentStatus == "Authorized") {
      return false;
    }
    if (buttonId == "Ramp-Out" && currentStatus == "Authorized") {
      return false;
    }
    if (buttonId == "Ramp-In" && currentStatus == "Ramped-Out") {
      return false;
    }
    return true;
  }
  checkInButtonPressed() {
    this.setState({ checkInDialogOpen: true });
  }

  activityCheckIn() {
    let d = new Date();
    let monthHolder = d.getMonth();
    let dayHolder = d.getDate();
    let yearHolder = d.getFullYear();
    let db = firebase.firestore();
    let a = this;
    var docRef = db
      .collection("schedules")
      .doc(monthHolder + 1 + "-" + dayHolder + "-" + yearHolder)
      .collection("activities")
      .doc(this.state.id);
    
    console.log(this.state.status + this.state.student)
    if(this.state.checkInSelection == 'No-Show' || this.state.checkInSelection == 'Unprepared'){
      db.collection('users').doc(this.state.student).collection('noShows').doc(this.state.id).set({
        date : a.state.startTime,
        status : 'Open'
      })
    }

    
    if(this.state.destination != null && this.state.resHolder != null){
    docRef
      .update({
        status: this.state.checkInSelection,
        destination: this.state.destination,
        dispatcherComment: this.state.dispatcherComment,
        resource: this.state.resHolder,
      })
      .then(function () {
        a.state.refresh();
      });
    }else{
      docRef
      .update({
        status: this.state.checkInSelection,
        dispatcherComment: this.state.dispatcherComment,
        
      })
      .then(function () {
        a.state.refresh();
      });
    }
    this.handleCheckInClose();
  }
  assignResourceButtonPressed() {
    this.setState({ assignResourceDialogIsOpen: true });
  }
  submitResourceAssignment() {
    let d = new Date();
    let dayHodler = d.getDate();
    let monthHolder = d.getMonth() + 1;
    let yearHolder = d.getFullYear();
    let db = firebase.firestore();
    let a = this;
    var docRef = db
      .collection("schedules")
      .doc(monthHolder + "-" + dayHodler + "-" + yearHolder)
      .collection("activities")
      .doc(this.state.id);
    docRef.update({ resource: this.state.resHolder }).then(function () {
      a.state.refresh();
    });
    var acDocRef = db.collection("aircraft").doc(this.state.resHolder);
    acDocRef.update({ isAssigned: true });
  }
  rampOutButtonPressed() {
    this.setState({ rampOutDialogIsOpen: true });
  }
  rampInbuttonPressed() {
    this.setState({ rampInDialogIsOpen: true });
  }
  handleClose() {
    this.setState({ assignResourceDialogIsOpen: false });
  }
  handleRampoutClose() {
    this.setState({ rampOutDialogIsOpen: false });
  }
  handleRampInClose() {
    this.setState({ rampInDialogIsOpen: false });
  }
  handleCheckInClose() {
    this.setState({ checkInDialogOpen: false });
  }
  aircraftResourceChange(event) {
    let db = firebase.firestore()
    let a = this
    this.setState({ resHolder: event.target.value });
    var acDocRef = db.collection("aircraft").doc(event.target.value);
      acDocRef.get().then(function (doc) {
        if (doc.exists) {
          a.setState({ pulledHobbsTime: doc.data().hobbsTime });
          a.setState({ pulledTachTime: doc.data().tachTime });
          a.setState({tachTime : doc.data().tachTime})
          a.setState({ hobbsTimeError: true });
          a.setState({ tachTimeError: true });
          a.setState({ discrepency: true });
          a.setState({ nextEventName: doc.data().nextEventName });
          a.setState({ nextEventTime: doc.data().nextEventTime });
        }
      });
  }
  onTxtChange(event) {
    var tID = event.target.id;
    var val = event.target.value;
    this.setState({ [tID]: val }, () => this.checkForError());
  }
  onRampInTxtChange(event) {
    var tID = event.target.id;
    var val = event.target.value;
    this.setState({ [tID]: val }, () => this.checkForRampInError());
  }
  checkForError() {
    let a = this;

    if (this.state.pulledHobbsTime == this.state.hobbsTime) {
      a.setState({ hobbsTimeError: false });
    } else {
      a.setState({ hobbsTimeError: false });
    }
    if (this.state.pulledTachTime == this.state.tachTime) {
      a.setState({ tachTimeError: false });
    } else {
      a.setState({ tachTimeError: false });
    }
    if (
      this.state.pulledHobbsTime == this.state.hobbsTime &&
      this.state.pulledTachTime == this.state.tachTime
    ) {
      a.setState({ discrepency: false });
    } else {
      a.setState({ discrepency: false });
    }
  }
  checkForRampInError() {
    let a = this;
    console.log("checkin for ramp in error");

    if (this.state.pulledHobbsTime <= this.state.hobbsTime) {
      a.setState({ hobbsTimeError: false });
    } else {
      a.setState({ hobbsTimeError: true });
    }
    if (this.state.pulledTachTime <= this.state.tachTime) {
      a.setState({ tachTimeError: false });
    } else {
      a.setState({ tachTimeError: true });
    }
    if (
      this.state.pulledHobbsTime <= this.state.hobbsTime &&
      this.state.pulledTachTime <= this.state.tachTime
    ) {
      a.setState({ discrepency: false });
      console.log("NO DIS");
      console.log(
        parseFloat(a.state.hobbsTime) - parseFloat(a.state.pulledHobbsTime)
      );
      a.setState({
        actTime: (
          parseFloat(a.state.hobbsTime) - parseFloat(a.state.pulledHobbsTime)
        ).toFixed(1),
      });
    } else {
      a.setState({ discrepency: true });
    }

    if (this.state.hobbsTime != "" && this.state.tachTime != "") {
      a.setState({ discrepency: false });
      a.setState({
        actTime: (
          parseFloat(a.state.hobbsTime) - parseFloat(a.state.pulledHobbsTime)
        ).toFixed(1),
      });
    }
  }
  submitRampOut() {
    let a = this;
    if (this.state.discrepency == false) {
      let d = new Date();
      let monthHolder = d.getMonth();
      let dayHolder = d.getDate();
      let yearHolder = d.getFullYear();
      let db = firebase.firestore();
      var docRef = db
        .collection("schedules")
        .doc(monthHolder + 1 + "-" + dayHolder + "-" + yearHolder)
        .collection("activities")
        .doc(this.state.id);
      docRef
        .update({
          status: "Ramped-Out",
          endResTime: new Date(this.state.adjustedDueBackTime),
          resource : this.state.resHolder,
          destination : this.state.destination
        })
        .then(function () {
          a.state.refresh();
        });
      var acDocRef = db.collection("aircraft").doc(this.state.resHolder);
      acDocRef.update({ isRampedOut: true, isAssigned : true });
      this.setState({ rampOutDialogIsOpen: false });
    }

    if (this.state.hobbsDiscrepancy != "" || this.state.tachDiscrepancy != "") {
      let db = firebase.firestore();
      var acDRef = db.collection("aircraft").doc(a.state.resHolder);
      acDRef.collection("discrepancies").doc().set({
        date: a.state.startTime,
        hobbsTimeInital: a.state.pulledHobbsTime,
        hobbsTimeNew: a.state.hobbsTime,
        hobbsTimeReason: a.state.hobbsDiscrepancy,
        tachTimeInital: a.state.pulledHobbsTime,
        tachTimeNew: a.state.tachTime,
        tachTimeReason: a.state.tachDiscrepancy,
      });
      acDocRef.update({
        hobbsTime: parseFloat(a.state.hobbsTime).toFixed(1),
        tachTime: parseFloat(a.state.tachTime).toFixed(1),
      });
    }
  }
  submitRampIn() {
    let a = this;
    console.log("testing ramp in");
    if (this.state.discrepency == false || this.state.discrepency == true) {
      console.log("discrepency test complete!");
      let d = new Date();
      let monthHolder = d.getMonth();
      let dayHolder = d.getDate();
      let yearHolder = d.getFullYear();
      let db = firebase.firestore();
      var docRef = db
        .collection("schedules")
        .doc(monthHolder + 1 + "-" + dayHolder + "-" + yearHolder)
        .collection("activities")
        .doc(this.state.id);
      docRef
        .update({
          status: "Ramped-In",
          hobbsTime: (
            parseFloat(a.state.hobbsTime) - parseFloat(a.state.pulledHobbsTime)
          ).toFixed(1),
          tachTime: (
            parseFloat(a.state.tachTime) - parseFloat(a.state.tachTime)
          ).toFixed(1),
          acHobbsTime: (
            parseFloat(a.state.hobbsTime) - parseFloat(a.state.pulledHobbsTime)
          ).toFixed(1),
          dispatcherComment: this.state.dispatcherComment,
        })
        .then(function () {
          a.state.refresh();
        });
      var acDocRef = db.collection("aircraft").doc(this.state.resHolder);
      acDocRef.update({
        isRampedOut: false,
        isAssigned: false,
        hobbsTime: this.state.hobbsTime,
        tachTime: this.state.tachTime,
        isDowned: this.state.downAircraft,
      });
      this.setState({ rampOutDialogIsOpen: false });

      if (this.state.downAircraft == true) {
        acDocRef.collection("squaks").doc().set({
          date: new Date(),
          instructor: a.state.instructor,
          student: a.state.student,
          activity: a.state.id,
          hobbsTime: a.state.hobbsTime,
          tachTime: a.state.tachTime,
          reason: a.state.squakReason,
        });
      }
    }
    //this.state.refresh()
  }
  handleCheckInChange(event) {
    this.setState({ checkInSelection: event.target.value });
  }
  setPracticeArea(event) {
    this.setState({ destination: event.target.value });
  }
  setHobbsDiscrepancy(event) {
    this.setState({ hobbsDiscrepancy: event.target.value });
  }
  setTachDiscrepancy(event) {
    this.setState({ tachDiscrepancy: event.target.value });
  }
  downAircraft(event) {
    if (this.state.downAircraft == false) {
      this.setState({ downAircraft: true });
    } else {
      this.setState({ downAircraft: false });
    }
  }
  changeSquakReason(event) {
    this.setState({ squakReason: event.target.value });
  }
  adjustDuebackTime(event) {
    this.setState({ adjustedDueBack: event.target.value }, () =>
      this.displayDuebackTime()
    );
    console.log("ADJUSTING DUEBACK");
  }
  displayDuebackTime() {
    console.log("DISPLAYING DUEBACK");
    let e = new Date(this.state.dueback.seconds * 1000);
    console.log(this.returnTime(e));
    console.log(this.state.adjustedDueBack);
    e.setUTCMinutes(e.getMinutes() + this.state.adjustedDueBack * 60);
    console.log(e);
    console.log(e.getHours() + ":" + e.getMinutes());
    console.log(this.returnTime(e));

    this.setState({ adjustedDueBackTime: e });
  }
  render() {
    return (
      <TableRow>
        <TableCell>{this.state.student}</TableCell>
        <TableCell>{this.state.instructor}</TableCell>
        <TableCell>{this.returnTime(this.state.startTime)}</TableCell>
        <TableCell>{this.returnTime(this.state.stopTime)}</TableCell>
        <TableCell>{this.props.resourceType}</TableCell>
        <TableCell>{this.props.resource}</TableCell>
        <TableCell>{this.props.status}</TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            disabled={this.returnIfEnabled("Check-In")}
            onClick={this.checkInButtonPressed}
          >
            Check In
          </Button>
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            disabled={this.returnIfEnabled("Resource")}
            onClick={this.assignResourceButtonPressed}
          >
            Assign Resource
          </Button>
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            disabled={this.returnIfEnabled("Ramp-Out")}
            onClick={this.rampOutButtonPressed}
          >
            Ramp Out
          </Button>
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            disabled={this.returnIfEnabled("Ramp-In")}
            onClick={this.rampInbuttonPressed}
          >
            Ramp In
          </Button>
        </TableCell>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.assignResourceDialogIsOpen}
          onClose={this.handleClose}
        >
          <DialogTitle id="simple-dialog-title">
            Assign Resource for : {this.props.student}
          </DialogTitle>
          <Select
            value={this.state.resHolder}
            onChange={this.aircraftResourceChange}
          >
            {this.state.pulledAircraft}
          </Select>
          <Button color="primary" onClick={this.submitResourceAssignment}>
            Assign
          </Button>
          <Button color="secondary" onClick={this.handleClose}>
            Close
          </Button>
        </Dialog>
        <Dialog>
          <Card style={cardStyle}>
            <Grid container>
              <Grid item xs={12}>
                <h1>
                  Ramp Out for {this.state.resHolder} assigned to{" "}
                  {this.state.student}
                </h1>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="standard-basic"
                  label="Hobbs Time"
                  disabled
                  defaultValue={this.state.pulledHobbsTime}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="hobbsTime"
                  label="Hobbs Time"
                  error={this.state.hobbsTimeError}
                  onChange={this.onTxtChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="standard-basic"
                  label="Tach Time"
                  disabled
                  defaultValue={this.state.pulledTachTime}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="tachTime"
                  label="Tach Time"
                  error={this.state.tachTimeError}
                  onChange={this.onTxtChange}
                />
              </Grid>
              <Grid item xs={12}>
                <h1></h1>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={this.state.discrepency}
                  onClick={this.submitRampOut}
                >
                  Ramp Out
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button color="secondary" onClick={this.handleRampoutClose}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Dialog>
        <Modal
          aria-labelledby="simple-dialog-title"
          open={this.state.rampOutDialogIsOpen}
          onClose={this.handleRampoutClose}
        >
          <Card
            style={{
              width: "75%",
              align: "center",
              marginLeft: "15%",
              marginTop: "5%",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <h1 style={{ textAlign: "center" }}>
                  Ramp Out for {this.state.resHolder} assigned to{" "}
                  {this.state.instructor}
                </h1>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <h3>Resource : </h3>
                  <Select
                    value={this.state.resHolder}
                    onChange={this.aircraftResourceChange}
                  >
                    {this.state.pulledAircraft}
                  </Select>
                </Grid>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Grid container>
                  <h3>Destination : </h3>
                  <Select
                    onChange={this.setPracticeArea}
                    value={this.state.destination}
                  >
                    {this.state.destinations}
                  </Select>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Last</TableCell>
                      <TableCell>Out</TableCell>
                      <TableCell>Discrepancy</TableCell>
                      <TableCell>Discrepancy Reason</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Hobbs</TableCell>
                      <TableCell>{this.state.pulledHobbsTime}</TableCell>
                      <TableCell>
                        {" "}
                        <TextField
                          id="hobbsTime"
                          label="Hobbs Time"
                          error={this.state.hobbsDiscrepancy}
                          defaultValue={this.state.hobbsTime}
                          onChange={this.onTxtChange}
                        />{" "}
                      </TableCell>
                      <TableCell>
                        {this.state.hobbsTime - this.state.pulledHobbsTime}
                      </TableCell>
                      <TableCell>
                        <Select
                          id="hobbsDiscrepancy"
                          value={this.state.hobbsDiscrepancy}
                          onChange={this.setHobbsDiscrepancy}
                        >
                          <MenuItem value={"SFS_TEST"}>SFS Test</MenuItem>
                          <MenuItem value={"Maintenance"}>Maintenance</MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                          <MenuItem value={"Retro_Activity"}>
                            Retro Activity
                          </MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Tach</TableCell>
                      <TableCell>{this.state.pulledTachTime}</TableCell>
                      <TableCell>
                        <TextField
                          id="tachTime"
                          label="Tach Time"
                          error={this.state.tachDiscrepancy}
                          defaultValue={this.state.tachTime}
                          onChange={this.onTxtChange}
                        />
                      </TableCell>
                      <TableCell>
                        {this.state.tachTime - this.state.pulledTachTime}
                      </TableCell>
                      <TableCell>
                        <Select
                          id="tachDiscrepancy"
                          value={this.state.tachDiscrepancy}
                          onChange={this.setTachDiscrepancy}
                        >
                          <MenuItem value={"SFS_TEST"}>SFS Test</MenuItem>
                          <MenuItem value={"Maintenance"}>Maintenance</MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                          <MenuItem value={"Retro_Activity"}>
                            Retro Activity
                          </MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>

              <Grid item xs={4}>
                <h3>Due Back : {this.returnTime(this.state.dueback)}</h3>
              </Grid>

              <Grid item xs={4}>
                <Grid container>
                  <h3>Adjusted Due Back : </h3>
                  <Select
                    id="adjustedDueBack"
                    value={this.state.adjustedDueBack}
                    onChange={this.adjustDuebackTime}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={0.1}>.1</MenuItem>
                    <MenuItem value={0.2}>.2</MenuItem>
                    <MenuItem value={0.3}>.3</MenuItem>
                    <MenuItem value={0.4}>.4</MenuItem>
                    <MenuItem value={0.5}>.5</MenuItem>
                    <MenuItem value={0.6}>.6</MenuItem>
                    <MenuItem value={0.7}>.7</MenuItem>
                    <MenuItem value={0.8}>.8</MenuItem>
                    <MenuItem value={0.9}>.9</MenuItem>
                    <MenuItem value={1.0}>1.0</MenuItem>
                  </Select>
                  <h3>
                    {Moment(this.state.adjustedDueBackTime).format("HH:mm")}
                  </h3>
                </Grid>
              </Grid>
              <Grid item xs={4}></Grid>

              <Grid item xs={12}>
                <TextField
                  id="dispatcherComment"
                  label="Dispatch Comment"
                  style={{ width: "100%" }}
                  onChange={this.onTxtChange}
                  value={this.state.dispatcherComment}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> </TableCell>
                      <TableCell>Event</TableCell>
                      <TableCell>Next Event At</TableCell>
                      <TableCell>Remaining</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Tach</TableCell>
                      <TableCell>{this.state.nextEventName}</TableCell>
                      <TableCell>{this.state.nextEventTime}</TableCell>
                      <TableCell>
                        {this.state.nextEventTime - this.state.tachTime}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12}>
                <h1></h1>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                  onClick={this.submitRampOut}
                >
                  Ramp-Out
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Modal>
        <Dialog>
          <Card style={cardStyle}>
            <Grid container>
              <Grid item xs={12}>
                <h1>
                  Ramp In for {this.state.resHolder} assigned to{" "}
                  {this.state.student}
                </h1>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="standard-basic"
                  label="Hobbs Time"
                  disabled
                  defaultValue={this.state.pulledHobbsTime}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="hobbsTime"
                  label="Hobbs Time"
                  defaultValue={this.state.hobbs}
                  onChange={this.onRampInTxtChange}
                >
                  {this.state.hobbs}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="standard-basic"
                  label="Tach Time"
                  disabled
                  defaultValue={this.state.pulledTachTime}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="tachTime"
                  label="Tach Time"
                  onChange={this.onRampInTxtChange}
                />
              </Grid>
              <Grid item xs={12}>
                <h1></h1>
              </Grid>
              <Grid item xs={12}>
                <h1></h1>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="aircraftTime"
                  label="Activity Time"
                  disabled
                  value={this.state.actTime}
                />
              </Grid>
              <Grid item xs={12}>
                <h1></h1>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={this.state.discrepency}
                  onClick={this.submitRampIn}
                >
                  Ramp In
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button color="secondary" onClick={this.handleRampInClose}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Dialog>

        <Modal
          aria-labelledby="check-in"
          open={this.state.checkInDialogOpen}
          onClose={this.handleRampInClose}
        >
          <Card
            style={{
              width: "75%",
              align: "center",
              marginLeft: "15%",
              marginTop: "5%",
            }}
          >
            <h1 style={{ textAlign: "center" }}>Activity Check In</h1>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Position</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Open FIFs</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>PIC/IP</TableCell>
                  <TableCell>{this.state.instructor}</TableCell>
                  <TableCell>False</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Student 1</TableCell>
                  <TableCell>{this.state.student}</TableCell>
                  <TableCell>False</TableCell>
                  <TableCell>
                    <Select
                      value={this.state.checkInSelection}
                      onChange={this.handleCheckInChange}
                    >
                      <MenuItem value={"Checked-In"}>Check-In</MenuItem>
                      <MenuItem value={"No-Show"}>No Show</MenuItem>
                      <MenuItem value={"Unprepared"}>Unprepared</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Grid container>
              <Grid item xs={4}>
                <h3>Event Start : {this.returnTime(this.state.startTime)}</h3>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <h3>Destination : </h3>
                  <Select
                    onChange={this.setPracticeArea}
                    value={this.state.destination}
                  >
                    {this.state.destinations}
                  </Select>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <h3 style={{ textAlign: "right" }}>
                  Event Stop : {this.returnTime(this.state.stopTime)}
                </h3>
              </Grid>
              <Grid item xs={4}>
                <h3>Requested Resource Type : {this.state.resourceType}</h3>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <h3>Resource : </h3>
                  <Select
                    value={this.state.resHolder}
                    onChange={this.aircraftResourceChange}
                  >
                    {this.state.pulledAircraft}
                  </Select>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <h3 style={{ textAlign: "right" }}>
                  Resoruce Time : {this.returnTime(this.state.startResTime)}
                </h3>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="dispatcherComment"
                  label="Dispatch Comment"
                  style={{ width: "100%" }}
                  onChange={this.onTxtChange}
                  defaultvalue={this.state.dispatcherComment}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <h3></h3>
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  onClick={this.activityCheckIn}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={12}>
                <h3></h3>
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={{ width: "100%" }}
                  variant="outlined"
                  color="secondary"
                  onClick={this.handleCheckInClose}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Modal>

        <Modal
          aria-labelledby="simple-dialog-title"
          open={this.state.rampInDialogIsOpen}
          onClose={this.handleRampInClose}
        >
          <Card
            style={{
              width: "75%",
              align: "center",
              marginLeft: "15%",
              marginTop: "5%",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <h1 style={{ textAlign: "center" }}>
                  Ramp In for {this.state.resHolder} assigned to{" "}
                  {this.state.instructor}
                </h1>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <h3>Resource : </h3>
                  <Select
                    value={this.state.resHolder}
                    onChange={this.aircraftResourceChange}
                    disabled
                  >
                    {this.state.pulledAircraft}
                  </Select>
                </Grid>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Grid container>
                  <h3>Destination : </h3>
                  <Select
                    onChange={this.setPracticeArea}
                    value={this.state.destination}
                    disabled
                  >
                    {this.state.destinations}
                  </Select>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Last</TableCell>
                      <TableCell>In</TableCell>
                      <TableCell>Total Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Hobbs</TableCell>
                      <TableCell>{this.state.pulledHobbsTime}</TableCell>
                      <TableCell>
                        {" "}
                        <TextField
                          id="hobbsTime"
                          label="Hobbs Time"
                          error={this.state.hobbsDiscrepancy}
                          defaultValue={this.state.hobbsTime}
                          onChange={this.onTxtChange}
                        />{" "}
                      </TableCell>
                      <TableCell>
                        {this.state.hobbsTime - this.state.pulledHobbsTime}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Tach</TableCell>
                      <TableCell>{this.state.pulledTachTime}</TableCell>
                      <TableCell>
                        <TextField
                          id="tachTime"
                          label="Tach Time"
                          error={this.state.tachDiscrepancy}
                          defaultValue={this.state.tachTime}
                          onChange={this.onTxtChange}
                        />
                      </TableCell>
                      <TableCell>
                        {this.state.tachTime - this.state.pulledTachTime}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="dispatcherComment"
                  label="Dispatch Comment"
                  style={{ width: "100%" }}
                  onChange={this.onTxtChange}
                  value={this.state.dispatcherComment}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> </TableCell>
                      <TableCell>Event</TableCell>
                      <TableCell>Next Event At</TableCell>
                      <TableCell>Remaining</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Tach</TableCell>
                      <TableCell>{this.state.nextEventName}</TableCell>
                      <TableCell>{this.state.nextEventTime}</TableCell>
                      <TableCell>
                        {this.state.nextEventTime - this.state.tachTime}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <h3>Aircraft Squak : </h3>
                  <Select onChange={this.changeSquakReason}>
                    <MenuItem value={"compInop"}>Computer Inop</MenuItem>
                    <MenuItem value={"event"}>Event</MenuItem>
                    <MenuItem value={"mxRequest"}>Maintenance Request</MenuItem>
                    <MenuItem value={"sqAirframe"}>Squawk Airframe</MenuItem>
                    <MenuItem value={"sqAvionics"}>Squawk Avionics</MenuItem>
                    <MenuItem value={"sqControls"}>Squawk Controls</MenuItem>
                    <MenuItem value={"sqElectronics"}>
                      Squawk Electronics
                    </MenuItem>
                    <MenuItem value={"sqEngine"}>Squawk Engine</MenuItem>
                    <MenuItem value={"sqInst"}>Squawk Inst</MenuItem>
                    <MenuItem value={"sqLights"}>Squawk Lights</MenuItem>
                  </Select>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.downAircraft}
                        onChange={this.downAircraft}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Down Aircraft"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <h1></h1>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                  onClick={this.submitRampIn}
                >
                  Ramp-In
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Modal>
      </TableRow>
    );
  }
}

const cardStyle = {
  textAlign: "center",
};

export default DispatcherActivityView;
