import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CouseCreateObjectContainer from "./courseCreateObjectContainer";
class CouseCreateObject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objs: 0,
      id: this.props.id,
      pushBack: this.props.pushBack,
      isGraded: true,
      objectives: [],
    };
    this.generateUnits = this.generateUnits.bind(this);

    this.changeGradeStatus = this.changeGradeStatus.bind(this);
  }

  changeGradeStatus(id, name, type) {
    var holder = this.state.objectives;
    holder[id] = [name, type];
    console.log("DID TRIGGER " + this.state.id);
    this.setState({ objectives: holder }, () =>
      this.props.pushBack(id, this.state.objectives)
    );
  }
  generateUnits() {
    if (this.state.objs != 0) {
      var coms = [];
      for (var i = 0; i < this.state.objs; i++) {
        coms.push(
          <CouseCreateObjectContainer
            pushBack={this.changeGradeStatus}
            id={i}
          ></CouseCreateObjectContainer>
        );
      }
      return coms;
    } else {
      return null;
    }
  }
  componentWillReceiveProps(props) {
    this.setState({ objs: props.objs });
    this.render();
  }
  render() {
    return <div>{this.generateUnits()}</div>;
  }
}
const cardStyle = {
  marginTop: "10px",
  marginLeft: "20px",
  width: "1000",
  marginBottom: "10px",
};

export default CouseCreateObject;
