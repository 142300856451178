import React, { Component } from "react";
import * as firebase from "firebase";
import Draggable, {DraggableCore} from 'react-draggable';
import DispatcherView from './dispatcherView'
import DispatchGraphs from './dispatchGraphs' 
import Card from '@material-ui/core/Card'
class DispatchDragableBeta extends Component {
    render(){
        return(
            <div>
            <Card style = {{height : '50px', marginTop : '0px'}}>
                <h3>Dispatcher Beta</h3>
            </Card>
            <Draggable
            
            handle=".handle"
            defaultPosition={{x: 0, y: 0}}
            position={null}
            grid={[25, 25]}
            scale={1}
            onStart={this.handleStart}
            onDrag={this.handleDrag}
            onStop={this.handleStop}>
            <div>
              <div className="handle">
              <DispatcherView></DispatcherView>
              </div>
            </div>
          </Draggable>

          <Draggable
            
            handle=".handle"
            defaultPosition={{x: 0, y: 0}}
            position={null}
            grid={[25, 25]}
            scale={1}
            onStart={this.handleStart}
            onDrag={this.handleDrag}
            onStop={this.handleStop}>
            <div>
              <div className="handle">
              <DispatchGraphs></DispatchGraphs>
              </div>
            </div>
          </Draggable>


          </div>
          
          
        )
    }
}

export default DispatchDragableBeta