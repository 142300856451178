import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import PermMediaOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActual";
import PublicIcon from "@material-ui/icons/Public";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import TimerIcon from "@material-ui/icons/Timer";
import SettingsIcon from "@material-ui/icons/Settings";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import Content from "./Content";
import Logbook from "./logbook";
import { Route, Link } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import seerLogo from "./Seer_logo.png";
import DispatcherView from "./dispatcherView";
import ScheduleIcon from '@material-ui/icons/Schedule';
import FlightIcon from '@material-ui/icons/Flight';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import TodayIcon from '@material-ui/icons/Today';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PersonIcon from '@material-ui/icons/Person';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import GroupIcon from '@material-ui/icons/Group';
import CreateIcon from '@material-ui/icons/Create';
import FolderIcon from '@material-ui/icons/Folder';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AssessmentIcon from '@mui/icons-material/Assessment';
const categories = [
  {
    id: "My Schedule",
    children: [
      {
        id: "My Schedule",
        icon: <ScheduleIcon />,
        to: "/schedule/",
        active: false,
      },
      { id: "Aircraft", icon: <FlightIcon />, to: "/aircraft" },
      { id: "My Logbook", icon: <FlightTakeoffIcon />, to: "/logbook" },
      { id: "My Issues", icon: <DnsRoundedIcon />, to: "/myIssues" },
      { id: "My Open Cashiers", icon: <AccountBalanceIcon />, to: "/openCashiers" },
    ],
  },
  {
    id: "Instructor",
    children: [
      { id: "Day View", icon: <ScheduleIcon />, to: "/instructorDayACView/" },
      { id: "Instructor Outlook", icon: <TodayIcon />, to: "/instructorOutlook/" },
      { id: "Scheduler", icon: <EventNoteIcon />, to: "/activityScheduler/" },
      { id: "My Students", icon: <PersonIcon />, to : "/myStudents" },
      {id : "My Payroll", icon : <AttachMoneyIcon/>, to : "/mypayroll"}
    ],
  },
  {
    id: "Dispatch",
    children: [
      { id: "Dispatcher", icon: <FlightTakeoffIcon />, to: "/dispatcherView/" },
      {
        id: "Flight Supervisor",
        icon: <FlightTakeoffIcon />,
        to: "/flightSupervisor/",
      },
      { id: "Aircraft Status", icon: <FlightIcon />, to: "/aircraftStatus/" },
      {
        id: "Aircraft Status(Supervisor)",
        icon: <FlightIcon />,
        to: "/aircraftFlightSup/",
      },
      { id: "Dispatch Graphs", icon: <MultilineChartIcon />, to: "/dispatchGraphs/" },
    ],
  },
  {
    id: "Admin",
    children: [
      { id: "My Team", icon: <GroupIcon /> },
      { id: "Manage Aircraft", icon: <FlightIcon />, to: "/aircraftAdmin/" },
      { id: "Couse Admin", icon: <FolderIcon />, to: "/courseAdmin/" },
      { id: "Course Create", icon: <CreateIcon />, to: "/createCourse/" },
      { id: "Reports", icon: <AssessmentIcon />, to: "/reports/" },
    ],
  },
  {
    id: "Scheduling",
    children: [
      { id: "Graphical Scheduler", icon: <EventNoteIcon />, to : "/dayScheduler" },
      { id: "Activity List", icon: <FormatListBulletedIcon />, to : "/activityList" },
      
    ],
  },
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover,&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  itemCategory: {
    backgroundColor: "#232f3e",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: "#4fc3f7",
  },
  itemPrimary: {
    fontSize: "inherit",
  },
  itemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator2(props) {
  const { classes, ...other } = props;
  const CustomLink = (props) => <Link to={"/homeBase"} />;
  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          className={clsx(classes.firebase, classes.item, classes.itemCategory)}
        >
          <img src={seerLogo} width="200"></img>
        </ListItem>
        <ListItem
          button
          className={clsx(classes.item, classes.itemCategory)}
          component={CustomLink}
        >
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Home Base
          </ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active, to }) => (
              <ListItem
                key={childId}
                button
                component={Link}
                to={to}
                className={clsx(classes.item, active && classes.itemActiveItem)}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

Navigator2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator2);
