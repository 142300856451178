import React, { Component } from "react";
import Card from "@material-ui/core/Card";

class CalendarViewTemplate extends Component {
  render() {
    return (
      <Card>
        <h1>Test</h1>
      </Card>
    );
  }
}

export default CalendarViewTemplate;
