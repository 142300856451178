import React, { Component } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as firebase from "firebase";
import { TableFooter } from "@material-ui/core";
class Payroll extends Component {
    constructor(props){
        super(props)
        this.state = {
            selectedTab : 0,
            payrollActivities : [],
            totalActivityHours : 0,
            totalPayrollAdjustHours : 0,
            totalHours : 0
        }
        this.selectTab = this.selectTab.bind(this)
    }
    componentDidMount(){
        let db = firebase.firestore()
        var acCarry = []
        var totalHours = 0
        db.collection('users').doc('TEST_INSTRUCTOR').collection('payroll').get().then((querySnapshot) => {
            querySnapshot.forEach((doc)=>{
                let activityDate = new Date(doc.data().date)
                acCarry.push(<TableRow>
                    <TableCell>{doc.data().activity}</TableCell>
                    <TableCell>{(activityDate.getMonth()+1)+ "-" + activityDate.getDate() + "-" + activityDate.getFullYear()}</TableCell>
                    <TableCell>{doc.data().unit}</TableCell>
                    <TableCell>{doc.data().student}</TableCell>
                    <TableCell>{doc.data().hours}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>)
                totalHours = totalHours + parseFloat(doc.data().hours)
                this.setState({payrollActivities : acCarry, totalActivityHours : totalHours})
            })
        })

    }

    selectTab(event){
        this.setState({selectedTab : parseInt(event.target.id)})
    }
    render(){
        return(<div>
            <Card>
            <Tabs value = {this.state.selectedTab} onChange={this.selectTab}>
                <Tab label = "Payroll Adjust Requests" value = {0} id = "0"></Tab>
                <Tab label = "Payroll" value = {1} id = "1"></Tab>
            </Tabs>



            <Card hidden = {this.state.selectedTab == 0}>
                <TableContainer component = {Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Activity</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Unit</TableCell>
                                <TableCell>Student</TableCell>
                                <TableCell>Hours</TableCell>
                                <TableCell>Reason</TableCell>
                                <TableCell>Payroll Period</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.payrollActivities}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>Total Hours : {this.state.totalActivityHours}</TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Card>

            <Card hidden = {this.state.selectedTab == 1}>
            <TableContainer component = {Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Submit</TableCell>
                                <TableCell>Actual</TableCell>
                                <TableCell>Requested</TableCell>
                                <TableCell>Reason</TableCell>
                                <TableCell>By</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Comment</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Card>

        </Card>
        </div>)
    }
}

export default Payroll