import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
const Map = ReactMapboxGl({
    accessToken:
      'pk.eyJ1IjoicmVlc2o0IiwiYSI6ImNqb2t6ZmQ0azA3djczcW10c3ZzMDZidnYifQ.5JwBFWWy5k7TjHoLcREeFw'
  });
class PracticeAreaLiveMap extends Component {
    render(){
        return(
            <Card style = {{width : '100%'}}>
             <Map style = "mapbox://styles/reesj4/ck55t15r402i21clrhwy15u3v"
                center = {[-112.420652,34.653496]}
                containerStyle={{
                height: '82vh',
                width: '82vw'
                }}></Map>

            </Card>
        )
    }
}

export default PracticeAreaLiveMap 