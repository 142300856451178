import React, { Component } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button'
import { Typography } from "@mui/material";
import Draggable from 'react-draggable'
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import * as firebase from "firebase";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import { fontWeight } from "@mui/system";
class LinearScheduler extends Component {
    constructor(props){
        super(props)
        this.state = {
            posX : 0,
            posY : 0,
            startTimeHours : 5,
            startTimeMins : '00',
            endTimeHours : 7,
            endTimeMins : 0,
            activityTime : 2,
            activityHidden : true,
            activityPos : null,
            editActivityBackdropOpen : false,
            courseUnits : [],
            preBreif : .3,
            acTime : 1.5,
            postBreif : .2,
            docName : 'reesj4TEST_INSTRUCTORFA-215-DEMOFLIGHT#4',
            scheduleDate : '',
            setDate : '',
            selectedUnit : '',
            activityComment : '',
            dutyDay : 0,
            ddColor : 'green',
            resourceProb : 0 ,
            resourceType : '',
            resource : '',
            editActivityBackdropOpen : false,
            requestTabSelected : 0,
            adminPreBreif : 0,
            adminACTime : 2,
            adminPostBreif : 0,
            adminStartTime : "05:30",
            adminEndTime : "07:30",
            adminDate : "",
            instructor : 'TEST_INSTRUCTOR',
            adminResource : '',
            adminComment : '',
            adminRequestType : '',
            adminChargeCode : '',
            adminReason : '',
            ipActivites : [],
            activityStart : 999999999999999999999999999,
            activityEnd : 0
            
        }
        this.handleStop = this.handleStop.bind(this)
        this.handleDrag = this.handleDrag.bind(this)
        this.hideActivityOne = this.hideActivityOne.bind(this)
        this.showActivityOne = this.showActivityOne.bind(this)
        this.toggleEditBackdrop = this.toggleEditBackdrop.bind(this)
        this.activityTimingChanged = this.activityTimingChanged.bind(this)
        this.updateActivityTiming = this.updateActivityTiming.bind(this)
        this.saveButtonPressed = this.saveButtonPressed.bind(this)
        this.resetActivityPos = this.resetActivityPos.bind(this)
        this.incrementDate = this.incrementDate.bind(this)
        this.refetchData = this.refetchData.bind(this)
        this.changeUnit = this.changeUnit.bind(this)
        this.commentAdded = this.commentAdded.bind(this)
        this.calculateDD = this.calculateDD.bind(this)
        this.calculateResourceProb = this.calculateResourceProb.bind(this)
        this.decrementDate = this.decrementDate.bind(this)
        this.toggleRequestBackdrop = this.toggleRequestBackdrop.bind(this) 
        this.selectTab = this.selectTab.bind(this)
        this.changeAdminRequestDate = this.changeAdminRequestDate.bind(this)
        this.adminTimingChanged = this.adminTimingChanged.bind(this)
        this.changeAdminStartTime = this.changeAdminStartTime.bind(this)
        this.calculateAdminEndTime = this.calculateAdminEndTime.bind(this)
        this.submitAdminActivity = this.submitAdminActivity.bind(this)
        this.changeAdminParams = this.changeAdminParams.bind(this)
        this.getIPActivities = this.getIPActivities.bind(this)
        this.getActivityRes = this.getActivityRes.bind(this)
        this.changeRequestedRes = this.changeRequestedRes.bind(this)
        this.getAdminResource = this.getAdminResource.bind(this)
    }
    componentDidMount(){
        let units = []
        let a = this
        var db = firebase.firestore();
        var docRef = db.collection("courses").doc('FA-215-DEMO');
        docRef.get().then(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          console.log(doc.data().lessons);
          let lessons = doc.data().lessons
          for(var i = 0; i < lessons.length; i = i + 1){
            console.log(lessons[i])
            units.push(<MenuItem value = {lessons[i]}>{lessons[i]}</MenuItem>)
            a.setState({courseUnits : units})
          }
          a.setState({resourceType : doc.data().acType}, ()=>a.getActivityRes())
        
          //lessons.push(doc.data().lessons);
        
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });

      let d = new Date()
      let scheduleDate = d.getMonth() + 1 + "-" + (d.getDate()+1) + "-" + d.getFullYear()
      let sd = new Date(scheduleDate)
      this.setState({scheduleDate : scheduleDate}, () => this.getIPActivities())

      let activityRef = db.collection('schedules').doc(scheduleDate).collection('activities').where('instructor','==','TEST_INSTRUCTOR')
        var ipActivites = []
        activityRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                //console.log(doc.data().startTime)
                if(doc.data().student == "reesj4"){
                    let startTime = new Date(doc.data().startTime["seconds"] * 1000)
                    let endTime = new Date(doc.data().endTime["seconds"] * 1000)
                    let startResTime = new Date(doc.data().startTime["seconds"] * 1000)
                    let endResTime = new Date(doc.data().startTime["seconds"] * 1000)
                    console.log(startTime)
                    let activityPosX = (startTime.getHours()-5)*60 + startTime.getMinutes()
                    
                    let acPos = {x : activityPosX, y : 0}
                    //a.setState({startTimeHours : startTime.getHours()})
                    a.setState({activityComment : doc.data().comment, selectedUnit : doc.data().unit, activityPos : acPos, activityHidden : false, startTimeHours : startTime.getHours(), startTimeMins : startTime.getMinutes(), endTimeHours : endTime.getHours(), endTimeMins : endTime.getMinutes(), preBreif : doc.data().preBreif, acTime : doc.data().acTime, postBreif : doc.data().postBreif}, ()=> a.resetActivityPos())
                    if(doc.data().startTime["seconds"]<a.state.activityStart){
                        a.setState({activityStart : doc.data().startTime["seconds"]},()=>a.calculateDD())
                    }
                    if(doc.data().endTime["seconds"]>a.state.activityEnd){
                        a.setState({activityEnd : doc.data().endTime["seconds"]},()=>a.calculateDD())
                    }
                
                }
            })
        })

      /*
      var activityRef = db.collection('schedules').doc(scheduleDate).collection('activities').doc('reesj4TEST_INSTRUCTORFA-215-DEMOFLIGHT#4')
      activityRef.get().then(function(doc){
          if(doc.exists){
            let startTime = new Date(doc.data().startTime["seconds"] * 1000)
            let endTime = new Date(doc.data().endTime["seconds"] * 1000)
            let startResTime = new Date(doc.data().startTime["seconds"] * 1000)
            let endResTime = new Date(doc.data().startTime["seconds"] * 1000)
            console.log(startTime)
            let activityPosX = (startTime.getHours()-5)*60 + startTime.getMinutes()
            
            let acPos = {x : activityPosX, y : 0}
            //a.setState({startTimeHours : startTime.getHours()})
            a.setState({activityComment : doc.data().comment, selectedUnit : doc.data().unit, activityPos : acPos, activityHidden : false, startTimeHours : startTime.getHours(), startTimeMins : startTime.getMinutes(), endTimeHours : endTime.getHours(), endTimeMins : endTime.getMinutes(), preBreif : doc.data().preBreif, acTime : doc.data().acTime, postBreif : doc.data().postBreif}, ()=> a.resetActivityPos())
          }
      })

      var moreActivityRef = db.collection('schedules').doc(scheduleDate).collection('activities')
      moreActivityRef.where('instructor', "==", "reesj4")
      */



    }

    getActivityRes(){
        console.log('GETTING ACTIVITY RES ' + this.state.resourceType)
        let a = this
        let db = firebase.firestore()
        var tailNumber = ""
        var acType = this.state.resourceType.toString()
        var TAA = false
        var needToQuery = true
        if(acType == "C172-SPIN"){
            this.setState({resource : 'R-49'})
            needToQuery = false
        }
        if(needToQuery){
        let acRef = db.collection('aircraft').where('aircraftType', '==', acType)
        acRef.get().then(function(querySnapshot){
        querySnapshot.forEach(function(doc){
            console.log(doc.data().isDowned)
            if(doc.data().isDowned == false){
                console.log('ACTIVITY RES ' + doc.data())
                a.setState({resource : doc.id})
            }
        })
        })
        }

    }


    getIPActivities(){
        this.setState({ipActivites : []})
        let a = this
        let db = firebase.firestore()
        let docRef = db.collection('schedules').doc(this.state.scheduleDate).collection('activities').where('instructor','==','TEST_INSTRUCTOR')
        var ipActivites = []
        docRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                //console.log(doc.data().startTime)
                if(doc.data().student == ""){
                let startTime = new Date(doc.data().startTime["seconds"] * 1000)
                let endTime = new Date(doc.data().endTime["seconds"] * 1000)
                let activityTimeMins = (((doc.data().endTime["seconds"]-doc.data().startTime["seconds"])*1000)/60)/1000
                let activityTimePixles = activityTimeMins.toString() + 'px'
                //console.log(startTime)
                let minsFromMidnight = startTime.getHours()*60 + startTime.getMinutes()
                let minsFromFive = minsFromMidnight - 300
                let activityPosX = (startTime.getHours()-5)*60 + startTime.getMinutes()
                let margin = activityPosX.toString() + 'px'
                console.log(margin)
                let style = {marginLeft : margin, backgroundColor : 'grey', width : activityTimePixles, position : 'fixed'}
                ipActivites.push(<Tooltip style = {{fontWeight : 'bold'}} title = {'Resource : ' + doc.data().resource + ' Comment : ' + doc.data().comment + ' Start Time : ' + startTime.getHours() + ":" + startTime.getMinutes() + ' End Time : ' + endTime.getHours() + ":" + endTime.getMinutes()} arrow><div style = {style}><Typography style = {{color : 'white', alignItems:'center', fontWeight : 'bold'}}>{doc.data().unit}</Typography></div></Tooltip>)
                a.setState({ipActivites : ipActivites})
                if(doc.data().startTime["seconds"]<a.state.activityStart){
                    a.setState({activityStart : doc.data().startTime["seconds"]},()=>a.calculateDD())
                }
                if(doc.data().endTime["seconds"]>a.state.activityEnd){
                    a.setState({activityEnd : doc.data().endTime["seconds"]},()=>a.calculateDD())
                }
                }
            })
        })
    }
    calculateDD(){
        
        /*
        let dd = parseFloat(this.state.preBreif) + parseFloat(this.state.postBreif) + parseFloat(this.state.acTime)
        this.setState({dutyDay : dd})
        if(dd < 12){
            this.setState({ddColor : 'green'})
        }else{
            this.setState({ddColor : 'red'})
        }
        this.calculateResourceProb()
        */
       if(this.state.activityEnd != 0){
           let totalDaySeconds = this.state.activityEnd - this.state.activityStart
           let totalDayHours = totalDaySeconds/60/60
           console.log(totalDayHours)
           this.setState({dutyDay : totalDayHours})
           if(totalDayHours < 12){
            this.setState({ddColor : 'green'})
        }else{
            this.setState({ddColor : 'red'})
        }
        this.calculateResourceProb()
       }else{
           this.setState({dutyDay : 0})
       }
    }
    calculateResourceProb(){
        if(this.state.selectedUnit == ""){
            this.setState({resourceProb : 0})
        }else{
            this.setState({resourceProb : 100})
        }
    }
    resetActivityPos(){
        console.log('resetting activity position')
        this.setState({activityPos : null},()=>this.calculateDD())
    }
    handleStop(event){
        console.log(event.position)
        this.setState({posX : event.x})
        let startPixlesFromMargin = event.x-450
        let hoursFromMidnight = startPixlesFromMargin/60 + 5
        //console.log(hoursFromMidnight)
        let startHours = parseInt(hoursFromMidnight)
        console.log(hoursFromMidnight)
        console.log('unrounded number')
        console.log(hoursFromMidnight % 1 * 60)
        let startMins = Math.round((hoursFromMidnight % 1) * 60)
        if(startMins % 6 != 0){
            console.log(startMins % 6)
            startMins = startMins - startMins % 6
        }
        if(startMins == 60){
            startHours = startHours + 1 
            startMins = "00"
        }
        if(startMins < 10){
            startMins = '0' + startMins
        }
        let minuitesFromMidnight = this.state.startTimeHours*60 + parseInt(this.state.startTimeMins)+this.state.preBreif*60 + this.state.acTime*60 + this.state.postBreif*60
        let endHours = Math.trunc(minuitesFromMidnight/60)
        var endMins = Math.round((minuitesFromMidnight/60%1)*60)
        if(endMins < 10){
            endMins = '0' + endMins
        }


        this.setState({startTimeHours : startHours, startTimeMins : startMins, endTimeHours : endHours, endTimeMins : endMins}, ()=>this.updateActivityTiming())
    }
    
    handleDrag(event){
        console.log('ACTIVITY POS')
        console.log(event)
        this.setState({posX : event.x})
        let startPixlesFromMargin = event.x-450
        let hoursFromMidnight = startPixlesFromMargin/60 + 5
        //console.log(hoursFromMidnight)
        let startHours = parseInt(hoursFromMidnight)
        let startMins = Math.round((hoursFromMidnight % 1) * 60)
        if(startMins % 6 != 0){
            console.log(startMins % 6)
            startMins = startMins - startMins % 6
        }
        if(startMins == 60){
            startHours = startHours + 1 
            startMins = "00"
        }
        if(startMins < 10){
            startMins = '0' + startMins
        }

        this.setState({startTimeHours : startHours, startTimeMins : startMins})
    }
    hideActivityOne(){
        if(this.state.activityHidden == false){
            
            let scheduleDate = new Date(this.state.scheduleDate)
    let db = firebase.firestore();
    this.setState({dutyDay : 0})
    db.collection("schedules")
      .doc(scheduleDate.getMonth() + 1 + "-" + (scheduleDate.getDate()) + "-" + scheduleDate.getFullYear())
      .collection("activities")
      .doc('reesj4TEST_INSTRUCTORFA-215-DEMOFLIGHT#4').delete()
        }
        let flip = !this.state.activityHidden
        this.setState({activityHidden : flip})
       
    }
    showActivityOne(){
        this.setState({activityHidden : false})
        let dd = this.state.preBreif + this.state.postBreif + this.state.acTime
        this.setState({dutyDay : dd})
    }
    toggleEditBackdrop(){
        this.setState({editActivityBackdropOpen : !this.state.editActivityBackdropOpen})
    }
    toggleRequestBackdrop(){
        this.setState({requestBackdropOpen : !this.state.requestBackdropOpen})
    }
    activityTimingChanged(event){
        console.log(event.target.id)
        console.log(event.target.value)
        console.log(this.state.startTimeHours*60 + parseInt(this.state.startTimeMins)+parseFloat(event.target.value)*60 + this.state.acTime*60 + this.state.postBreif*60)
        this.setState({[event.target.id] : event.target.value}, ()=>this.updateActivityTiming())
        this.calculateDD()
    }
    adminTimingChanged(event){
        this.setState({[event.target.id] : event.target.value},()=>this.calculateAdminEndTime())
    }
    updateActivityTiming(){
        console.log('updating activity timing')
        let minuitesFromMidnight = parseInt(this.state.startTimeHours)*60 + parseInt(this.state.startTimeMins)+parseFloat(this.state.preBreif)*60 + parseFloat(this.state.acTime)*60 + parseFloat(this.state.postBreif)*60
        let endHours = Math.trunc(minuitesFromMidnight/60)
       
        var endMins = Math.round((minuitesFromMidnight/60%1)*60)
        if(endMins < 10){
            endMins = '0' + endMins
        }
        console.log((endHours*60*60 + parseInt(endMins)*60))
        let d = new Date(this.state.scheduleDate)
        d.setHours(endHours)
        d.getMinutes(endMins)
        console.log(d.getTime())
        if(d.getTime()>this.state.activityEnd){
            this.setState({activityEnd : d.getTime()/1000}, ()=>this.calculateDD())
        }

        let s = new Date(this.state.scheduleDate)
        s.setHours(parseInt(this.state.startTimeHours))
        s.setMinutes(parseInt(this.state.startTimeMins))
        if(s.getTime()>this.state.activityEnd){
            this.setState({activityStart : s.getTime()/1000}, ()=>this.calculateDD())
        }
        this.setState({endTimeHours : endHours, endTimeMins : endMins}, ()=>this.calculateDD())
    }
    saveButtonPressed(){
    console.log("Save Button PRessed!")
    let d = new Date();
    let s = new Date(this.state.scheduleDate);
    let e = new Date(this.state.scheduleDate);
    s.setHours(this.state.startTimeHours);
    s.setMinutes(this.state.startTimeMins);
    let rs = new Date(s.getFullYear(), s.getMonth(), s.getDate(), s.getHours(), s.getMinutes() + (parseFloat(this.state.preBreif) * 60))
    console.log(s)
    console.log(rs)
    e.setHours(this.state.endTimeHours);
    e.setMinutes(this.state.startTimeMins);
    let re =  new Date(e.getFullYear(), e.getMonth(), e.getDate(), e.getHours(), e.getMinutes() - (parseFloat(this.state.postBreif) * 60))
    let scheduleDate = new Date(this.state.scheduleDate)
    let db = firebase.firestore();
    console.log(d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear())
    var status = "Pre-Post"
    if(d.getDate() == s.getDate()){
        status = "Ops-Request"
    }
    var tailNumber = ""
    var acType = this.state.resourceType.toString()
    let acRef = db.collection('aircraft').where('aircraftType', '==', acType)
    acRef.get().then(function(querySnapshot){
        querySnapshot.forEach(function(doc){
            if(doc.data().isDowned != false){
                tailNumber = doc.id
            }
        })
    })

    db.collection("schedules")
      .doc(scheduleDate.getMonth() + 1 + "-" + (scheduleDate.getDate()) + "-" + scheduleDate.getFullYear())
      .collection("activities")
      .doc('reesj4TEST_INSTRUCTORFA-215-DEMOFLIGHT#4'+scheduleDate.getMonth() + 1 + "-" + (scheduleDate.getDate()) + "-" + scheduleDate.getFullYear())
      .set({
        course: 'FA-215-DEMO',
        unit: this.state.selectedUnit,
        instructor: "TEST_INSTRUCTOR",
        student: "reesj4",
        status: status,
        startTime: s,
        endTime: e,
        startResTime: rs,
        endResTime: re,
        resourceType : '8KCAB',
        preBreif : this.state.preBreif,
        postBreif : this.state.postBreif,
        acTime : this.state.acTime,
        activityPos : this.state.activityPos,
        comment : this.state.activityComment,
        resource : this.state.resource
      });
      this.setState({editActivityBackdropOpen : false}, ()=>this.updateActivityTiming)
    }
    
    incrementDate(){

        let d = new Date(this.state.scheduleDate)
        console.log(d)
        var sd = new Date()
        sd.setDate(d.getDate() + 1)
        let scheduleDate = sd.getMonth() + 1 + "-" + (sd.getDate()) + "-" + sd.getFullYear()
        console.log(scheduleDate)
        this.setState({scheduleDate : scheduleDate, activityEnd : 0, activityStart :99999999999999999, dutyDay : 0 }, () => this.refetchData())
    }
    decrementDate(){
        let d = new Date(this.state.scheduleDate)
        console.log(d)
        var sd = new Date()
        sd.setDate(d.getDate() - 1)
        let scheduleDate = sd.getMonth() + 1 + "-" + (sd.getDate()) + "-" + sd.getFullYear()
        console.log(scheduleDate)
        this.setState({scheduleDate : scheduleDate, activityEnd : 0, activityStart :99999999999999999, dutyDay : 0 }, () => this.refetchData())
    }
    refetchData(){
        this.setState({activityHidden : true})
        let a = this
        var db = firebase.firestore();

        let d = new Date(this.state.scheduleDate)
        let scheduleDate = d.getMonth() + 1 + "-" + (d.getDate()) + "-" + d.getFullYear()
        let sd = new Date(scheduleDate)
        this.setState({scheduleDate : scheduleDate},()=>this.getIPActivities())
        //var activityRef = db.collection('schedules').doc(scheduleDate).collection('activities').doc('reesj4TEST_INSTRUCTORFA-215-DEMOFLIGHT#4')
        var activityRef = db.collection('schedules').doc(scheduleDate).collection('activities').where('instructor','==',this.state.instructor)

        activityRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                if(doc.data().student != ''){
                    let startTime = new Date(doc.data().startTime["seconds"] * 1000)
                    let endTime = new Date(doc.data().endTime["seconds"] * 1000)
                    let startResTime = new Date(doc.data().startTime["seconds"] * 1000)
                    let endResTime = new Date(doc.data().startTime["seconds"] * 1000)
                    console.log(startTime)
                    let activityPosX = (startTime.getHours()-5)*60 + startTime.getMinutes()
                    
                    let acPos = {x : activityPosX, y : 0}
                    //a.setState({startTimeHours : startTime.getHours()})
                    a.setState({ activityComment : doc.data().comment, activityPos : acPos, activityHidden : false, startTimeHours : startTime.getHours(), startTimeMins : startTime.getMinutes(), endTimeHours : endTime.getHours(), endTimeMins : endTime.getMinutes(), preBreif : doc.data().preBreif, acTime : doc.data().acTime, postBreif : doc.data().postBreif}, ()=> a.resetActivityPos())
                  }else{
                      a.setState({activityHidden : true},()=>a.calculateDD())
                  }
            })
        })


        /*
        activityRef.get().then(function(doc){
            if(doc.exists){
              let startTime = new Date(doc.data().startTime["seconds"] * 1000)
              let endTime = new Date(doc.data().endTime["seconds"] * 1000)
              let startResTime = new Date(doc.data().startTime["seconds"] * 1000)
              let endResTime = new Date(doc.data().startTime["seconds"] * 1000)
              console.log(startTime)
              let activityPosX = (startTime.getHours()-5)*60 + startTime.getMinutes()
              
              let acPos = {x : activityPosX, y : 0}
              //a.setState({startTimeHours : startTime.getHours()})
              a.setState({ activityComment : doc.data().comment, activityPos : acPos, activityHidden : false, startTimeHours : startTime.getHours(), startTimeMins : startTime.getMinutes(), endTimeHours : endTime.getHours(), endTimeMins : endTime.getMinutes(), preBreif : doc.data().preBreif, acTime : doc.data().acTime, postBreif : doc.data().postBreif}, ()=> a.resetActivityPos())
            }else{
                a.setState({activityHidden : true})
            }
        })
        */
    }
    changeUnit(event){
        console.log(event.target.value)
        this.setState({selectedUnit : event.target.value}, ()=>this.calculateResourceProb())
        if(event.target.value == "GROUND BRIEFING #1"){
            this.setState({resourceType : 'ORAL-ROOM'})
        }else{
            this.setState({resourceType : '8KCAB'})
        }
    }
    commentAdded(event){
        this.setState({activityComment : event.target.value})
    }
    selectTab(event){
        this.setState({requestTabSelected : parseInt(event.target.id)})
    }
    changeAdminRequestDate(event){
        console.log(event.target.value)
        //console.log(this.state.scheduleDate)
        this.setState({adminDate : event.target.value})
    }
    changeAdminStartTime(event){
        console.log('admin start time changed!')
        console.log(event.target.value)
        this.setState({adminStartTime : event.target.value},()=>this.calculateAdminEndTime())
    }
    calculateAdminEndTime(){
        var startTime = this.state.adminStartTime
        var startTimeHours = parseInt(startTime.substring(0,2))
        var startTimeMins = parseInt(startTime.substring(3,5))
        
        let minuitesFromMidnight = startTimeHours*60 + startTimeMins+parseFloat(this.state.adminPreBreif)*60 + parseFloat(this.state.adminACTime)*60 + parseFloat(this.state.adminPostBreif)*60
        let endHours = Math.trunc(minuitesFromMidnight/60)
       
        var endMins = Math.round((minuitesFromMidnight/60%1)*60)
        if(endMins < 10){
            endMins = '0' + endMins
        }
        if(endHours < 10){
            endHours = '0' + endHours
        }
        console.log(endHours + ':' + endMins)
        let adminEndTime = endHours + ':' + endMins
        this.setState({adminEndTime : adminEndTime})
        
    }
    submitAdminActivity(){
        //2021-12-11
        //01234
        //08:30
        //Start Time Calculation
        let scheduleRef = this.state.adminDate
        let startRef = this.state.adminStartTime
        let year = scheduleRef.substring(0,4)
        let month = scheduleRef.substring(5,7)
        let date = scheduleRef.substring(8,10)
        let hours = startRef.substring(0,2)
        let mins = startRef.substring(3,5)
        let startTime = new Date()
        startTime.setFullYear(year)
        startTime.setMonth(month-1)
        startTime.setDate(date)
        startTime.setHours(hours)
        startTime.setMinutes(mins)
        startTime.setSeconds(0)
        //End Time Calculation
        let endRef = this.state.adminEndTime
        let endHours = endRef.substring(0,2)
        let endHour = endRef.substring(3,5)
        let endTime = new Date()
        endTime.setFullYear(year)
        endTime.setMonth(month-1)
        endTime.setDate(date)
        endTime.setHours(endHours)
        endTime.setMinutes(endHour)
        endTime.setSeconds(0)
        console.log(endTime)
        //Res. Time Calculation
        let resStartTimeRef = this.state.adminPreBreif
        let resStartTime = new Date(startTime)
        let resMinsFromMidnight = startTime.getHours()*60 + startTime.getMinutes() + resStartTimeRef*60
        let resStartHours = Math.trunc(resMinsFromMidnight/60)
       
        var resStartMins = Math.round((resMinsFromMidnight/60%1)*60)
        if(resStartMins < 10){
            resStartMins = '0' + resStartMins
        }
        if(resStartMins < 10){
            resStartMins = '0' + resStartMins
        }
        resStartTime.setHours(resStartHours)
        resStartTime.setMinutes(resStartMins)
        resStartTime.setSeconds(0)
        //Res. End Time Calculation
        let resEndTime = new Date(resStartTime)
        let resEndMinsFromMidnight = resStartTime.getHours()*60 + resStartTime.getMinutes() + resStartTimeRef*60 + this.state.adminACTime*60
        let resEndHours = Math.trunc(resEndMinsFromMidnight/60)
       
        var resEndMins = Math.round((resEndMinsFromMidnight/60%1)*60)
        if(resEndMins < 10){
            resEndMins = '0' + resEndMins
        }
        if(resEndMins < 10){
            resEndMins = '0' + resEndMins
        }
        resEndTime.setHours(resEndHours)
        resEndTime.setMinutes(resEndMins)
        resEndTime.setSeconds(0)

        let db = firebase.firestore()
        let dbDateRefString = (startTime.getMonth()+1)+'-'+startTime.getDate()+'-'+startTime.getFullYear()
        let docNameString = this.state.instructor + "ADMIN" + dbDateRefString + resStartHours + resStartMins
        let docRef = db.collection('schedules').doc(dbDateRefString).collection('activities').doc(docNameString)
        console.log(this.state.adminRequestType)
        var status = "Pre-Post"
        let today = new Date()
        if(today.getDate() == startTime.getDate()){
            status = "Ops-Request"
        }


        docRef.set({
            acTime : this.state.adminACTime,
            comment : this.state.adminComment,
            course : "ADMIN",
            endResTime : resEndTime,
            endTime : endTime,
            instructor : 'TEST_INSTRUCTOR',
            postBreif : this.state.adminPostBreif,
            preBreif : this.state.adminPreBreif,
            resource : this.state.adminResource,
            startResTime : resStartTime,
            startTime : startTime,
            status : status,
            student : "",
            unit : "ADMIN",
            resourceType : this.state.adminRequestType,
            resource : this.state.adminResource
        })
        this.setState({requestBackdropOpen : false})
    }
    changeAdminParams(event){
        console.log(event.target)
        console.log(event.target.name)
        console.log(event.target.value)
        if(event.target.name!="adminRequestType"){
            this.setState({[event.target.name] : event.target.value})
        }else{
            this.setState({[event.target.name] : event.target.value},()=>this.getAdminResource(event.target.value))
        }
    }   
    changeRequestedRes(event){
        this.setState({resourceType : event.target.value},()=>this.getActivityRes())
    }
    getAdminResource(res){
        let a = this
        let db = firebase.firestore()
        let acRef = db.collection('aircraft').where('aircraftType','==',this.state.adminRequestType)

        if(res == "ORAL-ROOM" || res == "CESSNA-CPT" || res == "CLASSROOM" || res == "CONFERENCE-ROOM" || res == "FTD-BAYS"){
            a.setState({adminResource : "ground"})
        }else{
            acRef.get().then(function(querySnapshot){
                querySnapshot.forEach(function(doc){
                    if(doc.data().isDowned == false){
                        a.setState({adminResource : doc.id})
                    }
                })
            })
        }

        
    }
    render(){
        return(
        <div>
        <Card>
        <Grid container>
            <Grid item xs = {1}><h3>View Date:</h3></Grid>
            <Grid item xs = {1}><h3>{this.state.scheduleDate}</h3></Grid>
            <Grid item xs = {1}><IconButton style = {{marginTop : "5%"}} onClick = {this.decrementDate}><ArrowBackIosIcon></ArrowBackIosIcon></IconButton></Grid>
            <Grid item xs = {1}><IconButton style = {{marginTop : "5%"}} onClick = {this.incrementDate}><ArrowForwardIosIcon></ArrowForwardIosIcon></IconButton></Grid>
            <Grid item xs = {4} style = {{alignItems : 'center'}}><h3 style = {{alignSelf : 'center', color : this.state.ddColor}}>Duty Day : {this.state.dutyDay} Hours</h3></Grid>
            <Grid item xs = {1}><Button variant="contained" style = {{backgroundColor : '#5cb85c'}}>Save Activities</Button></Grid>
            <Grid item xs = {1}><Button variant="contained" style = {{backgroundColor : '#337ab7'}}>Add Student</Button></Grid>
            <Grid item xs = {1}><Button variant="contained" style = {{backgroundColor : '#d9534f'}}>Drop Students</Button></Grid>
            <Grid item xs = {1}><Button variant="contained" style = {{backgroundColor : '#d2d94f'}} onClick = {this.toggleRequestBackdrop}>New Request</Button></Grid>
        </Grid>
        
        <div style = {{minWidth : '1243.63636364px', maxWidth : '1243.63636364px'}}>
        <Grid container spacing={0}>
        <Grid item xs={1}>
          <h3>{this.state.scheduleDate}</h3>
          
        </Grid>
        <Grid item xs={11}>
          <Grid container>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>0500</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>0600</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>0700</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>0800</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>0900</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}} ><h3>1000</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1100</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1200</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1300</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1400</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1500</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1600</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1700</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1800</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>1900</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>2000</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>2100</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>2200</h3></Grid>
          <Grid item xs = {0.63157894736} style = {{backgroundColor : 'rgba(128, 220, 128, .8)'}}><h3>2300</h3></Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
        <Grid container>
          <Grid item xs = {6}><h3>Instructor</h3></Grid>
          <Grid item xs = {6}><Button onClick={this.showActivityOne} style = {{height : '100%'}}>+</Button></Grid>
          </Grid>
        </Grid>
        <Grid item xs={11}>
            {this.state.ipActivites}
        </Grid>
        <Grid item xs={1}>
          <Grid container>
          <Grid item xs = {6}><h3>Reesj4</h3></Grid>
          <Grid item xs = {6}><Button onClick={this.showActivityOne} style = {{height : '100%'}}>+</Button></Grid>
          </Grid>
        </Grid>
        <Grid item xs={11}>
        <div style = {{position : "absolute", backgroundColor : "rgba(144, 238, 144, .5)", height : 40, width : 360, marginLeft : 30}}>
            <Grid container>
                <Grid item xs = {6}>
                <h3 style = {{marginRight : '90%'}}>0530</h3>
                </Grid>
                <Grid item xs = {6}>
                <h3 style = {{marginLeft : '85%'}}>1130</h3>
                </Grid>
            </Grid>
        </div>
        <div style = {{position : "absolute", backgroundColor : "rgba(144, 238, 144, .5)", height : 40, width : 420, marginLeft : 670}}>
            <Grid container>
                <Grid item xs = {6}>
                <h3 style = {{marginRight : '90%'}}>1600</h3>
                </Grid>
                <Grid item xs = {6}>
                <h3 style = {{marginLeft : '85%'}}>2300</h3>
                </Grid>
            </Grid>
        </div>
        <div style = {{position : "absolute", backgroundColor : "#a9d6a9", height : 40, width : 182.5, marginLeft : 210}}>
            <Grid container>
                <Grid item xs = {4}>
                <h3 style = {{marginRight : '90%'}}>830</h3>
                </Grid>
                <Grid item xs = {4}>
                <h3>FB (72)</h3>
                </Grid>
                <Grid item xs = {4}>
                <h3 style = {{marginLeft : '85%'}}>1135</h3>
                </Grid>
            </Grid>
        </div>
        
        <Draggable axis="x" handle=".handle" defaultPosition={{x: 0, y: 0}} position={this.state.activityPos} grid={[25, 25]} scale={1} onStart={this.handleStart} onDrag={this.handleDrag} onStop={this.handleStop}>
        <div style = {{backgroundColor:'rgba(120, 120, 120, 0.9)', width: (parseFloat(this.state.preBreif)+parseFloat(this.state.acTime)+parseFloat(this.state.postBreif))*60}} hidden={this.state.activityHidden}>
              <Grid container>
                <Grid item xs = {1} style = {{}}>
                <Button style ={{maxWidth : '10px', minWidth : '10px', backgroundColor : 'rgba(51, 121, 183, 0.5)', color : 'white', width : '50px'}} onClick={this.toggleEditBackdrop}>E</Button>
                </Grid>
                <Grid item xs = {10}>
                <div className="handle">
                    <Typography style = {{color : 'white'}}>{this.state.startTimeHours}:{String(this.state.startTimeMins).padStart(2, "0")}-{this.state.endTimeHours}:{String(this.state.endTimeMins).padStart(2, "0")}</Typography>
                </div>
                
                </Grid>
                <Grid item xs = {1}>
                <Button style = {{maxWidth : '10px', minWidth : '10px', backgroundColor : 'rgba(217, 83, 79, 0.5)', color : 'white',}} onClick = {this.hideActivityOne}>-</Button>
                </Grid>
              </Grid>
              </div>
      </Draggable>
     
            
        </Grid>

        <Grid item xs={1}>
          <Grid container>
          <Grid item xs = {6}><h3 style = {{marginRight : '50%'}}>TEST_STUDENT(0)</h3></Grid>
          <Grid item xs = {6}><Button onClick={this.showActivityOne} style = {{height : '100%'}} disabled>+</Button></Grid>
          </Grid>
        </Grid>
        <Grid item xs={11}>
        <div style = {{position : "absolute", backgroundColor : "rgba(255, 202, 205, 0.85)", height : 40, width : 1080, marginLeft : 30}}>
            <Grid container>
                <Grid item xs = {6}>
                <h3>Unpaid Flight</h3>
                </Grid>
                <Grid item xs = {6}>
                <h3 style = {{marginLeft : '85%'}}>Indefinite</h3>
                </Grid>
            </Grid>
        </div>
        </Grid>
      </Grid>
        </div>
        
       
        </Card>
            <div>
            <Backdrop open={this.state.editActivityBackdropOpen} onClose = {this.toggleEditBackdrop}>
                <Card style={{width: "75%",align: "center",marginLeft: "15%"}}>
                    <Button style = {{marginLeft : '95%'}} onClick = {this.toggleEditBackdrop}>x</Button>
                    <h1 style = {{alignSelf : 'center'}}>Edit Activity</h1>
                    <Card sx={{ boxShadow: 3 }}>
                        <Grid container>
                            <Grid item xs = {3}>
                                <h4>Student 1</h4>
                                <Select labelId="student 1" id="studentone" label="Student 1" value = {'reesj4'}>
                                <MenuItem value={'reesj4'}>reesj4</MenuItem>
                                <MenuItem value={'test_student'}>TEST_STUDENT</MenuItem>
                                </Select></Grid>
                            
                            <Grid item xs = {3}><h4>Unit 1</h4><Select onChange = {this.changeUnit} labelId="unit 1" id="unitone" label="Unit 1" value = {this.state.selectedUnit}>
                                {this.state.courseUnits}
                                </Select></Grid>
                            <Grid item xs = {3}><h4>Student 2</h4><Select labelId="student 2" id="studentone" label="Student 2" value = {''}>
                                <MenuItem value={'reesj4'}>reesj4</MenuItem>
                                <MenuItem value={'test_student'}>TEST_STUDENT</MenuItem>
                                </Select></Grid>
                            <Grid item xs = {3}><h4>Unit 2</h4></Grid>
                        </Grid>

                        <Divider></Divider>
                        <Grid container style = {{marginBotton : 15}}>
                            <Grid item xs = {2.4}><h4>Check In</h4><TextField value={this.state.startTimeHours+":"+String(this.state.startTimeMins).padStart(2, "0")}></TextField></Grid>
                            <Grid item xs = {2.4}><h4>Pre-Breif</h4><TextField id = "preBreif" value={this.state.preBreif} onChange = {this.activityTimingChanged} type="number" inputProps = {{step : .1}}></TextField></Grid>
                            <Grid item xs = {2.4}><h4>A/C Time</h4><TextField value={this.state.acTime} id = "acTime" onChange = {this.activityTimingChanged} type="number" inputProps = {{step : .1}}></TextField></Grid>
                            <Grid item xs = {2.4}><h4>Post-Brief</h4><TextField value={this.state.postBreif} id = "postBreif" onChange = {this.activityTimingChanged} type="number" inputProps = {{step : .1}}></TextField></Grid>
                            <Grid item xs = {2.4}><h4>Act End</h4><TextField value={this.state.endTimeHours+":"+String(this.state.endTimeMins).padStart(2, "0")}></TextField></Grid>
                        </Grid>
                        <Divider></Divider>
                    <h4>Comments</h4>
                    <TextField style = {{width : '100%'}} onChange = {this.commentAdded} value = {this.state.activityComment}></TextField>
                    </Card>
                    <Grid container>
                        <Grid item xs = {3}>
                        <h4>Resource Probability : {this.state.resourceProb}%</h4>
                        </Grid>
                        <Grid item xs = {3}>
                            <h3>Resource Type : </h3>
                        </Grid>
                        <Grid item xs = {3}>
                            <Select label = "Request Type" onChange = {this.changeRequestedRes} name = {"activityRequestType"} value = {this.state.resourceType}>
                                <MenuItem value={'c172'} name = {"adminRequestType"}>C172(ANY)</MenuItem>
                                <MenuItem value={'C172-SPIN'} name = {"adminRequestType"}>C172-SPIN</MenuItem>
                                <MenuItem value={'C172-TAA'} name = {"adminRequestType"}>C172-TAA</MenuItem>
                                <MenuItem value={'C172-NAVIII'} name = {"adminRequestType"}>C172-NAVIII</MenuItem>
                                <MenuItem value={'C172-WESTRAMP'} name = {"adminRequestType"}>C172-WESTRAMP</MenuItem>
                                <MenuItem value={'C182RG'} name = {"adminRequestType"}>C182RG</MenuItem>
                                <MenuItem value={'C150'} name = {"adminRequestType"}>C150</MenuItem>
                                <MenuItem value={'da42'} name = {"adminRequestType"}>DA42NG</MenuItem>
                                <MenuItem value={'8KCAB'} name = {"adminRequestType"}>8KCAB</MenuItem>
                                <MenuItem value={'CESSNA-CPT'} name = {"adminRequestType"}>CESSNA-CPT</MenuItem>
                                <MenuItem value={'CLASSROOM'} name = {"adminRequestType"}>CLASSROOM</MenuItem>
                                <MenuItem value={'CONFERENCE-ROOM'} name = {"adminRequestType"}>CONFERENCE-ROOM</MenuItem>
                                <MenuItem value={'FTD-BAYS'} name = {"adminRequestType"}>FTD-BAYS</MenuItem>
                                <MenuItem value={'ORAL-ROOM'} name = {"adminRequestType"}>ORAL-ROOM</MenuItem>
                                <MenuItem value={'C172-ULTRA-AATD'} name = {"adminRequestType"}>C172-ULTRA-AATD</MenuItem>
                                
                            </Select>
                        </Grid>
                        <Grid item xs = {3}>
                            <h3>Expected Resource : {this.state.resource}</h3>
                        </Grid> 
                    </Grid>
                    
                    <Card >
                        <Button variant = "contained" style = {{marginTop : 15}} onClick = {this.saveButtonPressed}>Save</Button>
                        
                    </Card>
                </Card>
            </Backdrop>
            </div>







            <div>
                <Backdrop open = {this.state.requestBackdropOpen} onClose = {this.toggleRequestBackdrop}>
                    <Card style={{width: "75%",align: "center",marginLeft: "15%"}}>
                        <Grid container>
                        <Grid item xs = {11}>
                        <h1>New Request</h1>
                        </Grid>
                        <Grid item xs = {1}>
                            <IconButton style = {{marginLeft : '50%'}} onClick = {this.toggleRequestBackdrop}><CloseIcon/></IconButton>
                        </Grid>
                        </Grid>
                       
                        <Tabs value = {this.state.requestTabSelected} onChange={this.selectTab}>
                            <Tab label = "Course" value = {0} id = "0"></Tab>
                            <Tab label = "Admin" value = {1} id = "1"></Tab>
                            <Tab label = "Rental" value = {2} id = "2"></Tab>
                            <Tab label = "Refresher" value = {3} id = "3"></Tab>
                        </Tabs>

                        <div hidden = {!(this.state.requestTabSelected == 0)}>
                        <Card sx={{ boxShadow: 3 }}>
                            <Grid container>
                                <Grid item xs = {3}>
                                <h4>Student 1</h4>
                                <Select labelId="student 1" id="studentone" label="Student 1" value = {'reesj4'}>
                                <MenuItem value={'reesj4'}>reesj4</MenuItem>
                                <MenuItem value={'test_student'}>TEST_STUDENT</MenuItem>
                                </Select></Grid>
                            
                            <Grid item xs = {3}><h4>Unit 1</h4><Select onChange = {this.changeUnit} labelId="unit 1" id="unitone" label="Unit 1" value = {this.state.selectedUnit}>
                                {this.state.courseUnits}
                                </Select></Grid>
                            <Grid item xs = {3}><h4>Student 2</h4><Select labelId="student 2" id="studentone" label="Student 2" value = {''}>
                                <MenuItem value={'reesj4'}>reesj4</MenuItem>
                                <MenuItem value={'test_student'}>TEST_STUDENT</MenuItem>
                                </Select></Grid>
                            <Grid item xs = {3}><h4>Unit 2</h4></Grid>
                        </Grid>

                        <Divider></Divider>
                        <Grid container style = {{marginBotton : 15}}>
                            <Grid item xs = {2.4}><h4>Check In</h4><TextField value={this.state.startTimeHours+":"+this.state.startTimeMins}></TextField></Grid>
                            <Grid item xs = {2.4}><h4>Pre-Breif</h4><TextField id = "preBreif" value={this.state.preBreif} onChange = {this.activityTimingChanged} type="number" inputProps = {{step : .1}}></TextField></Grid>
                            <Grid item xs = {2.4}><h4>A/C Time</h4><TextField value={this.state.acTime} id = "acTime" onChange = {this.activityTimingChanged} type="number" inputProps = {{step : .1}}></TextField></Grid>
                            <Grid item xs = {2.4}><h4>Post-Brief</h4><TextField value={this.state.postBreif} id = "postBreif" onChange = {this.activityTimingChanged} type="number" inputProps = {{step : .1}}></TextField></Grid>
                            <Grid item xs = {2.4}><h4>Act End</h4><TextField value={this.state.endTimeHours+":"+this.state.endTimeMins}></TextField></Grid>
                        </Grid>
                        <Divider></Divider>
                    <h4>Comments</h4>
                    <TextField style = {{width : '100%'}} onChange = {this.commentAdded} value = {this.state.activityComment}></TextField>
                    </Card>
                    <h4>Resource Probability : {this.state.resourceProb}%</h4>
                    <Card >
                        <Button  style = {{marginTop : 15}} onClick = {this.saveButtonPressed}>Save</Button>
                        
                    </Card>
                    </div>

                    <div hidden = {this.state.requestTabSelected != 1}>
                        <Card sx={{ boxShadow: 3 }}>
                            <div style = {{marginTop : '1%'}}>
                                <Grid container>
                                <Grid item xs = {6}><TextField type = "date" label = "Request Date" defaultValue = {'2021-12-18'} onChange = {this.changeAdminRequestDate} style = {{marginBotton : '5px'}}></TextField></Grid>
                                <Grid item xs = {4}><Select label = "Request Type" onChange = {this.changeAdminParams} name = {"adminRequestType"} value = {this.adminRequestType}>
                                        <MenuItem value={'c172'} name = {"adminRequestType"}>C172(ANY)</MenuItem>
                                        <MenuItem value={'C172-SPIN'} name = {"adminRequestType"}>C172-SPIN</MenuItem>
                                        <MenuItem value={'C172-TAA'} name = {"adminRequestType"}>C172-TAA</MenuItem>
                                        <MenuItem value={'C172-NAVIII'} name = {"adminRequestType"}>C172-NAVIII</MenuItem>
                                        <MenuItem value={'C172-WESTRAMP'} name = {"adminRequestType"}>C172-WESTRAMP</MenuItem>
                                        <MenuItem value={'C182RG'} name = {"adminRequestType"}>C182RG</MenuItem>
                                        <MenuItem value={'C150'} name = {"adminRequestType"}>C150</MenuItem>
                                        <MenuItem value={'da42'} name = {"adminRequestType"}>DA42NG</MenuItem>
                                        <MenuItem value={'8KCAB'} name = {"adminRequestType"}>8KCAB</MenuItem>
                                        <MenuItem value={'CESSNA-CPT'} name = {"adminRequestType"}>CESSNA-CPT</MenuItem>
                                        <MenuItem value={'CLASSROOM'} name = {"adminRequestType"}>CLASSROOM</MenuItem>
                                        <MenuItem value={'CONFERENCE-ROOM'} name = {"adminRequestType"}>CONFERENCE-ROOM</MenuItem>
                                        <MenuItem value={'FTD-BAYS'} name = {"adminRequestType"}>FTD-BAYS</MenuItem>
                                        <MenuItem value={'ORAL-ROOM'} name = {"adminRequestType"}>ORAL-ROOM</MenuItem>
                                        <MenuItem value={'C172-ULTRA-AATD'} name = {"adminRequestType"}>C172-ULTRA-AATD</MenuItem>
                                    </Select></Grid>
                                    <Grid item xs = {2}>
                                        <h4>Expected Resource : {this.state.adminResource}</h4>
                                    </Grid>
                                <h4> </h4>
                                <Grid itexm xs = {4}><TextField label = "PIC/Inst" disabled defaultValue = {this.state.instructor}></TextField></Grid>
                                <Grid itexm xs = {4}><TextField label = "Pilot 2/ Observer 1"></TextField></Grid>
                                <Grid itexm xs = {4}><TextField label = "Observer 2"></TextField></Grid>
                                <Grid item xs = {2.4}><h4>Start</h4><TextField type = "time" onChange = {this.changeAdminStartTime} defaultValue = {this.state.adminStartTime}></TextField></Grid>
                                <Grid item xs = {2.4}><h4>Pre-Breif</h4><TextField id = "adminPreBreif" value={this.state.adminPreBreif} onChange = {this.adminTimingChanged} type="number" inputProps = {{step : .1}}></TextField></Grid>
                                <Grid item xs = {2.4}><h4>A/C Time</h4><TextField value={this.state.adminACTime} id = "adminACTime" onChange = {this.adminTimingChanged} type="number" inputProps = {{step : .1}}></TextField></Grid>
                                <Grid item xs = {2.4}><h4>Post-Brief</h4><TextField value={this.state.adminPostBreif} id = "adminPostBreif" onChange = {this.adminTimingChanged} type="number" inputProps = {{step : .1}}></TextField></Grid>
                                <Grid item xs = {2.4}><h4>End</h4><TextField type = "time" value = {this.state.adminEndTime}></TextField></Grid>
                                <h4>Comments</h4>
                                <TextField style = {{width : '100%'}} onChange = {this.changeAdminParams} value = {this.state.adminComment} name = "adminComment"></TextField>
                                </Grid>
                                <Grid container>
                                <Grid item xs = {2.4}>
                                    <h4>Request Type</h4>
                                    <Select label = "Request Type">
                                        <MenuItem value={'141 Prof. Check'}>141 Prof. Check</MenuItem>
                                        <MenuItem value={'ADMIN DNP'}>ADMIN DNP</MenuItem>
                                        <MenuItem value={'ADMIN Pd w/o OR'}>ADMIN Pd w/o OR</MenuItem>
                                        <MenuItem value={'ADMIN Pd with OR'}>ADMIN Pd with OR</MenuItem>
                                        <MenuItem value={'ARC'}>ARC</MenuItem>
                                        <MenuItem value={'Discovery Flight'}>Discovery Flight</MenuItem>
                                        <MenuItem value={'XART Currency'}>XART Currency</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs = {2.4}>
                                <h4>Charge Code</h4>
                                    <Select label = "Charge Code">
                                        <MenuItem value={'PRC Default'}>PRC Default</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs = {2.4}>
                                <h4>Admin Reason</h4>
                                    <Select label = "Charge Code" value = {this.state.adminChargeCode} name = {"adminChargeCode"} onChange = {this.changeAdminParams}>
                                        <MenuItem value={'Currency (DNP)'} name = {"adminChargeCode"}>Currency (DNP)</MenuItem>
                                        <MenuItem value={'FAA Check (DNP)'} name = {"adminChargeCode"}>FAA Check (DNP)</MenuItem>
                                        <MenuItem value={'IPTraining (Pay)'} name = {"adminChargeCode"}>IPTraining (Pay)</MenuItem>
                                        <MenuItem value={'MISC (DNP)'} name = {"adminChargeCode"}>MISC (DNP)</MenuItem>
                                        <MenuItem value={'Maintenance (PAY)'} name = {"adminChargeCode"}>Maintenance (PAY)</MenuItem>
                                        <MenuItem value={'Meeting (PAY)'} name = {"adminChargeCode"}>Meeting (PAY)</MenuItem>
                                        <MenuItem value={'One-On-One (Pay)'} name = {"adminChargeCode"}>One-On-One (Pay)</MenuItem>
                                        <MenuItem value={'STD-Activity'} name = {"adminChargeCode"} >STD-Activity</MenuItem>
                                        <MenuItem value={'STD-Interview'} name = {"adminChargeCode"}>STD-Interview</MenuItem>
                                        <MenuItem value={'Summer Prog (PAY)'} name = {"adminChargeCode"}>Summer Prog (PAY)</MenuItem>
                                        <MenuItem value={'VIP/GUEST (PAY)'} name = {"adminChargeCode"}>VIP/GUEST (PAY)</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs = {2.4}></Grid>
                                <Grid item xs = {2.4}><Button variant = "contained" style = {{marginTop : '25%', marginLeft : '60%'}} onClick = {this.submitAdminActivity}>Submit</Button></Grid>
                                </Grid>
                            </div>
                        </Card> 
                    </div>





                    </Card>
                </Backdrop>
            </div>




        
        </div>)
    }
}

export default LinearScheduler

/*
<div style = {{backgroundColor:'rgba(120, 120, 120, 0.9)', width: '600px', marginLeft : '50px'}}>
              <Grid container>
                <Grid item xs = {1}>
                <Button style ={{backgroundColor : '#337ab7', color : 'white'}}>E</Button>
                </Grid>
                <Grid item xs = {10}>
                <Typography style = {{color : 'white'}}>0600-1300</Typography>
                </Grid>
                <Grid item xs = {1}>
                <Button style = {{backgroundColor : '#d9534f', color : 'white'}}>-</Button>
                </Grid>
              </Grid>
              </div>





              <div>
          <div className="handle">Drag from here</div>
          <div>This readme is really dragging on...</div>
        </div>
*/


/*
 <div style = {{backgroundColor:'rgba(120, 120, 120, 0.9)', width: this.state.activityTime*120}} hidden={this.state.activityHidden}>
              <Grid container>
                <Grid item xs = {1} style = {{}}>
                <Button style ={{backgroundColor : '#337ab7', color : 'white', marginLeft : '0px'}} onClick={this.toggleEditBackdrop}>E</Button>
                </Grid>
                <Grid item xs = {10}>
                <div className="handle">
                    <Typography style = {{color : 'white'}}>{this.state.startTimeHours}:{this.state.startTimeMins}-{this.state.startTimeHours + 2}:{this.state.startTimeMins}</Typography>
                </div>
                </Grid>
                <Grid item xs = {1} style = {{marginRight : '250px'}}>
                <Button style = {{backgroundColor : '#d9534f', color : 'white', marginRight : '250px'}} onClick = {this.hideActivityOne}>-</Button>
                </Grid>
              </Grid>
              </div>

*/




/*
<Draggable axis="x" handle=".handle" defaultPosition={{x: 0, y: 0}} position={this.state.activityPos} grid={[25, 25]} scale={1} onStart={this.handleStart} onDrag={this.handleDrag} onStop={this.handleStop}>
        <div style = {{backgroundColor:'rgba(120, 120, 120, 0.9)', width: this.state.activityTime*120}} hidden={true}>
              <Grid container>
                <Grid item xs = {1}>
                <Button style ={{backgroundColor : '#337ab7', color : 'white'}} onClick={this.toggleEditBackdrop}>E</Button>
                </Grid>
                <Grid item xs = {10}>
                <div className="handle">
                    <Typography style = {{color : 'white'}}>{this.state.startTimeHours}:{this.state.startTimeMins}-{this.state.startTimeHours + 2}:{this.state.startTimeMins}</Typography>
                </div>
                </Grid>
                <Grid item xs = {1}>
                <Button style = {{backgroundColor : '#d9534f', color : 'white', marginRight : '50px'}} onClick = {this.hideActivityOne}>-</Button>
                </Grid>
              </Grid>
              </div>
      </Draggable>

*/