import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import * as firebase from "firebase";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
class CourseAdminUnit extends Component{
    constructor(props){
        super(props)
        this.state = {
            course : this.props.course,
            unit : this.props.unit,
            objectives : []
        }
    }
    componentWillMount(){
        let db = firebase.firestore()
        let a = this
        db.collection('courses').doc(this.state.course).collection(this.state.unit).get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                if(doc.id != 'info'){
                var holder = a.state.objectives
                holder.push(<h4>{doc.id}</h4>)
                a.setState({objectives : holder})
                }
            })
        })
    }
    render(){
        return(
        <ExpansionPanel style = {{width : '100%'}}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
            {this.state.unit}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div>
              {this.state.objectives}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        )
    }
}

export default CourseAdminUnit