import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import DispatchActivityView from "./dispatchActivityView";
class DispatchContainerView extends Component {
  render() {
    return <DispatchActivityView></DispatchActivityView>;
  }
}

export default DispatchContainerView;
