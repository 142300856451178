import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Aircraft from "./aircraftViewAdmin";
import Grid from "@material-ui/core/Grid";
import * as firebase from "firebase";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Backdrop } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import AircraftCreateView from "./aircraftCreateView";
class AircraftDrawAdmin extends Component {
  state = {
    ac: [],
    renderAircraft: [],
    testAc: [<Aircraft></Aircraft>, <Aircraft></Aircraft>],
    modalOpen: false,
  };

  addAircraft(aircraft) {
    this.setState({ renderAircraft: aircraft });
  }
  componentWillMount() {
    var db = firebase.firestore();
    var docRef = db.collection("aircraft");
    let a = this;
    docRef
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          a.state.ac.push(doc.data());
          console.log(doc.data().moment);
          console.log(a.state.ac.length);
          var holdAc = a.state.renderAircraft;
          holdAc.push(
            <Aircraft
              callsign={doc.id}
              tailNumber={doc.data().nNumber}
              type={doc.data().aircraftType}
              emptyWeight={doc.data().emptyWeight}
              moment={doc.data().moment}
              CG={doc.data().CG}
              usefulLoad={doc.data().usefulLoad}
              grossWeight={doc.data().grossWeight}
            >
              {" "}
            </Aircraft>
          );
          a.addAircraft(holdAc);
          console.log(a.state.renderAircraft);
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }
  dismissModal() {}
  render() {
    let a = this;
    var isOpen = false;
    return (
      <div>
        <Grid container spacing={1}>
          {a.state.renderAircraft}
        </Grid>
        <Fab
          onClick={() => {
            this.setState({ modalOpen: true });
          }}
          color="primary"
          aria-label="add"
          style={addButtonStyle}
        >
          <AddIcon />
        </Fab>
        <Modal
          open={a.state.modalOpen}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <AircraftCreateView
            dismiss={this.dismissModal()}
          ></AircraftCreateView>
        </Modal>
      </div>
    );
  }
}
const addButtonStyle = {
  left: "95%",
  bottom: "95%",
};

export default AircraftDrawAdmin;
