import React, { Component } from "react";
import * as firebase from "firebase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import GridList from "@material-ui/core/GridList";
import InstructorActivityGrade from "./InstructorActivityGrade";
import GridListTile from "@material-ui/core/GridListTile";
import { List, Backdrop } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import Modal from "@material-ui/core/Modal";
import GradeSheet from "./gradeSheet"
class InstructorDayACViewActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: props.student,
      instructor: props.instructor,
      startTime: props.startTime,
      stopTime: props.stopTime,
      startDate: props.startDate,
      status: props.status,
      resourceType: props.resourceType,
      resource: props.resource,
      hobbs: props.hobbs,
      aircraftRate: props.aircraftRate,
      fuel: props.fuel,
      id: props.id,
      refresh: this.props.refresh,
      completeDiagramOpen: false,
      course: this.props.course,
      unit: this.props.unit,
      refActivity: "",
      actUnits: [],
      acGrades: [],
      actGrade: "",
      oralTime: 0,
      fuelRate: 44,
      hobbsRate: 189,
      oralRate: 65,
      totalActivityCost: 0,
      IPPin: "",
      STPin: "",
      IPWorkingPin: "0",
      STWorkingPin: "0",
      RouteOfFlight: "",
      ASELTime: 0,
      completeButtonDisabled: true,
      havePulledActivities: false,
      previousGrades : [],
      gradeSheetOpen : false,
      activityComment : ""
    };
    this.returnIfEnabled = this.returnIfEnabled.bind(this);
    this.authButtonPressed = this.authButtonPressed.bind(this);
    this.completeButtonPushed = this.completeButtonPushed.bind(this);
    this.dismissComplteDialog = this.dismissComplteDialog.bind(this);
    this.getActivityObjectives = this.getActivityObjectives.bind(this);
    this.changeACTGrade = this.changeACTGrade.bind(this);
    this.updateOralTime = this.updateOralTime.bind(this);
    this.updateUnitGrades = this.updateUnitGrades.bind(this);
    this.checkForSubmissionGuidelines = this.checkForSubmissionGuidelines.bind(
      this
    );
    this.submitToLogs = this.submitToLogs.bind(this);
    this.updateIPPin = this.updateIPPin.bind(this);
    this.updateSTPin = this.updateSTPin.bind(this);
    this.updateStates = this.updateStates.bind(this);
    this.setDBLineItems = this.setDBLineItems.bind(this);
    this.getResourceRates = this.getResourceRates.bind(this)
    this.openGradeSheet = this.openGradeSheet.bind(this)
    this.updateActivityComment = this.updateActivityComment.bind(this)
  }
  componentWillMount() {
    var db = firebase.firestore();
    var a = this;
    var previousGradeCarry = []
    if(this.state.student != "" && this.state.student!=null && this.state.student!='undefined'){
    var lastGradeRef = db.collection('users').doc(this.state.student).collection('logbook').where("unit", '==', this.state.unit)
    lastGradeRef.get().then(function(querySnapshot){
      querySnapshot.forEach(function(doc){
        if(doc.data().course == a.state.course){
           let lineItemRef = db.collection('users').doc(a.state.student).collection('logbook').doc(doc.id).collection('lineItems')
           lineItemRef.get().then(function(lineItemSnapshot){
              lineItemSnapshot.forEach(function(lineItemDoc){
                previousGradeCarry.push({"lineItemName" : doc.id, "lineItemGrade" : doc.data().grade})
              })
           })
        }
      })
    })
  }


    var activityRef = db
      .collection("courses")
      .doc(this.state.course)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          console.log(doc.data());
          var holder = doc.data().lessons;
          for (var i = 0; i < holder.length; i++) {
            if (holder[i] == a.state.unit) {
              a.setState({ refActivity: holder[i] });

              let unit = holder[i];

              console.log("GETTING ACTIVITY DATA");
              console.log(unit.toString() + " " + "5FLIGHT #5 (DUAL)");

              var docRef = db.collection("courses").doc(a.state.course).collection(unit).orderBy("order");
              console.log("ORDER:::::::::");
              
              //docRef.orderBy("order");
              docRef.get().then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                  // doc.data() is never undefined for query doc snapshots
                  console.log(doc.id, " => ", doc.data());
                  var holder = a.state.actUnits;
                  holder.push(
                    <ListItem>
                      <InstructorActivityGrade
                        activityObjective={doc.id}
                        activityCI={doc.data().isGraded}
                        order={doc.data().order}
                        update={a.updateUnitGrades}
                      ></InstructorActivityGrade>
                    </ListItem>
                  );
                  a.setState({ actUnits: holder }, ()=>a.getResourceRates());
                });
              });
            }
          }
        }else{
          console.log("DOC DOES NOT EXIST!!!!")
        }
      });
    var IPRef = db
      .collection("users")
      .doc(this.state.instructor)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          a.setState({ IPPin: doc.data().pin });
        }
      });
    if(this.state.student != "" && this.state.student != null){
    var StRef = db
      .collection("users")
      .doc(this.state.student)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          a.setState({ STPin: doc.data().pin });
        }
      });
    }
    /*
    if (this.state.hobbs == null) {
      this.setState({ hobbs: 0 });
    }
    this.setState({
      totalActivityCost:
        this.state.hobbs * this.state.fuelRate +
        this.state.hobbs * this.state.hobbsRate +
        0 * this.state.oralRate,
    });
    */
  }
  
  getResourceRates(){
    let db = firebase.firestore()
    let ratesRef = db.collection('rates').doc(this.state.resourceType)
    let a = this
    ratesRef.get().then(function(doc){
      a.setState({oralRate : doc.data().oralRate, hobbsRate : doc.data().hobbsRate, fuelRate : doc.data().fuelRate})
    })
  }


  getActivityObjectives(unit) {
    console.log("GETTING ACTIVITY DATA");
    console.log(unit.toString() + " " + "5FLIGHT #5 (DUAL)");
    console.log(this.state.course);
    var db = firebase.firestore();
    var a = this;
    var docRef = db
      .collection("courses")
      .doc(this.state.course)
      .collection(unit)
      .orderBy("order");
    console.log("ORDER:::::::::");
    docRef.orderBy("order");
    docRef.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        var holder = a.state.actUnits;
        holder.push(
          <ListItem>
            <InstructorActivityGrade
              activityObjective={doc.id}
              activityCI={doc.data().isGraded}
              order={doc.data().order}
              update={a.updateUnitGrades}
            ></InstructorActivityGrade>
          </ListItem>
        );
        a.setState({ actUnits: holder });
      });
    });
  }
  returnTime(convertTime) {
    let d = new Date(convertTime * 1000);
    return Moment(d).format("HH:mm");
  }
  returnIfEnabled(buttonId) {
    let currentStatus = this.state.status;
    if (buttonId == "Authorize" && currentStatus == "Checked-In") {
      return false;
    }
    if (
      buttonId == "Complete" &&
      (currentStatus == "Authorized" || currentStatus == "Ramped-In")
    ) {
      return false;
    }
    return true;
  }
  authButtonPressed() {
    let d = new Date();
    let monthHolder = d.getMonth();
    let dayHolder = d.getDate();
    let yearHolder = d.getFullYear();
    let db = firebase.firestore();
    let a = this;
    console.log(monthHolder + 1 + "-" + (dayHolder + 1) + "-" + yearHolder);
    var docRef = db
      .collection("schedules")
      .doc(monthHolder + 1 + "-" + dayHolder + "-" + yearHolder)
      .collection("activities")
      .doc(this.state.id);
    docRef.update({ status: "Authorized" }).then(function () {
      a.state.refresh();
    });
  }
  completeButtonPushed() {
    this.setState({ completeDiagramOpen: true });
  }
  dismissComplteDialog() {
    this.setState({ completeDiagramOpen: false });
  }
  changeACTGrade(event) {
    this.setState({ actGrade: event.target.value }, () =>
      this.checkForSubmissionGuidelines()
    );
    console.log("GRADE CHANGED TO : " + this.state.actGrade);
  }
  updateOralTime(event) {
    
    
    this.setState({ oralTime: event.target.value });
    var aircraftAcTime = this.state.hobbs;
    if(aircraftAcTime == null){
      aircraftAcTime = 0
    }
    this.setState(
      {
        totalActivityCost:
          parseFloat(aircraftAcTime) * this.state.fuelRate +
          parseFloat(aircraftAcTime) * this.state.hobbsRate +
          event.target.value * this.state.oralRate + parseFloat(aircraftAcTime) * 1.5 + event.target.value*1.5,
      },
      () => this.checkForSubmissionGuidelines()
    );
  }
  updateUnitGrades(index, grade, comments, goodToGo, unitName) {
    console.log("UPDATING UNIT GRADES");
    let holder = this.state.acGrades;
    console.log(index + " :::: GOOD TO GO");
    holder[index] = [
      index,
      this.state.actUnits[index],
      grade,
      comments,
      goodToGo,
      unitName,
    ];
    this.setState({ acGrades: holder }, () =>
      this.checkForSubmissionGuidelines()
    );
    console.log(index + " : " + grade + " : " + comments);
  }
  checkForSubmissionGuidelines() {
    console.log(
      ":::::: CHECKING FOR SUBMISSION GUIDLINES " +
        this.state.actUnits.length +
        " " +
        this.state.acGrades.length
    );
    var count = 0;
    if(this.state.actUnits.length == 0){
      this.setState({ isReadyToSubmit: true });
    }
    if (this.state.actUnits.length == this.state.acGrades.length) {
      if (this.actGrade != "") {
        for (var i = 0; i < this.state.acGrades.length; i++) {
          if (this.state.acGrades[i][4] == true) {
            this.setState({ isReadyToSubmit: false });
          } else {
            console.log(">>>>>>>>>" + count + " " + i);
            if (count != i) {
              count = count + 1;
            } else {
              this.setState({ isReadyToSubmit: true });
            }
          }
        }
      }
    }
  }
  updateIPPin(event) {
    this.setState({ IPWorkingPin: event.target.value });
  }
  updateSTPin(event) {
    this.setState({ STWorkingPin: event.target.value });
  }
  submitToLogs() {
    console.log("SUBMITTING TO LOGS");
    let a = this;
    console.log(Date(this.state.startTime));
    //console.log(this.state.IPPIn.trim() == this.state.IPWorkingPin.trim())
    let d = new Date();
    let tDate = d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear();
    if (this.state.isReadyToSubmit == true && this.state.resource != null) {
      console.log(this.state.IPWorkingPin)
      console.log(this.state.STWorkingPin)
      console.log(this.state.IPPin)
      console.log(this.state.STPin)
      console.log("READY TO SUBMIT");
      console.log(this.state.startTime);
      if (
        (this.state.IPPin == this.state.IPWorkingPin &&
        this.state.STPin == this.state.STWorkingPin) || (this.state.IPPin == this.state.IPWorkingPin && this.state.course == "ADMIN")
      ) {
        let db = firebase.firestore();
        console.log("PINS MATCH");
        let dHolder = new Date(this.state.startTime);
        if(this.state.course != "ADMIN"){
        db.collection("users")
          .doc(this.state.student)
          .collection("logbook")
          .doc(this.state.id)
          .set({
            isPaid: false,
            date: firebase.firestore.FieldValue.serverTimestamp(),
            durationOfFlight: a.state.hobbs,
            oralTime: this.state.oralTime,
            aircraftMake: this.state.resourceType,
            aircraftID: this.state.resource,
            routeOfFlight: this.state.RouteOfFlight,
            ASEL: this.state.hobbs,
            AMEL : 0.0,
            dualReceived: this.state.stDualReceived,
            PIC: this.state.stPIC,
            day: this.state.stDay,
            night: this.state.stNight,
            XC: this.state.stXC,
            IFRAct: this.state.stIFRAct,
            IFRSim: this.state.stIFRSim,
            approaches: this.state.stApproaches,
            dayLanding: this.state.stDayLanding,
            nightLanding: this.state.stNightLanding,
            isFlightOrSim: true,
            course : this.state.course,
            unit : this.state.unit,
            actGrade : this.state.actGrade,

          })
          
          db.collection("users")
          .doc(this.state.student)
          .collection("cashiers")
          .doc(this.state.id)
          .set({
            date: Date(a.state.startTime),
            durationOfFlight: a.state.hobbs,
            oralTime: this.state.oralTime,
            aircraftMake: this.state.resourceType,
            oralTime: this.state.oralTime,
            totalActivityCost : this.state.totalActivityCost,
            oralRate : this.state.oralRate,
            hobbsRate : this.state.hobbsRate,
            course : this.state.course,
            unit : this.state.unit,
            instructor : this.state.instructor,
            paid:false,
            fuelRate : this.state.fuelRate,
            
            })
          .then(function () {
            a.setDBLineItems();
          });

          if(this.state.actGrade == "U" || this.state.actGrade == "I"){
            let activityDate = new Date()
            db.collection('users').doc(this.state.student).collection('comments').doc().set({
              date : (activityDate.getMonth()+1) + "/" + activityDate.getDate() + '/' + activityDate.getFullYear(),
              enteredBy : this.state.instructor,
              lastEditied : 0,
              comment : this.state.course + ' ' + this.state.unit + ' (' + this.state.actGrade + ') :' + this.state.activityComment
            })
          }


        }
        if(this.state.course != "ADMIN"){
        db.collection("users")
          .doc(this.state.instructor)
          .collection("logbook")
          .doc(this.state.id)
          .set({
            date: Date(a.state.startTime),
            durationOfFlight: a.state.hobbs,
            oralTime: this.state.oralTime,
            aircraftMake: this.state.resourceType,
            aircraftID: this.state.resource,
            routeOfFlight: this.state.RouteOfFlight,
            oralTime: this.state.oralTime,
            aircraftMake: this.state.resourceType,
            aircraftID: this.state.resource,
            routeOfFlight: this.state.RouteOfFlight,
            ASEL: this.state.hobbs,
            dualGiven: this.state.ipDualGiven,
            PIC: this.state.ipPIC,
            day: this.state.ipDay,
            night: this.state.ipNight,
            XC: this.state.ipXC,
            IFRAct: this.state.ipIFRAct,
            IFRSim: this.state.ipIFRSim,
            approaches: this.state.ipApproaches,
            dayLanding: this.state.ipDayLanding,
            nightLanding: this.state.ipNightLanding,
            isFlightOrSim: true,
            course : this.state.course,
            unit : this.state.unit,
            actGrade : this.state.actGrade,

          });
          

          


        }
        var hobbsTime = this.state.hobbs
        if(this.state.hobbs == null){
          hobbsTime = 0
        }
        db.collection("users").doc(this.state.instructor).collection("payroll").doc(this.state.id).set({
          date : Date(a.state.startTime),
          activity : "Course",
          unit : a.state.unit,
          student : a.state.student,
          hours : parseFloat(hobbsTime) + parseFloat(this.state.oralTime)
        })
        console.log(
          d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear()
        );
        db.collection("schedules")
          .doc(d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear())
          .collection("activities")
          .doc(a.state.id)
          .update({
            status: "Completed",
          });
        this.state.refresh();
      } else {
        console.log("INCORRECT PINS");
      }
    } else {
      if (this.state.isReadyToSubmit == true) {
        console.log("READY TO SUBMIT");
        console.log(this.state.startTime);
        if (
        (this.state.IPPin == this.state.IPWorkingPin &&
          this.state.STPin == this.state.STWorkingPin) || (this.state.IPPin == this.state.IPWorkingPin && this.state.course == "ADMIN")
        ) {
          let db = firebase.firestore();
          console.log("PINS MATCH");
          let dHolder = new Date(this.state.startTime);
          db.collection("users")
            .doc(this.state.student)
            .collection("logbook")
            .doc(this.state.id)
            .set({
              isPaid: false,
              date: firebase.firestore.FieldValue.serverTimestamp(),
              oralTime: this.state.oralTime,
              isFlightOrSim: false,
            })
            .then(function () {
              a.setDBLineItems();
            });

          db.collection("users")
            .doc(this.state.instructor)
            .collection("logbook")
            .doc(this.state.id)
            .set({
              date: Date(a.state.startTime),
              oralTime: this.state.oralTime,
              isFlightOrSim: false,
            });
          console.log(
            d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear()
          );
          db.collection("schedules")
            .doc(d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear())
            .collection("activities")
            .doc(a.state.id)
            .update({
              status: "Completed",
            });
          this.state.refresh();
        } else {
          console.log("INCORRECT PINS");
        }
      }
    }
  }
  setDBLineItems() {
    let db = firebase.firestore();
    let a = this;
    console.log("SETTING LINE ITEMS");
    console.log(a.state.acGrades);
    for (var i = 0; i < a.state.acGrades.length; i++) {
      //console.log(a.state.acGrades[i][5].toString())
      //  let ref = a.state.acGrades[i][5].toString()
      var docData = {
        grade: a.state.acGrades[i][1],
        order: a.state.acGrades[i][0],
        comment: a.state.acGrades[i][3],
        name: a.state.acGrades[i][5],
      };

      db.collection("users")
        .doc(a.state.student)
        .collection("logbook")
        .doc(a.state.id)
        .collection("lineItems")
        .doc(a.state.acGrades[i][5])
        .set({
          grade: a.state.acGrades[i][2],
          order: a.state.acGrades[i][0],
          comment: a.state.acGrades[i][3],
          name: a.state.acGrades[i][5],
        })
        .then(function () {
          console.log("DOC SUCCESSFULLY WRITTEN!");
        });


        db.collection("users")
        .doc(a.state.instructor)
        .collection("logbook")
        .doc(a.state.id)
        .collection("lineItems")
        .doc(a.state.acGrades[i][5])
        .set({
          grade: a.state.acGrades[i][2],
          order: a.state.acGrades[i][0],
          comment: a.state.acGrades[i][3],
          name: a.state.acGrades[i][5],
        })
    }
  }
  updateStates(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  openGradeSheet(){
    console.log(this.state.gradeSheetOpen)
    this.setState({gradeSheetOpen : !this.state.gradeSheetOpen})
  }
  updateActivityComment(event){
    console.log(event.target.value)
    this.setState({activityComment : event.target.value})
  }
  render() {
    return (
      <TableRow>
        <TableCell>{this.state.student}</TableCell>
        <TableCell>{this.state.course + " " + this.state.unit}</TableCell>
        <TableCell>{this.returnTime(this.state.startTime)}</TableCell>
        <TableCell>{this.returnTime(this.state.stopTime)}</TableCell>
        <TableCell>{this.props.resource}</TableCell>
        <TableCell>{this.props.status}</TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            disabled={this.returnIfEnabled("Authorize")}
            onClick={this.authButtonPressed}
          >
            Authorize
          </Button>
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={this.completeButtonPushed}
            disabled={this.returnIfEnabled("Complete")}
          >
            Complete
          </Button>
        </TableCell>

        <TableCell>
          <Button variant="contained" color="primary" disabled = {this.state.course == "ADMIN"} onClick = {this.openGradeSheet}>Gradesheet</Button>
          
        </TableCell>

        

        <Modal
          aria-labelledby="simple-dialog-title"
          open={this.state.gradeSheetOpen}
          onClose={this.openGradeSheet}
          backdropcomponent={Backdrop}
          >
            <GradeSheet student = {this.state.student} unit = {this.state.unit} course = {this.state.course}></GradeSheet>
          </Modal>


        <Modal
          aria-labelledby="simple-dialog-title"
          open={this.state.completeDiagramOpen}
          onClose={this.dismissComplteDialog}
          backdropcomponent={Backdrop}
        >
          <Card style={cardStyle}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h1>Activity Complete</h1>
              </Grid>
              <Grid container sm={12}>
                <Grid item xs={4}>
                  <TextField
                    label="Aircraft Time"
                    value={this.state.hobbs}
                    disabled
                    variant="outlined"
                    type="number"
                    style={{ width: "80%" }}
                  ></TextField>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Oral Time"
                    variant="outlined"
                    type="number"
                    onChange={this.updateOralTime}
                    style={{ width: "80%" }}
                    value={this.state.oralTime}
                  ></TextField>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={"Fuel Surcharge @ $"+this.state.fuelRate+"/hr"}
                  value={this.state.hobbs * this.state.fuelRate}
                  disabled
                  variant="outlined"
                  type="number"
                  style={{ width: "20%" }}
                ></TextField>
                <TextField
                  label={"Hobbs Time @ $" + this.state.hobbsRate + "/hr"}
                  variant="outlined"
                  type="number"
                  value={this.state.hobbs * this.state.hobbsRate}
                  disabled
                  style={{ width: "20%" }}
                ></TextField>
                <TextField
                  label={"Oral Time @ $"+this.state.oralRate+"/hr"}
                  variant="outlined"
                  type="number"
                  value={this.state.oralTime * this.state.oralRate}
                  disabled
                  style={{ width: "20%" }}
                ></TextField>
                <TextField
                  label="Service Fee @ $1.5/hr"
                  variant="outlined"
                  type="number"
                  value={this.state.oralTime * 1.5 + parseFloat(this.state.hobbs) * 1.5}
                  disabled
                  style={{ width: "20%" }}
                ></TextField>
                <TextField
                  label="Activity Cost"
                  variant="outlined"
                  type="number"
                  value={this.state.totalActivityCost}
                  disabled
                  style={{ width: "20%" }}
                ></TextField>
              </Grid>
              <div hidden={this.state.course == "ADMIN"}>
              <Grid item xs={12}>
                <h2>Student Logbook</h2>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="stASEL"
                  value={this.state.stLandings}
                  label="ASEL"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                  defaultValue={this.state.hobbs}
                ></TextField>
                <TextField
                  id="stDualReceived"
                  label="Dual Received"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="stPIC"
                  label="PIC"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="stDay"
                  label="Day"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="stNight"
                  label="Night"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="stXC"
                  label="XC"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="stIFRAct"
                  label="IFR Actual"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="stIFRSim"
                  label="Sim IFR"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="stApproaches"
                  label="Appr"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="stDayLanding"
                  label="Day Landing"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="stNightLanding"
                  label="Night Landing"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
              </Grid>
              </div>
              <Grid item xs={12}>
                <h2>Instructor Logbook</h2>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="ipASEL"
                  label="ASEL"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                  defaultValue={this.state.hobbs}
                ></TextField>
                <TextField
                  id="ipDualGiven"
                  label="Dual Given"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="ipPIC"
                  label="PIC"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="ipDay"
                  label="Day"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="ipNight"
                  label="Night"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="ipXC"
                  label="XC"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="ipIFRAct"
                  label="IFR Actual"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="ipIFRSim"
                  label="Sim IFR"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="ipApproaches"
                  label="Appr"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="ipDayLanding"
                  label="Day Landing"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
                <TextField
                  id="ipNightLanding"
                  label="Night Landing"
                  variant="outlined"
                  type="number"
                  style={{ width: "9%" }}
                  onChange={this.updateStates}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="RouteOfFlight"
                  value={this.state.RouteOfFlight}
                  label="Route of Flight"
                  variant="outlined"
                  style={{ width: "95%" }}
                  onChange={this.updateStates}
                ></TextField>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                <h2>Grade</h2>
              </Grid>
              <Grid item xs={6}>
                <Select
                  id="totalGrade"
                  value={this.state.actGrade}
                  onChange={this.changeACTGrade}
                >
                  <MenuItem value={"S"}>S</MenuItem>
                  <MenuItem value={"U"}>U</MenuItem>
                  <MenuItem value={"I"}>I</MenuItem>
                </Select>
              </Grid>

              <List
                style={{ height: "300px", width: "100%", overflow: "auto" }}
              >
                {this.state.actUnits}
              </List>
              <Grid item xs={12}>
                <TextField
                  id="activityComments"
                  label="Comments"
                  placeholder=""
                  multiline
                  style={{ width: "95%" }}
                  onChange = {this.updateActivityComment}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="ipPin"
                  label="IP Pin"
                  type="password"
                  onChange={this.updateIPPin}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="stuPin"
                  label="Student Pin"
                  type="password"
                  onChange={this.updateSTPin}
                  disabled = {this.state.course == "ADMIN"}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                  disabled={!this.state.isReadyToSubmit}
                  onClick={this.submitToLogs}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Modal>
      </TableRow>
    );
  }
}
const cardStyle = {
  textAlign: "center",
  width: "80%",
  height: "800px",
  marginLeft: "15%",
  overflow: "auto",
  marginTop: "3%",
};

export default InstructorDayACViewActivity;
