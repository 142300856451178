import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Aircraft from "./aircraftView";
import Grid from "@material-ui/core/Grid";
import * as firebase from "firebase";
class AircraftDraw extends Component {
  state = {
    ac: [],
    renderAircraft: [],
    testAc: [<Aircraft></Aircraft>, <Aircraft></Aircraft>],
  };
  addAircraft(aircraft) {
    this.setState({ renderAircraft: aircraft });
  }
  componentWillMount() {
    var db = firebase.firestore();
    var docRef = db.collection("aircraft");
    let a = this;
    docRef
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          a.state.ac.push(doc.data());
          console.log(doc.data().moment);
          console.log(a.state.ac.length);
          var holdAc = a.state.renderAircraft;
          holdAc.push(
            <Aircraft
              callsign={doc.id}
              tailNumber={doc.data().nNumber}
              type={doc.data().aircraftType}
              emptyWeight={doc.data().emptyWeight}
              moment={doc.data().moment}
              CG={doc.data().CG}
              usefulLoad={doc.data().usefulLoad}
              grossWeight={doc.data().grossWeight}
            >
            </Aircraft>
          );
          a.addAircraft(holdAc);
          console.log(a.state.renderAircraft);
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  render() {
    let a = this;
    return (
      <Grid container spacing={1}>
        {a.state.renderAircraft}
      </Grid>
    );
  }
}

export default AircraftDraw;
