import React, { Component } from "react";
import * as firebase from "firebase";
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import StudentProfile from "./studentProfile";
class MyStudents extends Component {
    constructor(props){
        super(props)
        this.state = {
            students : []
        }

    }
    componentDidMount(){
        let a = this
        let db = firebase.firestore()
        var SPs = []
        db.collection('users').doc('TEST_INSTRUCTOR').get().then(function(doc){
            let students = doc.data().students
            for(var i = 0; i < students.length; i = i + 1){
                SPs.push(<StudentProfile studentName ={students[i]}></StudentProfile>)
                if(i == students.length-1){
                    a.setState({students:SPs})
                }
            }
        })
    }
    render(){
        return(<Grid container>
            {this.state.students}
        </Grid>)
    }
}

export default MyStudents