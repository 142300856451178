import React, { Component } from "react";
import * as firebase from "firebase";
import Paper from '@material-ui/core/Paper';
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  Title,
  Legend,
} from '@devexpress/dx-react-chart-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { Animation } from '@devexpress/dx-react-chart';
import { weeklyPassRateData as data } from './testAdminData';



const format = () => tick => tick;
const legendStyles = () => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  },
});
const legendLabelStyles = theme => ({
  label: {
    paddingTop: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
});
const legendItemStyles = () => ({
  item: {
    flexDirection: 'column',
  },
});

const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const legendItemBase = ({ classes, ...restProps }) => (
  <Legend.Item className={classes.item} {...restProps} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);
const Item = withStyles(legendItemStyles, { name: 'LegendItem' })(legendItemBase);
const demoStyles = () => ({
  chart: {
    paddingRight: '20px',
  },
  title: {
    whiteSpace: 'pre',
  },
});

const ValueLabel = (props) => {
  const { text } = props;
  return (
    <ValueAxis.Label
      {...props}
      text={`${text}%`}
    />
  );
};

const titleStyles = {
  title: {
    whiteSpace: 'pre',
  },
};
const TitleText = withStyles(titleStyles)(({ classes, ...props }) => (
  <Title.Text {...props} className={classes.title} />
));




let testData = [1,2,3,4,5,6,7]
class ReportGeneration extends Component {
    constructor(props){
        super(props)
        this.state = {
            lines : [],
            title : [],
            params : [],
            term : "pastThreeMonths",
            frequency : "week",
            data1Param : "",
            data2Param : "",
            data3Param : "",
            numOfParams : 0,
            chart : []
            
        }
        this.addParam = this.addParam.bind(this)
        this.changeTerm = this.changeTerm.bind(this)
        this.changeFrequency = this.changeFrequency.bind(this)
        this.changeData = this.changeData.bind(this)
    }
    addParam(){
        this.setState({numOfParams:this.state.numOfParams+1})
    }
    changeTerm(event){
        console.log(event.target.value)
        this.setState({term : event.target.value},()=>this.getData())
    }
    changeFrequency(event){
        this.setState({frequency : event.target.value},()=>this.getData())
    }
    changeData(event){
        console.log(event.target.value)
        console.log(event.target)
        this.setState({[event.target.name] : event.target.value},()=>this.getData())
    }
    getData(){

        var data1ParamName = ""
        var data2ParamName = ""
        var data3ParamName = ""
        if(this.state.data1Param == "passrate"){
            data1ParamName = "Checkride Pass Rate"
        }
        if(this.state.data1Param == "stagePassRate"){
            data1ParamName = "Stage Pass Rate"
        }
        if(this.state.data1Param == "threshold"){
            data1ParamName = "Threshold"
        }


        if(this.state.data2Param == "passrate"){
            data2ParamName = "Checkride Pass Rate"
        }
        if(this.state.data2Param == "stagePassRate"){
            data2ParamName = "Stage Pass Rate"
        }
        if(this.state.data2Param == "threshold"){
            data2ParamName = "Threshold"
        }

        if(this.state.data3Param == "passrate"){
            data3ParamName = "Checkride Pass Rate"
        }
        if(this.state.data3Param == "stagePassRate"){
            data3ParamName = "Stage Pass Rate"
        }
        if(this.state.data3Param == "threshold"){
            data3ParamName = "Threshold"
        }
        



        if(this.state.term == "pastThreeMonths" && this.state.frequency == "week"){
            var carryData = []
            var carryLines = []
            for(var i = 12; i > 0; i = i - 1){
                console.log(data[data.length-i])
                carryData.push(data[data.length-i])
                if(i == 1){
                carryLines.push( <LineSeries
                    name={data1ParamName}
                    valueField={this.state.data1Param}
                    argumentField="month"
                />)
                carryLines.push( <LineSeries
                    name={data2ParamName}
                    valueField={this.state.data2Param}
                    argumentField="month"
                />)
                carryLines.push( <LineSeries
                    name={data3ParamName}
                    valueField={this.state.data3Param}
                    argumentField="month"
                />)
                this.setState({lines : carryLines})
                var chartObject = [<Chart data={carryData}>
                    <ArgumentAxis />
                        <ValueAxis max={50} labelComponent={ValueLabel}/>
                        {carryLines}
                            <Title
                                text={data1ParamName + ' - Past Three Months - Weekly'}
                                textComponent={TitleText}
                            />
                            
                            <Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
                </Chart>]
                this.setState({chart : chartObject})
                }
            }
        }


        if(this.state.term == "pastThreeMonths" && this.state.frequency == "month"){
            var carryData = []
            var carryLines = []
            for(var i = 12; i > 0; i = i - 4){
                console.log(data[data.length-i])
                carryData.push(data[data.length-i])
                if(i == 1){
                    carryLines.push( <LineSeries
                        name={data1ParamName}
                        valueField={this.state.data1Param}
                        argumentField="month"
                    />)
                    carryLines.push( <LineSeries
                        name={data2ParamName}
                        valueField={this.state.data2Param}
                        argumentField="month"
                    />)
                    carryLines.push( <LineSeries
                        name={data3ParamName}
                        valueField={this.state.data3Param}
                        argumentField="month"
                    />)
                this.setState({lines : carryLines})
                var chartObject = [<Chart data={carryData}>
                    <ArgumentAxis />
                        <ValueAxis max={50} labelComponent={ValueLabel}/>
                        {carryLines}
                            <Title
                                text={data1ParamName + ' - Past Three Months - Monthly'}
                                textComponent={TitleText}
                            />
                            <Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
                </Chart>]
                this.setState({chart : chartObject})
                }
            }
        }


        if(this.state.term == "pastSixMonths" && this.state.frequency == "week"){
            var carryData = []
            var carryLines = []
            for(var i = 24; i > 0; i = i - 1){
                console.log(data[data.length-i])
                carryData.push(data[data.length-i])
                if(i == 1){
                    carryLines.push( <LineSeries
                        name={data1ParamName}
                        valueField={this.state.data1Param}
                        argumentField="month"
                    />)
                    carryLines.push( <LineSeries
                        name={data2ParamName}
                        valueField={this.state.data2Param}
                        argumentField="month"
                    />)
                    carryLines.push( <LineSeries
                        name={data3ParamName}
                        valueField={this.state.data3Param}
                        argumentField="month"
                    />)
                this.setState({lines : carryLines})
                var chartObject = [<Chart data={carryData}>
                    <ArgumentAxis />
                        <ValueAxis labelComponent={ValueLabel}/>
                        {carryLines}
                            <Title
                                text={data1ParamName + ' - Past Six Months - Weekly'}
                                textComponent={TitleText}
                            />
                            <Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
                </Chart>]
                this.setState({chart : chartObject})
                }
            }
        }
        if(this.state.term == "pastYear" && this.state.frequency == "week"){
            var carryData = []
            var carryLines = []
            for(var i = 24; i > 0; i = i - 1){
                console.log(data[data.length-i])
                carryData.push(data[data.length-i])
                if(i == 1){
                    carryLines.push( <LineSeries
                        name={data1ParamName}
                        valueField={this.state.data1Param}
                        argumentField="month"
                    />)
                    carryLines.push( <LineSeries
                        name={data2ParamName}
                        valueField={this.state.data2Param}
                        argumentField="month"
                    />)
                    carryLines.push( <LineSeries
                        name={data3ParamName}
                        valueField={this.state.data3Param}
                        argumentField="month"
                    />)
                this.setState({lines : carryLines})
                var chartObject = [<Chart data={data}>
                    <ArgumentAxis />
                        <ValueAxis labelComponent={ValueLabel}/>
                        {carryLines}
                            <Title
                                text={data1ParamName + ' - Past Six Months - Weekly'}
                                textComponent={TitleText}
                            />
                            <Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
                </Chart>]
                this.setState({chart : chartObject})
                }
            }
        }
        
    }
    render(){
        return(




            <Paper>

                <Card>
                    <Grid container>
                        <Grid item xs = {11}>
                            <h3>Report Parameters</h3>
                        </Grid>
                        <Grid item xs = {1}>
                            <Button variant = 'contained' style = {{marginTop : '10px'}} onClick = {this.addParam}>Add</Button>
                        </Grid>
                        <Grid item xs = {12}>
                                                        <Card hidden = {this.state.numOfParams < 1}>
                                                        <Grid container>
                                                            <Grid item xs = {3}>
                                                                <h3>Parameter 1</h3>
                                                            </Grid>
                                                            <Grid item xs = {3}>
                                                                <h3>Data</h3>
                                                                <Select id={"lineData"} value = {this.state.data1Param} onChange = {this.changeData} name = "data1Param">
                                                                    <MenuItem value={"passrate"} name = "data1Param">Checkride Pass Rate</MenuItem>
                                                                    <MenuItem value={"stagePassRate"} name = "data1Param">Stage Pass Rate</MenuItem>
                                                                    <MenuItem value={"threshold"} name = "data1Param">Threshold</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs = {3}>
                                                                <h3>Term</h3>
                                                                <Select id={"term"} value = {this.state.term} disabled = {false} onChange = {this.changeTerm}>
                                                                    <MenuItem value={"pastYear"}>Past Year</MenuItem>
                                                                    <MenuItem value={"pastSixMonths"}>Past Six Months</MenuItem>
                                                                    <MenuItem value={"pastThreeMonths"}>Past Three Months</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs = {3}>
                                                                <h3>Frequency</h3>
                                                                <Select id={"frequency"} value = {this.state.frequency} disabled = {false} onChange = {this.changeFrequency}>
                                                                    <MenuItem value={"month"}>Month</MenuItem>
                                                                    <MenuItem value={"week"}>Week</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>



                                                    <Card hidden = {this.state.numOfParams < 2}>
                                                        <Grid container>
                                                            <Grid item xs = {3}>
                                                                <h3>Parameter 2</h3>
                                                            </Grid>
                                                            <Grid item xs = {3}>
                                                                <h3>Data</h3>
                                                                <Select id={"lineData"} value = {this.state.data2Param} onChange = {this.changeData} name = "data2Param">
                                                                    <MenuItem value={"passrate"} name = "data2Param">Checkride Pass Rate</MenuItem>
                                                                    <MenuItem value={"stagePassRate"} name = "data2Param">Stage Pass Rate</MenuItem>
                                                                    <MenuItem value={"threshold"} name = "data2Param">Threshold</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs = {3}>
                                                                <h3>Term</h3>
                                                                <Select id={"term"} value = {this.state.term} disabled = {false} onChange = {this.changeTerm} disabled>
                                                                    <MenuItem value={"pastYear"}>Past Year</MenuItem>
                                                                    <MenuItem value={"pastSixMonths"}>Past Six Months</MenuItem>
                                                                    <MenuItem value={"pastThreeMonths"}>Past Three Months</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs = {3}>
                                                                <h3>Frequency</h3>
                                                                <Select id={"frequency"} value = {this.state.frequency} disabled = {false} onChange = {this.changeFrequency} disabled>
                                                                    <MenuItem value={"month"}>Month</MenuItem>
                                                                    <MenuItem value={"week"}>Week</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>

                                                    <Card hidden = {this.state.numOfParams < 3}>
                                                        <Grid container>
                                                            <Grid item xs = {3}>
                                                                <h3>Parameter 3</h3>
                                                            </Grid>
                                                            <Grid item xs = {3}>
                                                                <h3>Data</h3>
                                                                <Select id={"lineData"} value = {this.state.data2Param} onChange = {this.changeData} name = "data3Param">
                                                                    <MenuItem value={"passrate"} name = "data2Param">Checkride Pass Rate</MenuItem>
                                                                    <MenuItem value={"stagePassRate"} name = "data2Param">Stage Pass Rate</MenuItem>
                                                                    <MenuItem value={"threshold"} name = "data2Param">Threshold</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs = {3}>
                                                                <h3>Term</h3>
                                                                <Select id={"term"} value = {this.state.term} disabled = {false} onChange = {this.changeTerm} disabled>
                                                                    <MenuItem value={"pastYear"}>Past Year</MenuItem>
                                                                    <MenuItem value={"pastSixMonths"}>Past Six Months</MenuItem>
                                                                    <MenuItem value={"pastThreeMonths"}>Past Three Months</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs = {3}>
                                                                <h3>Frequency</h3>
                                                                <Select id={"frequency"} value = {this.state.frequency} disabled = {false} onChange = {this.changeFrequency} disabled>
                                                                    <MenuItem value={"month"}>Month</MenuItem>
                                                                    <MenuItem value={"week"}>Week</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                            











                        </Grid>
                    </Grid>
                    
                </Card>


              {this.state.chart}
            </Paper>


        )
    }
}

export default ReportGeneration