import React, { Component } from "react";
import * as firebase from "firebase";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'
import Moment from "moment";
import Button from '@material-ui/core/Button'
class OpsRequestObject extends Component {
    constructor(props){
        super(props)
        this.state = {
            course : props.course,
            endResTime : props.endResTime,
            endTime : props.endTime,
            instructor : props.instructor,
            startResTime : props.startResTime,
            startTime : props.startTime,
            student : props.student,
            unit : props.unit,
            id : props.id,
            collectionRef : props.collectionRef,
            resourceType : props.resourceType,
            selectAtTime : 0,
            refresh : props.refresh
        }
        this.submitOpsRequest = this.submitOpsRequest.bind(this)
        this.denyOpsRequest = this.denyOpsRequest.bind(this)
    }
    componentDidMount(){
        console.log(this.state.startTime.seconds)
    }
    submitOpsRequest(){
        let a = this
        let db = firebase.firestore()
        let d = new Date(this.state.startTime.seconds * 1000)
        db.collection('schedules').doc((d.getMonth() + 1) + "-" + d.getDate() + "-" + d.getFullYear()).collection('activities').doc(this.state.id).update({
            status : "Scheduled"
        }).then(function(){
            a.state.refresh()
        });

    }
    denyOpsRequest(){
        let db = firebase.firestore()
        let a = this
        let d = new Date(this.state.startTime.seconds * 1000)
        db.collection('schedules').doc((d.getMonth() + 1) + "-" + d.getDate() + "-" + d.getFullYear()).collection('activities').doc(this.state.id).update({
            status : "Ops-Request Cancelled"
        }).then(function(){
            a.state.refresh()
        });
        
    }
    render(){
        return(
            <TableRow>
                <TableCell>{this.state.student}</TableCell>
                <TableCell>{this.state.instructor}</TableCell>
                <TableCell>{Moment(this.state.startTime.seconds * 1000).format("HH:mm")}</TableCell>
                <TableCell>{Moment(this.state.startResTime.seconds * 1000).format("HH:mm")}</TableCell>
                <TableCell>{Moment(this.state.endResTime.seconds * 1000).format("HH:mm")}</TableCell>
                <TableCell>{Moment(this.state.endTime.seconds * 1000).format("HH:mm")}</TableCell>
                <TableCell>{this.state.resourceType}</TableCell>
                <TableCell><Button onClick = {this.submitOpsRequest} variant="contained" color="primary" >Approve</Button></TableCell>
                <TableCell><Button onClick = {this.denyOpsRequest} variant="contained" >Deny</Button></TableCell>
            </TableRow>
        )
    }
}

export default OpsRequestObject