import React, { Component } from "react";
import ResourceDayView from './resourceView'
import * as firebase from 'firebase';
class ResourceDayViewBuilder extends Component {
    constructor(props){
        super(props)
        this.state = {
            res : []
        }
    }
    componentWillMount(){
        var db = firebase.firestore();
        var docRef = db.collection("aircraft")
        let a = this
        var holder = []
        var ref = 0
        docRef.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                
                holder.push({id:ref,name:doc.id});
                ref = ref + 1
            });
        },a.setState({res : holder}));
        console.log(this.state.res)
    }
    render(){
        return(
            <div>
            <ResourceDayView res = {this.state.res}></ResourceDayView>
            </div>
        );
    }
}

export default ResourceDayViewBuilder