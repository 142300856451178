import React, { Component } from "react";
import * as firebase from 'firebase';
import Grid from '@material-ui/core/Grid'
import Clock from 'react-live-clock';


class LineStatusHeader extends Component {
    constructor(props){
        super(props)
        this.state = {
            lineStatus : "ERROR",
            statusColor : "#ff0000"
        }
    }
    componentWillMount(){
        let db = firebase.firestore()
        let a = this
        db.collection('lineOps').doc('status').onSnapshot(function(doc){
            a.setState({lineStatus : doc.data().flightStatus})
            if(doc.data().flightStatus == "GO"){
                a.setState({statusColor : '#17bf11'})
            }
            if(doc.data().flightStatus == "HOLD"){
                a.setState({statusColor : "#ff0000"})
            }
            if(doc.data().flightStatus == "DUAL ONLY" || doc.data().flightStatus == "LOCAL ONLY" || doc.data().flightStatus == "PATTERN ONLY"){
                a.setState({statusColor : "#f2ff00"})
            }
        })
    }

    render(){
        return(
              <h2 style = {{color : this.state.statusColor}} >Current Status : {this.state.lineStatus}</h2>
        )
    }
}

export default LineStatusHeader