import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import c172 from "./c172.png";
function Aircraft(props) {
  var type = props.type
  if(type != "c172" && type!="da42" && type!="8KCAB"){
    type = "Seer_logo"
  }
  return (
    <Card style={aircraftCardStyle}>
      <h1>{props.callsign}</h1>
      <img src={require("./" + type + ".png")} width="75%"></img>
      <h2>{props.formalName}</h2>
      <h2>{props.tailNumber}</h2>

      <h2>Empty Weight : {props.emptyWeight}</h2>
      <h2>Moment : {props.moment}</h2>
      <h2>CG : {props.CG}</h2>
      <h2>Useful Load : {props.usefulLoad}</h2>
      <h2>Gross Weight : {props.grossWeight}</h2>
    </Card>
  );
}

const aircraftCardStyle = {
  textAlign: "center",
  marginBottom: "50px",
  marginLeft: "5%",
  width: "300px",
};

export default Aircraft;
