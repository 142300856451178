import React, { Component } from "react";
import * as firebase from "firebase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import InstructorDayACViewActivity from "./instructorDayACViewActivity";
class InstructorDayACView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pulledActivites: [],
      updateHolder: "",
    };
    this.refreshPage = this.refreshPage.bind(this);
    this.refresh = this.refresh.bind(this);
  }
  refreshPage() {
    console.log("SHOULD BE REFRESHING");
  }
  componentWillMount() {
    this.setState({ pulledActivites: [] });
    let a = this;
    var db = firebase.firestore();
    let d = new Date();
    var monthHolder = d.getMonth();
    var dayHolder = d.getDate();
    var yearHolder = d.getFullYear();
    var docRef = db
      .collection("schedules")
      .doc(monthHolder + 1 + "-" + dayHolder + "-" + yearHolder)
      .collection("activities");
    console.log(monthHolder + 1 + "-" + (dayHolder + 1) + "-" + yearHolder);
    docRef
      .where("instructor", "==", "TEST_INSTRUCTOR").orderBy('startTime')
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          if(doc.data().status != "Ops-Request Cancelled"){
          var holder = a.state.pulledActivites;
          holder.push(
            <InstructorDayACViewActivity
              refresh={a.refresh}
              student={doc.data().student}
              instructor={doc.data().instructor}
              startTime={doc.data().startTime}
              stopTime={doc.data().endTime}
              resourceType={doc.data().resourceType}
              resource={doc.data().resource}
              status={doc.data().status}
              id={doc.id}
              course={doc.data().course}
              unit={doc.data().unit}
              hobbs={doc.data().acHobbsTime}
              startDate={doc.data.startTime}
            ></InstructorDayACViewActivity>
          );
          a.setState({ pulledActivites: holder });
        }
        });
      });
  }
  refresh() {
    this.setState({ pulledActivites: [] });
    let a = this;
    var db = firebase.firestore();
    let d = new Date();
    var monthHolder = d.getMonth();
    var dayHolder = d.getDate();
    var yearHolder = d.getFullYear();
    var docRef = db
      .collection("schedules")
      .doc(monthHolder + 1 + "-" + dayHolder + "-" + yearHolder)
      .collection("activities");
    console.log(monthHolder + 1 + "-" + (dayHolder + 1) + "-" + yearHolder);
    docRef
      .where("instructor", "==", "TEST_INSTRUCTOR").orderBy('startTime')
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          if(doc.data().status != "Ops-Request Cancelled"){
          var holder = a.state.pulledActivites;
          holder.push(
            <InstructorDayACViewActivity
              refresh={a.refresh}
              student={doc.data().student}
              instructor={doc.data().instructor}
              startTime={doc.data().startTime}
              stopTime={doc.data().endTime}
              resourceType={doc.data().resourceType}
              resource={doc.data().resource}
              status={doc.data().status}
              id={doc.id}
              course={doc.data().course}
              unit={doc.data().unit}
              hobbs={doc.data().acHobbsTime}
              startDate={doc.data.startTime}
            ></InstructorDayACViewActivity>
          );
          a.setState({ pulledActivites: holder });
          }
        });
      });
  }
  render() {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell>Student</TableCell>
            <TableCell>Course/Unit</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Resource</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Authorize</TableCell>
            <TableCell>Complete</TableCell>
          </TableHead>
          <TableBody>{this.state.pulledActivites}</TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default InstructorDayACView;
