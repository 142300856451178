import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

import DispatchResourceGraph from "./dispatchResourceGraph";
import DispatchResourceGraphical from './dispatchResourceGraphical'
import DispatchAircraftPlanner from './dispatchAircraftPlanner'
import PracticeAreaLiveMap from './practiceAreaLiveMap'
class DispatchGraphs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphSelection: "resourcePlanner",
      graphObj: <DispatchAircraftPlanner></DispatchAircraftPlanner>,
    };
    this.onGraphSlectChange = this.onGraphSlectChange.bind(this);
  }
  onGraphSlectChange(event) {
    this.setState({ graphSelection: event.target.value });
    if(event.target.value == "resourceTextual"){
      this.setState({graphObj : <DispatchResourceGraph></DispatchResourceGraph>})
    }
    if(event.target.value == "resourceGraphical"){
      this.setState({graphObj : <DispatchResourceGraphical></DispatchResourceGraphical>})
    }
    if(event.target.value == "resourcePlanner"){
      this.setState({graphObj : <DispatchAircraftPlanner></DispatchAircraftPlanner>})
    }
    if(event.target.value == "visualPractice"){
      this.setState({graphObj : <PracticeAreaLiveMap></PracticeAreaLiveMap>})
    }
  }
  render() {
    return (
      <div>
        <Card>
          <Grid container>
            <h4>Graph Select</h4>
            <Select
              value={this.state.graphSelection}
              onChange={this.onGraphSlectChange}
            >
              <MenuItem value={"resourceTextual"}>Resource Textual</MenuItem>
              <MenuItem value={"resourceGraphical"}>Resource Graphical</MenuItem>
              <MenuItem value={'resourcePlanner'}>Resource Planner</MenuItem>
              <MenuItem value={"visualPractice"}>Practice Area</MenuItem>
            </Select>
          </Grid>
        </Card>
        {this.state.graphObj}
      </div>
    );
  }
}

export default DispatchGraphs;
