import React, { Component } from "react";
import * as firebase from "firebase";
import Card from "@material-ui/core/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import DispatchResourceGraph from "./dispatchResourceGraph";
import { Chart } from "react-google-charts";
import moment from "moment";
class DispatchResourceGraphical extends Component {

    constructor(props) {
        super(props);
        this.state = {
          startTime: 530,
          endTime: 2330,
          tableHolder: [],
          selRes: "8KCAB",
          resStartTimes: [],
          resEndTimes: [],
          totalUpRes: 1,
          KCABTimes: [240],
          c172Times: [240],
          DA42Times: [240],
          c182Times: [240],
          t: [],
          timeInterval: 5,
          dataHolder : []
        };
        this.fillData = this.fillData.bind(this);
        this.fillArrays = this.fillArrays.bind(this);
        this.changeInterval = this.changeInterval.bind(this)
        this.refresh = this.refresh.bind(this)
      }
      componentWillMount() {
        let a = this;
        var KCAB = 0;
        var c172 = 0;
        var da42 = 0;
        var c182 = 0;
        let db = firebase.firestore();
        db.collection("aircraft")
          .where("aircraftType", "==", "8KCAB")
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              if (
                doc.data().isAssigned == false &&
                doc.data().isDowned == false &&
                doc.data().isRampedOut == false
              ) {
                KCAB = KCAB + 1;
              }
            });
          });
    
        db.collection("aircraft")
          .where("aircraftType", "==", "c172")
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              //  if(doc.data().isAssigned == false && doc.data().isDowned == false && doc.data().isRampedOut == false ){
              c172 = c172 + 1;
             // console.log(c172);
              // }
            });
          });
        db.collection("aircraft")
          .where("aircraftType", "==", "da42")
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              // if(doc.data().isAssigned == false && doc.data().isDowned == false && doc.data().isRampedOut == false ){
              da42 = da42 + 1;
              // }
            });
          });
    
        db.collection("aircraft")
          .where("aircraftType", "==", "c182")
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              //  if(doc.data().isAssigned == false && doc.data().isDowned == false && doc.data().isRampedOut == false ){
              c182 = c182 + 1;
              a.fillArrays(KCAB, c172, da42, c182);
              // }
            });
          });
      }
    
      refresh(){
        let a = this;
        var KCAB = 0;
        var c172 = 0;
        var da42 = 0;
        var c182 = 0;
        let db = firebase.firestore();
        db.collection("aircraft")
          .where("aircraftType", "==", "8KCAB")
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              if (
                doc.data().isAssigned == false &&
                doc.data().isDowned == false &&
                doc.data().isRampedOut == false
              ) {
                KCAB = KCAB + 1;
              }
            });
          });
    
        db.collection("aircraft")
          .where("aircraftType", "==", "c172")
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              //  if(doc.data().isAssigned == false && doc.data().isDowned == false && doc.data().isRampedOut == false ){
              c172 = c172 + 1;
             // console.log(c172);
              // }
            });
          });
        db.collection("aircraft")
          .where("aircraftType", "==", "da42")
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              // if(doc.data().isAssigned == false && doc.data().isDowned == false && doc.data().isRampedOut == false ){
              da42 = da42 + 1;
              // }
            });
          });
    
        db.collection("aircraft")
          .where("aircraftType", "==", "c182")
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              //  if(doc.data().isAssigned == false && doc.data().isDowned == false && doc.data().isRampedOut == false ){
              c182 = c182 + 1;
              a.fillArrays(KCAB, c172, da42, c182);
              // }
            });
          });
      }
    
      fillArrays(KCAB, c172, da42, c182) {
        var KCABHolder = this.state.KCABTimes;
        var c172Holder = this.state.c172Times;
        var DA42Holder = this.state.DA42Times;
        var c182Holder = this.state.c182Times;
        for (var i = 0; i < 241; i++) {
          console.log(i)
          KCABHolder[i] = KCAB;
          c172Holder[i] = c172;
          DA42Holder[i] = da42;
          c182Holder[i] = c182;
          if (i === 240) {
            console.log('setting states')
            this.setState({ KCABTimes: KCABHolder }, () => this.fillData());
            this.setState({ c172Times: c172Holder });
            this.setState({ DA42Times: DA42Holder });
            this.setState({ c182Times: c182Holder });
          }
        }
      }
      fillData() {
        console.log('filling data')
        let db = firebase.firestore();
        let d = new Date();
        let t = this;
        let s = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 5, 30);
        let e = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 30);
        let dayHolder = db
          .collection("schedules")
          .doc(d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear())
          .collection("activities");
        dayHolder
          .where("resourceType", "==", "8KCAB")
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              if (
                doc.data().status == "Scheduled" ||
                doc.data().status == "Checked-In" ||
                doc.data().status == "Ramped-Out" ||
                doc.data().status == "Authorized"
              ) {
                //Figure out how many six minute intervals between 0530 and the res time
                //this would be calculed by startTime-initalTime then dividing by six to get TT?
                let a = new Date(doc.data().startResTime.seconds * 1000);
                let z = new Date(doc.data().endResTime.seconds * 1000);
    
                let b = a.getHours() * 60 + a.getMinutes();
                let y = z.getHours() * 60 + z.getMinutes();
    
                if (doc.data().resourceType == "8KCAB") {
                  for (var c = b; c < y; c = c + 6) {
                    let h = t.state.KCABTimes;
                    h[c / 6] = h[c / 6] - 1;
                    t.setState({ KCABTimes: h });
                    t.establishTable();
                  }
                }
    
                if (doc.data().resourceType == "c172") {
                  for (var c = b; c < y; c = c + 6) {
                    let h = t.state.c172Times;
                    h[c / 6] = h[c / 6] - 1;
                    t.setState({ c172Times: h });
    
                    t.establishTable();
                  }
                }
    
                if (doc.data().resourceType == "da42") {
                  for (var c = b; c < y; c = c + 6) {
                    let h = t.state.DA42Times;
                    h[c / 6] = h[c / 6] - 1;
                    t.setState({ DA42Times: h });
    
                    t.establishTable();
                  }
                }
                if (doc.data().resourceType == "c182") {
                  for (var c = b; c < y; c = c + 6) {
                    let h = t.state.KCABTimes;
                    h[c / 6] = h[c / 6] - 1;
                    t.setState({ c182Times: h });
    
                    t.establishTable();
                  }
                }
              }
            });
          });
          t.establishTable();
      }
      establishTable() {
        console.log('establishing table')
        var holder = [];
    
        var KCABHolder = this.state.KCABTimes;
        var c172Holder = this.state.c172Times;
        var DA42Holder = this.state.DA42Times;
        var c182Holder = this.state.c182Times;
        let d = new Date();
        holder[0] = ['Time', 'C172', 'DA42', 'C182', '8KCAB']
        for (var i = 0; i < 241; i++) {
          console.log(i)
          if (i % this.state.timeInterval == 0 &&  i * 6 > d.getHours() * 60 + d.getMinutes()) {
            let w = new Date(d.getFullYear(), d.getMonth(), d.getDate());
            w.setMinutes(i * 6);
            /*
            holder.push(
              <TableRow>
                <TableCell>{moment(w).format("HH:mm")}</TableCell>
                <TableCell>{c172Holder[i]}</TableCell>
                <TableCell>{DA42Holder[i]}</TableCell>
                <TableCell>{c182Holder[i]}</TableCell>
                <TableCell>{KCABHolder[i]}</TableCell>
              </TableRow>
            );
            */
           holder.push([
                moment(w).format("HH:mm"),
                c172Holder[i],
                DA42Holder[i],
                c182Holder[i],
                KCABHolder[i]
           ])
           console.log(holder)

          }
          if (i === 240) {
            this.setState({t: holder});
          }
        }
      }
    
      changeInterval(event){
        this.setState({timeInterval : event.target.value}, () => this.refresh())
      }





    render(){
        return(
            <Chart
            width={'100%'}
            height={'750px'}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            /*
            data={[
              ['Time', 'C172', 'DA42', 'C182', '8KCAB'],
              ['2014', 1000, 400, 200, 100],
              ['2015', 1170, 460, 250, 100],
              ['2016', 660, 1120, 300, 100],
              ['2017', 1030, 540, 350, 100],
            ]}
            */
            data = {this.state.t}
            options={{
              // Material design options
              chart: {
                title: 'Resource Graphical',
                //subtitle: 'Sales, Expenses, and Profit: 2014-2017',
              },
            }}
           
          />
        )
    }
}

export default DispatchResourceGraphical