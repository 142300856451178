import React, { Component } from "react";
import * as firebase from "firebase";
import Card from "@material-ui/core/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import DispatchResourceGraph from "./dispatchResourceGraph";
import { Chart } from "react-google-charts";
import moment from "moment";
import { Menu } from "@material-ui/core";

class DispatchAircraftPlanner extends Component {
    constructor(props){
        super(props)
        this.state = {
            aircraftData : [],
            resourceType : 'c172'
        }
        this.changeResource = this.changeResource.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    componentWillMount(){
        var h = []
        h.push(["Aircraft", "Available", "Down", 'Assigned'])
        this.setState({aircraftData : h})

        let a = this
        let db = firebase.firestore()
        let docRef = db.collection('aircraft').where('aircraftType', '==', 'c172')
        docRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                if(doc.data().isDowned == false && doc.data().isAssigned == false){
                    var holder = a.state.aircraftData
                    holder.push([doc.id,(50 - (doc.data().nextEventTime - doc.data().tachTime)),0,0])
                    a.setState({aircraftData : holder})
                    console.log(holder)
                }
                if(doc.data().isDowned == true){
                    var holder = a.state.aircraftData
                    holder.push([doc.id,0,(50 - (doc.data().nextEventTime - doc.data().tachTime)),0])
                    a.setState({aircraftData : holder})
                }
                if(doc.data().isAssigned == true){
                    var holder = a.state.aircraftData
                    holder.push([doc.id,0,0,(50 - (doc.data().nextEventTime - doc.data().tachTime))])
                    a.setState({aircraftData : holder})
                }
            })
        })
        
    }
    refresh(){
        var h = []
        h.push(["Aircraft", "Available", "Down", 'Assigned'])
        this.setState({aircraftData : h})

        let a = this
        let db = firebase.firestore()
        let docRef = db.collection('aircraft').where('aircraftType', '==', this.state.resourceType)
        docRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                if(doc.data().isDowned == false && doc.data().isAssigned == false){
                    var holder = a.state.aircraftData
                    holder.push([doc.id,(50 - (doc.data().nextEventTime - doc.data().tachTime)),0,0])
                    a.setState({aircraftData : holder})
                    console.log(holder)
                }
                if(doc.data().isDowned == true){
                    var holder = a.state.aircraftData
                    holder.push([doc.id,0,(50 - (doc.data().nextEventTime - doc.data().tachTime)),0])
                    a.setState({aircraftData : holder})
                }
                if(doc.data().isAssigned == true){
                    var holder = a.state.aircraftData
                    holder.push([doc.id,0,0,(50 - (doc.data().nextEventTime - doc.data().tachTime))])
                    a.setState({aircraftData : holder})
                }
            })
        })
    }
    changeResource(event){
        this.setState({resourceType : event.target.value},() => this.refresh())
    }
  render() {
    return (
      <Card>
        <Grid container>
            <h3>Resource Type : </h3>
            <Select value = {this.state.resourceType} onChange = {this.changeResource}>
                <MenuItem value={'c172'}>C-172</MenuItem>
                <MenuItem value={'da42'}>DA-42</MenuItem>
                <MenuItem value={'c182'}>C-182</MenuItem>
                <MenuItem value={'8KCAB'}>8KCAB</MenuItem>
            </Select>
        </Grid>
        <Chart
          width={"100%"}
          height={"500px"}
          chartType="Bar"
          loader={<div>Loading Chart</div>}
          /*
          data={[
            ["Aircraft", "Available", "Down", 'Assigned'],
            ["R-91", 0, 25, 0],
            ["R-01", 25, 0, 0],
            ["2016", 0, 0, 0],
            ["2017", 0, 0, 0],
          ]}
          */
        
          options={{
              
            hAxis: {
                maxValue: 50,
              },
            // Material design options
            
           
          }}
          data={this.state.aircraftData}
          // For tests
          rootProps={{ "data-testid": "2" }}
        />
      </Card>
    );
  }
}

export default DispatchAircraftPlanner;
