import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import ActivityView from "./activityView";
import * as firebase from "firebase";
import Moment from "moment";
const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
});
//TODO : Sort Algorithm
class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activites: [],
    };
  }
  componentWillMount() {
    let userName = firebase.auth().currentUser.displayName
    console.log(userName)
    var db = firebase.firestore();
    var docRef = db.collection("schedules");
    let a = this;
    let d = new Date();
    var monthHolder = d.getMonth();
    var dayHolder = d.getDate();
    var yearHolder = d.getFullYear();
    var numOfDays = 7;
    console.log("GETTING DOC DATA FOR");
    console.log(dayHolder + 1 + "-" + monthHolder + "-" + yearHolder);
    for (var i = 0; i < numOfDays; i++) {
      console.log(monthHolder + i);
      //NEED TO ACCOUNT FOR LEAP YEAR
      if (
        dayHolder + i > 31 ||
        ((monthHolder == 3 || 5 || 10) && dayHolder + i > 30) ||
        (monthHolder == 1 && dayHolder + i > 28)
      ) {
        dayHolder = 0;
        console.log("MONTH HOLDER IS : " + monthHolder);
        monthHolder = d.getMonth() + 1;
      }
      var docRef = db
        .collection("schedules")
        .doc(monthHolder + 1 + "-" + (dayHolder + i) + "-" + yearHolder)
        .collection("activities");
      
      docRef
        .where("student", "==", userName).orderBy('startTime')
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            if(doc.data().status != "Ops-Request Cancelled" && doc.data().status != "Cancelled"){
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let holder = a.state.activites;
            let t = doc.data().startTime;
            console.log(doc.data().startTime["seconds"]);
            var s = new Date(doc.data().startTime["seconds"] * 1000);
            var e = new Date(doc.data().endTime["seconds"] * 1000);
            console.log(e);
            holder.push(
              <ActivityView
                activityUnitInfo={doc.data().course + " " + doc.data().unit}
                status={doc.data().status}
                personell={doc.data().instructor}
                date={
                  s.getMonth() + 1 + "/" + s.getDate() + "/" + s.getFullYear()
                }
                startTime={Moment(s).format("HH:mm")}
                stopTime={Moment(e).format("HH:mm")}
                res={doc.data().resource}
              ></ActivityView>
            );

            a.setState({ activites: holder });
            }
          });
        });
    }
  }
  render() {
    return <div>{this.state.activites}</div>;
  }
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);
