import React, { Component } from "react";
import * as firebase from "firebase";
import seerLogo from "./Seer_logo.png";
import * as serviceWorker from '../serviceWorker';
import Navigator from '../componets/navigation'
import Hook from '../componets/test'
import Paperbase from './Paperbase'
import Paperbase2 from './Paperbase2'
import {BrowserRouter as Router} from 'react-router-dom'
import Card from '@material-ui/core/CardActions'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import 'firebase/auth';
import 'firebase/firestore';

class Login extends Component {
    constructor(props){
        super(props)
        this.state = {
            user : '',
            pass : ''
        }
        this.loginButtonPressed = this.loginButtonPressed.bind(this)
        this.onTextChanged = this.onTextChanged.bind(this)
    }
    loginButtonPressed(){
        firebase.auth().signInWithEmailAndPassword(this.state.user, this.state.pass).catch(function(error){
            var errorCode = error.errorCode
            var errorMessage = error.errorMessage
            alert("Login Error")
        })
    }
    onTextChanged(event){
        this.setState({[event.target.name] : event.target.value})
        console.log(event.target.name + " " + event.target.value)
    }
    render(){
        return(
            <Card style = {{width : '100%', height : '100%'}}>
                <Card style = {{alignItems : 'center', marginLeft : '42%', width : '250px', marginTop : '10%'}}>
                    <Grid container>
                    <Grid item xs={12}>
                    <img src={seerLogo} width="240"></img>
                    </Grid>
                    <Grid item xs={12}>
                    <TextField name = "user" label = "User ID" style = {{width : '100%'}} onChange = {this.onTextChanged}></TextField>
                    </Grid>
                    <Grid item xs={12}>
                    <TextField name = "pass" label = "Password" type="password" style = {{width : '100%'}} onChange = {this.onTextChanged}></TextField>
                    </Grid>
                    <Grid  item xs={12}>
                        <Button style = {{width : '100%'}} onClick = {this.loginButtonPressed}>Submit</Button>
                    </Grid>
                    </Grid>
                </Card>
            </Card>
        )
    }
}

export default Login