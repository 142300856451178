import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import * as firebase from "firebase";
var workingData = [];
class DispatchActivityView extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    let d = new Date();
    var db = firebase.firestore();
    var monthHolder = d.getDate();
    var dayHolder = d.getMonth();
    var yearHolder = d.getFullYear();
    var numOfDays = 7;
    console.log("GETTING DOC DATA FOR");
    console.log(dayHolder + 1 + "-" + monthHolder + "-" + yearHolder);

    var acRef = db
      .collection("schedules")
      .doc(dayHolder + 1 + "-" + monthHolder + "-" + yearHolder)
      .collection("activities");
    acRef.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        var s = new Date(doc.data().startTime["seconds"] * 1000);
        var e = new Date(doc.data().endTime["seconds"] * 1000);
        console.log(s);
        workingData.push({
          startDate: s,
          endDate: e,
          student: doc.data().student,
          courseId: doc.data().course,
          unitId: doc.data().unit,
          resourcetId: doc.data().resourceType,
          startBreifTime: doc.data().startBreifTime,
          endBreifTime: doc.data().endBreifTime,
          instructor: doc.data().instructor,
          status: doc.data().status,
        });
        console.log("WORKING DATA:");
        console.log(workingData);
      });
    });
  }
  render() {
    return (
      <Card>
        <h1>test</h1>
      </Card>
    );
  }
}

export default DispatchActivityView;
