import React, { Component } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button'
import * as firebase from "firebase";
import Moment from 'react-moment'
class SchedulingActivityRow extends Component {
    constructor(props){
        super(props)
        this.state = {
            instructor : props.instructor,
            student : props.student,
            startTime : props.startTime,
            endTime : props.endTime,
            course : props.course,
            unit : props.unit,
            docId : props.id
        }
    }
    render(){
        return(<TableRow>
            <TableCell>{this.state.instructor}</TableCell>
            <TableCell>{this.state.student}</TableCell>
            <TableCell><Moment format = "HH:mm">{this.state.startTime*1000}</Moment></TableCell>
            <TableCell><Moment format = "HH:mm">{this.state.endTime*1000}</Moment></TableCell>
            <TableCell>{this.state.course}</TableCell>
            <TableCell>{this.state.unit}</TableCell>
            <TableCell><Button variant = "contained">Edit</Button></TableCell>
        </TableRow>)
    }
}

export default SchedulingActivityRow