import React, { Component } from "react";
import * as firebase from "firebase";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button'
import PaymentScreen from "./paymentScreen";
class OpenCashiers extends Component {
    constructor(props){
        super(props)
        this.state = {
            user : "reesj4",
            cashiers : []
        }
    }
    componentDidMount(){
        let a = this
        let db = firebase.firestore()
        var open = []
        db.collection('users').doc(this.state.user).collection('cashiers').get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                if(doc.data().paid == false){
                    open.push(
                        <PaymentScreen date = {doc.data().date} course = {doc.data().course} unit = {doc.data().unit} instructor = {doc.data().instructor} aircraftMake = {doc.data().aircraftMake} durationOfFlight = {doc.data().durationOfFlight} hobbsRate = {doc.data().hobbsRate} oralRate = {doc.data().oralRate} oralTime = {doc.data().oralTime} paid = {doc.data().paid} totalActivityCost = {doc.data().totalActivityCost}></PaymentScreen>
                    )
                    a.setState({cashiers : open})
                }
            })
        })
    }
    render(){
        return(
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Coruse</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell>Instructor</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.cashiers}
                    </TableBody>
                </Table>
            </TableContainer>


        )
    }
}

export default OpenCashiers