import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import * as firebase from "firebase";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CouseCreateContainer from "./courseCreateConatiner";
class CouseCreateHeader extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.createCourse = this.createCourse.bind(this);
    this.getDataBack = this.getDataBack.bind(this);
    this.createCourse = this.createCourse.bind(this);
    this.state = {
      units: 0,
      courseComplete: [],
    };
  }
  handleInputChange(event) {
    if (event.target.id == "courseName" || event.target.id == "units") {
      const target = event.target;
      const value = target.value;
      const name = target.id;
      this.setState({ [name]: value });
    }
  }
  createCourse() {
    console.log("did create course:" + "Units : " + this.state.units);
    console.log(this.state.courseComplete);
    console.log(this.state.courseComplete[0]);
    console.log(this.state.courseComplete[0][0][1]);
    console.log("BREAK");
    console.log(this.state.courseComplete[0][0][1][1]);
    var db = firebase.firestore();
    console.log("Course Complete Length : " + this.state.courseComplete.length);
    for (var i = 0; i < this.state.courseComplete.length; i++) {
      db.collection("courses")
        .doc(this.state.courseName)
        .collection(i + this.state.courseComplete[i][0][0])
        .doc("info")
        .set({
          type: this.state.courseComplete[i][1],
        });
      for (var j = 0; j < this.state.courseComplete[i][0][1].length; j++) {
        console.log("J IS :");
        console.log(j);
        console.log(this.state.courseComplete[i][0][1][j][0].length);
        for (var k = 0; k < this.state.courseComplete[i][0][1][j].length; k++) {
          db.collection("courses")
            .doc(this.state.courseName)
            .collection(i + this.state.courseComplete[i][0][0])
            .doc(k + this.state.courseComplete[i][0][1][j][k][0])
            .set({
              isGraded: this.state.courseComplete[i][0][1][j][k][1],
            });
        }
      }
    }
  }

  getDataBack(course) {
    this.setState({ courseComplete: course });
  }
  render() {
    return (
      <dvi>
        <Card>
          <Grid container>
            <Grid xs={3}>
              <TextField
                style={courseNameStyle}
                onChange={this.handleInputChange}
                id="courseName"
                label="Course Name"
                variant="outlined"
              ></TextField>
            </Grid>
            <Grid xs={3}>
              <TextField
                style={courseNameStyle}
                onChange={this.handleInputChange}
                id="units"
                label="Course Units"
                variant="outlined"
              ></TextField>
            </Grid>
            <Grid xs={3}>
              <Button
                style={generateCourseButtonStyle}
                variant="contained"
                color="primary"
                size="large"
                onClick={this.createCourse}
              >
                Generate Course
              </Button>
            </Grid>
          </Grid>
        </Card>
        <CouseCreateContainer
          units={this.state.units}
          pushback={this.getDataBack}
        ></CouseCreateContainer>
      </dvi>
    );
  }
}
const courseNameStyle = {
  marginTop: "10px",
  align: "left",
  marginBottom: "10px",
};
const courseUnitStyle = {
  marginTop: "10px",
  align: "center",
  marginBottom: "10px",
};
const generateCourseButtonStyle = {
  marginTop: "12px",
  align: "left",
  marginBottom: "10px",
};
export default CouseCreateHeader;
