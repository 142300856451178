import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import * as firebase from "firebase";
import Button from "@material-ui/core/Button";
import { CSVReader } from "react-papaparse";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CourseAdminUnit from './courseAdminUnit'
class CourseAdminCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseName: this.props.courseName,
      download: this.props.download,
      units : []
    };
    this.populateUnits = this.populateUnits.bind(this)
  }
  
  componentDidMount(){
    let db = firebase.firestore()
    let a = this
    db.collection('courses').doc(this.state.courseName).get().then(function(doc){
      for(var i = 0; i < doc.data().lessons.length; i++){
        var holder = a.state.units
        holder.push(<CourseAdminUnit unit = {doc.data().lessons[i]} course = {a.state.courseName}></CourseAdminUnit>)
        a.setState({units : holder})
      }
    })
  }
  populateUnits(){

  }
  /*
  <Card style = {{marginTop : '25px'}}>
        <Grid container>
        <ExpansionPanel>
          <h1>{this.state.courseName}</h1>
          <Button>Import</Button>
          <Button>Download</Button>
            {this.state.units}
          </ExpansionPanel>
        </Grid>
      </Card>
  */
  render() {
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
            {this.state.courseName}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div>
              {this.state.units}
            </div>
          </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default CourseAdminCourse;
