import React, { Component } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button'
import * as firebase from "firebase";
class InstructorOutlook extends Component {
    constructor(props){
        super(props)
        this.state = {
            activities : []
        }
        this.returnMonth = this.returnMonth.bind(this)
    }
    componentDidMount(){
        let a = this
        let db = firebase.firestore()
        let activities = []
        for(var i = 0; i < 7; i = i+1){
            let d = new Date()
            d.setDate(d.getDate()+i)
            var docRef = db.collection('schedules').doc((d.getMonth()+1)+'-'+d.getDate()+'-'+d.getFullYear()).collection('activities').where('instructor', '==', 'TEST_INSTRUCTOR').orderBy('startTime')
            docRef.get().then(function(querySnapshot){
                querySnapshot.forEach(function(doc){
                    let startTime = new Date(doc.data().startTime["seconds"] * 1000)
                    let endTime = new Date(doc.data().endTime["seconds"] * 1000)
                    if(doc.data().status == "Scheduling" || doc.data().status == 'Pre-Post'){
                    activities.push(<TableRow>
                        <TableCell>PRE-POST</TableCell>
                        <TableCell>{startTime.getDate()+" "+a.returnMonth(startTime.getMonth())+ " " + startTime.getHours() + ':' + startTime.getMinutes()}</TableCell>
                        <TableCell>{endTime.getDate()+" "+a.returnMonth(endTime.getMonth())+ " " + endTime.getHours() + ':' + endTime.getMinutes()}</TableCell>
                        <TableCell>{doc.data().status}</TableCell>
                        <TableCell>{doc.data().resourceType}</TableCell>
                        <TableCell>{doc.data().resource}</TableCell>
                        <TableCell>{doc.data().course}</TableCell>
                        <TableCell>{doc.data().unit}</TableCell>
                        <TableCell>{doc.data().instructor}</TableCell>
                        <TableCell>{doc.data().student}</TableCell>
                        <TableCell></TableCell>
                        </TableRow>)
                    }else{
                        activities.push(<TableRow>
                            <TableCell><Button variant = "contained" disabled={!(doc.data().status == "Scheduled" || doc.data().status == "Checked-In")}>AA</Button><Button style = {{marginLeft : '1%'}} variant = "contained" disabled={!(doc.data().status == "Ramped-In" || doc.data().status == "Authorized")}>AC</Button></TableCell>
                            <TableCell>{startTime.getDate()+" "+a.returnMonth(startTime.getMonth())+ " " + startTime.getHours() + ':' + startTime.getMinutes()}</TableCell>
                            <TableCell>{endTime.getDate()+" "+a.returnMonth(endTime.getMonth())+ " " + endTime.getHours() + ':' + endTime.getMinutes()}</TableCell>
                            <TableCell>{doc.data().status}</TableCell>
                            <TableCell>{doc.data().resourceType}</TableCell>
                            <TableCell>{doc.data().resource}</TableCell>
                            <TableCell>{doc.data().course}</TableCell>
                            <TableCell>{doc.data().unit}</TableCell>
                            <TableCell>{doc.data().instructor}</TableCell>
                            <TableCell>{doc.data().student}</TableCell>
                            <TableCell></TableCell>
                            </TableRow>)
                    }
                    a.setState({activities : activities})
                })
            })
        }

    }
    returnMonth(month){
        if(month == 0){
            return 'JAN'
        }
        if(month == 1){
            return 'FEB'
        }
        if(month == 2){
            return 'MAR'
        }
        if(month == 3){
            return 'APR'
        }
        if(month == 4){
            return 'MAY'
        }
        if(month == 5){
            return 'JUN'
        }
        if(month == 6){
            return 'JUL'
        }
        if(month == 7){
            return 'AUG'
        }
        if(month == 8){
            return 'SEP'
        }
        if(month == 9){
            return 'OCT'
        }
        if(month == 10){
            return 'NOV'
        }
        if(month == 11){
            return 'DEC'
        }
    }
    render(){
        return(
        <TableContainer component = {Paper}>
            <Table>
            <TableHead >
                <TableRow>
                <TableCell></TableCell>
                <TableCell>Start</TableCell>
                <TableCell>Stop</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Res</TableCell>
                <TableCell>Course</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Inst/PIC</TableCell>
                <TableCell>Students</TableCell>
                <TableCell>Obs</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {this.state.activities}
            </TableBody>
            </Table>
        </TableContainer>)
    }
}

export default InstructorOutlook