import React, { Component } from "react";
import * as firebase from "firebase";
import Card from '@material-ui/core/Card'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
class MyIssues extends Component {
    constructor(props){
        super(props)
        this.state = {
            pqTable : [],
            instructor : [],
            ratings : [],
            hasNoShows : false,
            noShows : []

        }
    }
    componentDidMount(){
        let a = this 
        let db = firebase.firestore()
        //db.collection('users').doc(firebase.auth().currentUser)
        console.log(firebase.auth().currentUser)
        let user = firebase.auth().currentUser.displayName
        
        db.collection('users').doc(user).collection('PQ').get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                var holder = a.state.pqTable
                holder.push(<TableRow>
                                <TableCell>{Moment(doc.data().issued.seconds * 1000).format("MM-DD-YYYY")}</TableCell>
                                <TableCell>{Moment(doc.data().expiration.seconds * 1000).format("MM-DD-YYYY")}</TableCell>
                                <TableCell>{Moment(doc.data().expiration.seconds * 1000).fromNow()}</TableCell>
                                <TableCell>{doc.data().totalWind}</TableCell>
                                <TableCell>{doc.data().xWind}</TableCell>
                                <TableCell>{doc.data().deIce}</TableCell>
                            </TableRow>)
                a.setState({pqTable : holder})
            })
        })
        db.collection('users').doc(user).get().then(function(doc){
            let holder = a.state.instructor
            holder.push(<TableRow>
                            <TableCell>{doc.data().instructor}</TableCell>
                            <TableCell>{doc.data().course}</TableCell>
                            <TableCell>{doc.data().team}</TableCell>
                        </TableRow>)
            a.setState({instructor : holder})
        })
        db.collection('users').doc(user).collection('noShows').get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                if(doc.data().status == 'Open'){
                    a.setState({hasNoShows : true})
                    var holder = a.state.noShows
                    holder.push(<TableRow>
                                    <TableCell>{Moment(doc.data().date.seconds * 1000).format("MM-DD-YYYY")}</TableCell>
                                    <TableCell>{doc.data().status}</TableCell>
                                </TableRow>)
                    a.setState({noShows : holder})
                }
            })
        })
    }
    render(){
        return(
            <div>
                 <Card style = {{textAlign : "center"}} hidden = {!this.state.hasNoShows}>
                    <h3>My No Shows</h3>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.noShows}
                        </TableBody>
                    </Table>
                </Card>

                <Card style = {{marginTop : '25px', textAlign : "center"}}>
                    <h3>My PQ</h3>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Issue Date
                                </TableCell>
                                <TableCell>
                                    Expiration Date
                                </TableCell>
                                <TableCell>
                                    Expires
                                </TableCell>
                                <TableCell>
                                    Max Wind
                                </TableCell>
                                <TableCell>
                                    Max Cross Wind
                                </TableCell>
                                <TableCell>
                                    De-Ice
                                </TableCell>
                            </TableRow>          
                            
                        </TableHead>
                        <TableBody>
                                {this.state.pqTable}
                        </TableBody>  
                    </Table>
                </Card>
                <Card style = {{marginTop : '25px', textAlign : "center"}}>
                    <h3>My Instructors</h3>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Name
                                </TableCell>
                                <TableCell>
                                    Course
                                </TableCell>
                                <TableCell>
                                    Team
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.instructor}
                        </TableBody>
                    </Table>
                </Card>
                <Card style = {{marginTop : '25px', textAlign : "center"}}>
                    <h3>My Issues</h3>
                </Card>
                <Card style = {{marginTop : '25px', textAlign : "center"}}>
                    <h3>My Ratings</h3>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Issuance Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                        </TableBody>
                    </Table>
                </Card>
               
            </div>
        )
    }
}
export default MyIssues