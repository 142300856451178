import React from "react";
import PropTypes from "prop-types";
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Navigator from "./Navigator";
import Content from "./Content";
import Header from "./Header";
import RouteNav from "./Router";
import Navigator2 from "./navigator2";
import Logbook from "./logbook";
import AircraftDraw from "./aircraftDataMainView";
import AircraftDrawAdmin from "./aircraftDataAdminView";
import CouseCreateHeader from "./courseCreateHeader";
import ResourceDayViewBuilder from "./resourceViewBuilder";
import ActivityScheduler from "./activityScheduler";
import CourseAdmin from "./courseAdmin";
import DispatchContainerView from "./dispatchContainerView";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DispatcherView from "./dispatcherView";
import InstructorDayACView from "./instructorDayACView";
import HomeBase from "./homeBase";
import FlightSupervisor from "./flightSupervisor";
import AircraftStatus from "./aircraftStatus";
import AircraftFlightSup from "./aircraftFlightSupManager";
import DispatchGraphs from "./dispatchGraphs";
import MyIssues from './myIssues'
import DispatchDragableBeta from './dispatchDragableBeta'
import LinearScheduler from './linearScheduler'
import Payroll from './payroll'
import MyStudents from './myStudents'
import OpenCashiers from "./openCashiers";
import InstructorOutlook from "./instructorOutlook";
import ActivityList from "./activityList";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import DayScheduler from './dayScheduler'
import ReportGeneration from "./reportGeneration";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Seer Flight Systems, LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#00549a",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 256;

const styles = {
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
  footer: {
    padding: theme.spacing(2),
    background: "#eaeff1",
  },
};

function Paperbase2(props) {
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          <Hidden smUp implementation="js">
            <Navigator2
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator2 PaperProps={{ style: { width: drawerWidth } }} />
          </Hidden>
        </nav>
        <div className={classes.app}>
          <Switch>
            <Route path="/schedule/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="My Schedule"
              />
            </Route>
            <Route path="/logbook/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="My Logbook"
              />
            </Route>
            <Route path="/aircraft/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="Aircraft"
              />
            </Route>
            <Route path="/aircraftAdmin/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="Aircraft Admin"
              />
            </Route>
            <Route path="/createCourse/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="Course Creation"
              />
            </Route>
            <Route path="/courseAdmin/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="Course Admin"
              />
            </Route>
            <Route path="/schedulerDayView/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="Resource Day View"
              />
            </Route>
            <Route path="/activityScheduler/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="Activity Scheduler"
              />
            </Route>
            <Route path="/dispatcherView/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="Dispatch"
              />
            </Route>
            <Route path="/instructorDayACView/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="My Day"
              />
            </Route>
            <Route path="/homeBase/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="Home Base"
              />
            </Route>
            <Route path="/flightSupervisor/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="Flight Supervisor"
              />
            </Route>
            <Route path="/aircraftStatus/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="Aircraft Status"
              />
            </Route>
            <Route path="/aircraftFlightSup/">
              <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="Aircraft Status"
              />
            </Route>
            <Route path = '/myIssues'>
            <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="My Issues"
              />
            </Route>
            <Route path = '/dayScheduler'>
            <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="Graphical Scheduler"
              />
            </Route>
            <Route path = '/mypayroll'>
              <Header onDrawerToggle = {handleDrawerToggle} headerTitle = "Payroll"></Header>
            </Route>
            <Route path = '/myStudents'>
              <Header onDrawerToggle = {handleDrawerToggle} headerTitle = "My Students"></Header>
            </Route>
            <Route path = '/openCashiers'>
              <Header onDrawerToggle = {handleDrawerToggle} headerTitle = "My Open Cashiers"></Header>
            </Route>
            <Route path = '/instructorOutlook'>
              <Header onDrawerToggle = {handleDrawerToggle} headerTitle = "My Outlook"></Header>
            </Route>
            <Route path = '/activityList'>
              <Header onDrawerToggle = {handleDrawerToggle} headerTitle = "Activity List"></Header>
            </Route>
            <Route path = '/reports/'>
              <Header onDrawerToggle = {handleDrawerToggle} headerTitle = "Report Generator"></Header>
            </Route>
            <Route path = '/'>
            <Header
                onDrawerToggle={handleDrawerToggle}
                headerTitle="My Schedule"
              />
            </Route>
            
            
          </Switch>

          <main className={classes.main}>
            <Switch>
              <Route path="/schedule/">
                <Content />
              </Route>
              <Route path="/logbook/">
                <Logbook />
              </Route>
              <Route path="/aircraft/">
                <AircraftDraw></AircraftDraw>
              </Route>
              <Route path="/aircraftAdmin/">
                <AircraftDrawAdmin></AircraftDrawAdmin>
              </Route>
              <Route path="/createCourse/">
                <CouseCreateHeader></CouseCreateHeader>
              </Route>
              <Route path="/courseAdmin/">
                <CourseAdmin></CourseAdmin>
              </Route>
              <Route path="/schedulerDayView/">
                <DispatchContainerView></DispatchContainerView>
              </Route>
              <Route path="/activityScheduler/">
                <LinearScheduler></LinearScheduler>
              </Route>
              <Route path="/dispatcherView/">
                <DispatcherView></DispatcherView>
              </Route>
              <Route path="/instructorDayACView/">
                <InstructorDayACView></InstructorDayACView>
              </Route>
              <Route path="/homeBase/">
                <HomeBase></HomeBase>
              </Route>
              <Route path="/flightSupervisor">
                <FlightSupervisor></FlightSupervisor>
              </Route>
              <Route path="/aircraftStatus/">
                <AircraftStatus></AircraftStatus>
              </Route>
              <Route path="/aircraftFlightSup/">
                <AircraftFlightSup></AircraftFlightSup>
              </Route>
              <Route path="/dispatchGraphs/">
                <DispatchGraphs></DispatchGraphs>
              </Route>
              <Route path = '/myIssues/'>
                <MyIssues></MyIssues>
              </Route>
              <Route path = '/dispatchBeta/'>
                <DispatchDragableBeta></DispatchDragableBeta>
              </Route>
              <Route path = '/dayScheduler/'>
                <DayScheduler></DayScheduler>
              </Route>
              <Route path = '/mypayroll/'>
                <Payroll/>
              </Route>
              <Route path = '/mystudents/'>
                <MyStudents/>
              </Route>
              <Route path = '/openCashiers'>
                <OpenCashiers/>
              </Route>
              <Route path = '/instructorOutlook'>
                <InstructorOutlook></InstructorOutlook>
              </Route>
              <Route path = '/activityList'>
                <ActivityList></ActivityList>
              </Route>
              <Route path = '/reports'>
                <ReportGeneration></ReportGeneration>
              </Route>
              <Route path="/">
                <Content></Content>
              </Route>
            
            </Switch>
          </main>
          <footer className={classes.footer}>
            <Copyright />
          </footer>
        </div>
      </div>
    </ThemeProvider>
  );
}

Paperbase2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Paperbase2);
