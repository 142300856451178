import React, { Component } from "react";
import * as firebase from "firebase";
import Card from "@material-ui/core/Card";
import Divider from '@material-ui/core/Divider'
import ListItem from "@material-ui/core/ListItem";
import List from '@material-ui/core/List'
import InstructorActivityGrade from './InstructorActivityGrade'
class GradeSheet extends Component {
    constructor(props){
        super(props)
        this.state = {
            student : props.student,
            course : props.course,
            unit : props.unit,
            actUnits : []
        }
    }
    componentDidMount(){
        let a = this
        let db = firebase.firestore()
        var unitHold = []
        let previousUnitRef = db.collection('users').doc(this.state.student).collection('logbook').where('unit','==',this.state.unit)
        previousUnitRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                if(doc.data().course == a.state.course){
                    let lineItemDocRef = db.collection('users').doc(a.state.student).collection('logbook').doc(doc.id).collection('lineItems')
                    var count = 0
                    lineItemDocRef.get().then(function(lineItemSnapshot){
                        lineItemSnapshot.forEach(function(lineDoc){
                            console.log(count + ' ' + unitHold)
                            count = count + 1
                            var graded = true
                            if(lineDoc.data().grade == 'I' || lineDoc.data().grade == 'C'){
                                graded = false
                            }
                            unitHold.push( <ListItem>
                                <InstructorActivityGrade
                                  activityObjective={lineDoc.id}
                                  activityCI={graded}
                                  order={lineDoc.data().order}
                                  //update={a.updateUnitGrades}
                                  previousGrade = {lineDoc.data().grade}
                                  disableCheck = {true}
                                  spacer = {' - '}
                                  comment = {lineDoc.data().comment}
                                ></InstructorActivityGrade>
                              </ListItem>
                            )
                            if(count == lineItemSnapshot.size){
                                a.setState({actUnits : unitHold})
                            }
                        })
                    })
                }
            })
            if(querySnapshot.size == 0){
                a.getGradeSheet()
            }
        })
    }
    getGradeSheet(){
        let db = firebase.firestore()
        let a = this
        var unitHold = []
        let unitRef = db.collection('courses').doc(this.state.course).collection(this.state.unit)
        unitRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                if(doc.id != 'info'){
                unitHold.push( <ListItem>
                    <InstructorActivityGrade
                      activityObjective={doc.id}
                      activityCI={doc.data().isGraded}
                      order={doc.data().order}
                      //update={a.updateUnitGrades}
                      disableCheck = {true}
                    ></InstructorActivityGrade>
                  </ListItem>)
                }
            })
            a.setState({actUnits : unitHold})
        })
    }
    render(){
        return(
        <Card style={{width: "75%",align: "center",marginLeft: "20%"}}>
            <h1>Grade Sheet - {this.state.unit}</h1>    
            <Divider></Divider>
            <List
                style={{width: "100%", overflow: "auto" }}
              >
                {this.state.actUnits}
              </List>
        </Card>
        )
    }
}

export default GradeSheet


