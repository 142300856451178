import React, { Component } from "react";
import * as firebase from "firebase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import DispatcherActivityView from "./dispatcherActivityView";

class DispatcherView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pulledActivites: [],
      pulledAricraft: [],
      acitivtyFilter: ["Scheduled", "Checked-In", "Authorized", "Ramped-Out"],
    };
    this.refresh = this.refresh.bind(this);
    this.sortActivities = this.sortActivities.bind(this);
    this.doPullRefresh = this.doPullRefresh.bind(this);
  }
  componentWillMount() {
    this.doPullRefresh();





    /*
        let a = this 
        var db = firebase.firestore();
        let d = new Date()
        this.setState({pulledActivites : []}, () => this.doPullRefresh())
        var monthHolder = d.getMonth()
        var dayHolder = d.getDate()
        var yearHolder = d.getFullYear()
        var docRef = db.collection("schedules").doc((monthHolder+1)  + "-" + (dayHolder) + "-" + yearHolder).collection("activities")
        console.log((monthHolder+1)  + "-" + (dayHolder) + "-" + yearHolder)
        */
    /*
        docRef.where("status", "==", "Scheduled").get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                var holder = a.state.pulledActivites
                holder.push(<DispatcherActivityView  dueback = {doc.data().endResTime} startResTime = {doc.data().startResTime} refresh = {a.refresh} destination = {doc.data().destination} dispatcherComment = {doc.data().dispatcherComment}  student={doc.data().student} instructor={doc.data().instructor} startTime={doc.data().startTime} stopTime={doc.data().endTime} resourceType = {doc.data().resourceType} resource = {doc.data().resource} status = {doc.data().status} id={doc.id} course = {doc.data().course} unit = {doc.data().unit} hobbs = {doc.data().hobbs}></DispatcherActivityView>)
                a.setState({pulledActivites : holder})
                
            });
        });
        docRef.where("status", "==", "Authorized").get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                var holder = a.state.pulledActivites
                 holder.push(<DispatcherActivityView  dueback = {doc.data().endResTime} startResTime = {doc.data().startResTime} refresh = {a.refresh} destination = {doc.data().destination} dispatcherComment = {doc.data().dispatcherComment} student={doc.data().student} instructor={doc.data().instructor} startTime={doc.data().startTime} stopTime={doc.data().endTime} resourceType = {doc.data().resourceType} resource = {doc.data().resource} status = {doc.data().status} id={doc.id}></DispatcherActivityView>)
                a.setState({pulledActivites : holder})
                
            });
        });
        docRef.where("status", "==", "Checked-In").get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                var holder = a.state.pulledActivites
                 holder.push(<DispatcherActivityView  dueback = {doc.data().endResTime} startResTime = {doc.data().startResTime} refresh = {a.refresh} destination = {doc.data().destination} dispatcherComment = {doc.data().dispatcherComment} student={doc.data().student} instructor={doc.data().instructor} startTime={doc.data().startTime} stopTime={doc.data().endTime} resourceType = {doc.data().resourceType} resource = {doc.data().resource} status = {doc.data().status} id={doc.id}></DispatcherActivityView>)
                a.setState({pulledActivites : holder})
                
            });
        });
        docRef.where("status", "==", "Ramped-Out").get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                var holder = a.state.pulledActivites
                holder.push(<DispatcherActivityView  dueback = {doc.data().endResTime} startResTime = {doc.data().startResTime} refresh = {a.refresh} destination = {doc.data().destination} student={doc.data().student} instructor={doc.data().instructor} startTime={doc.data().startTime} stopTime={doc.data().endTime} resourceType = {doc.data().resourceType} resource = {doc.data().resource} status = {doc.data().status} id={doc.id}></DispatcherActivityView>)
                a.setState({pulledActivites : holder})
                
            });
        });
        */

    /*
        docRef.orderBy("startTime").get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                  if(doc.data().status == "Scheduled" || doc.data().status == "Checked-In" || doc.data().status == "Authorized" || doc.data().status == "Ramped-Out"){
                    console.log(doc.id, " => ", doc.data());
                    var holder = a.state.pulledActivites
                    holder.push(<DispatcherActivityView  dueback = {doc.data().endResTime} startResTime = {doc.data().startResTime} refresh = {a.refresh} destination = {doc.data().destination} student={doc.data().student} instructor={doc.data().instructor} startTime={doc.data().startTime} stopTime={doc.data().endTime} resourceType = {doc.data().resourceType} resource = {doc.data().resource} status = {doc.data().status} id={doc.id}></DispatcherActivityView>)
                    a.setState({pulledActivites : holder})    
                  }
              });
        })
        */
  }
  doPullRefresh() {
    let a = this;
    var db = firebase.firestore();
    let d = new Date();
    var monthHolder = d.getMonth();
    var dayHolder = d.getDate();
    var yearHolder = d.getFullYear();
    var docRef = db
      .collection("schedules")
      .doc(monthHolder + 1 + "-" + dayHolder + "-" + yearHolder)
      .collection("activities");
    console.log(monthHolder + 1 + "-" + dayHolder + "-" + yearHolder);
    this.setState({ pulledActivites: [] });

    docRef
      .orderBy("startTime")
      .get()
      .then(function (querySnapshot) {
        a.setState({ pulledActivites: [] });
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          if (
            doc.data().status == "Scheduled" ||
            doc.data().status == "Checked-In" ||
            doc.data().status == "Authorized" ||
            doc.data().status == "Ramped-Out"
          ) {
            console.log(doc.id, " => ", doc.data());
            var holder = a.state.pulledActivites;
            holder.push(
              <DispatcherActivityView
                
                dueback={doc.data().endResTime}
                startResTime={doc.data().startResTime}
                refresh={a.refresh}
                destination={doc.data().destination}
                student={doc.data().student}
                instructor={doc.data().instructor}
                startTime={doc.data().startTime}
                stopTime={doc.data().endTime}
                resourceType={doc.data().resourceType}
                resource={doc.data().resource}
                status={doc.data().status}
                id={doc.id}
              ></DispatcherActivityView>
            );
            a.setState({ pulledActivites: holder });
          }
        });
      });
  }
  refresh() {
    this.doPullRefresh();
    // this.setState({pulledAricraft : []})
    // this.setState({pulledActivites : []}, () => this.doPullRefresh())

    /*
        docRef.where("status", "==", "Scheduled").get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                var holder = a.state.pulledActivites
                holder.push(<DispatcherActivityView   dueback = {doc.data().endResTime} startResTime = {doc.data().startResTime} refresh = {a.refresh} destination = {doc.data().destination} dispatcherComment = {doc.data().dispatcherComment} student={doc.data().student} instructor={doc.data().instructor} startTime={doc.data().startTime} stopTime={doc.data().endTime} resourceType = {doc.data().resourceType} resource = {doc.data().resource} status = {doc.data().status} id={doc.id} course = {doc.data().course} unit = {doc.data().unit} hobbs = {doc.data().hobbs}></DispatcherActivityView>)
                a.setState({pulledActivites : holder})
            });
        });
        docRef.where("status", "==", "Authorized").get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                var holder = a.state.pulledActivites
                 holder.push(<DispatcherActivityView  dueback = {doc.data().endResTime} startResTime = {doc.data().startResTime} refresh = {a.refresh} destination = {doc.data().destination} dispatcherComment = {doc.data().dispatcherComment} student={doc.data().student} instructor={doc.data().instructor} startTime={doc.data().startTime} stopTime={doc.data().endTime} resourceType = {doc.data().resourceType} resource = {doc.data().resource} status = {doc.data().status} id={doc.id}></DispatcherActivityView>)
                a.setState({pulledActivites : holder})
            });
        });
        docRef.where("status", "==", "Checked-In").get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                var holder = a.state.pulledActivites
                 holder.push(<DispatcherActivityView  dueback = {doc.data().endResTime} startResTime = {doc.data().startResTime} refresh = {a.refresh} destination = {doc.data().destination} dispatcherComment = {doc.data().dispatcherComment} student={doc.data().student} instructor={doc.data().instructor} startTime={doc.data().startTime} stopTime={doc.data().endTime} resourceType = {doc.data().resourceType} resource = {doc.data().resource} status = {doc.data().status} id={doc.id}></DispatcherActivityView>)
                a.setState({pulledActivites : holder})
            });
        });
        docRef.where("status", "==", "Ramped-Out").get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                var holder = a.state.pulledActivites
                console.log(doc.data().dispatcherComment)
                holder.push(<DispatcherActivityView  dueback = {doc.data().endResTime} startResTime = {doc.data().startResTime} refresh = {a.refresh} destination = {doc.data().destination} dispatcherComment = {doc.data().dispatcherComment} student={doc.data().student} instructor={doc.data().instructor} startTime={doc.data().startTime} stopTime={doc.data().endTime} resourceType = {doc.data().resourceType} resource = {doc.data().resource} status = {doc.data().status} id={doc.id}></DispatcherActivityView>)
                a.setState({pulledActivites : holder})
            });
        });
        */
  }

  sortActivities() {
    console.log("SORTING%%%%%%%%%%%%");
    var holder = this.state.pulledActivites;
    holder = holder.sort();
    console.log(holder);
    this.setState({ pulledActivites: holder });
  }
  render() {
    return (
      <div>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Student</TableCell>
                <TableCell>Instructor</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Resource Type</TableCell>
                <TableCell>Resource</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Check In</TableCell>
                <TableCell>Assign Resource</TableCell>
                <TableCell>Ramp Out</TableCell>
                <TableCell>Ramp In</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>{this.state.pulledActivites}</TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default DispatcherView;
