import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Aircraft from "./aircraftViewAdmin";
import Grid from "@material-ui/core/Grid";
import * as firebase from "firebase";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import c172 from "./c172.png";

class AircraftEditView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nNumber: props.nNumber,
      emptyWeight: props.emptyWeight,
      moment: props.moment,
      cg: props.cg,
      usefulLoad: props.usefulLoad,
      grossWeight: props.grossWeight,
      onClose: props.onClose,
      resourceID: props.resourceID,
      type: props.type,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateData = this.updateData.bind(this);
  }
  changeState(name, target) {
    this.setState({
      [name]: target,
    });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }
  updateData() {
    var db = firebase.firestore();
    console.log("aircraft/" + this.state.resourceID);
    const acRef = db.collection("aircraft").doc(this.state.resourceID).set(
      {
        CG: this.state.cg,
        emptyWeight: this.state.emptyWeight,
        grossWeight: this.state.grossWeight,
        moment: this.state.moment,
        nNumber: this.state.nNumber,
        usefulLoad: this.state.usefulLoad,
      },
      { merge: true }
    );
    let a = this.props.onClose;
  }
  render() {
    return (
      <Card style={aircraftCardStyle}>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h1>{this.state.resourceID}</h1>
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="nNumber"
                label="N Number"
                variant="outlined"
                defaultValue={this.state.nNumber}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="emptyWeight"
                label="Empty Weight"
                variant="outlined"
                defaultValue={this.state.emptyWeight}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="moment"
                label="Moment"
                variant="outlined"
                defaultValue={this.state.moment}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="cg"
                label="Center of Gravity"
                variant="outlined"
                defaultValue={this.state.cg}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="usefulLoad"
                label="Useful Load"
                variant="outlined"
                defaultValue={this.state.usefulLoad}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="grossWeight"
                label="Gross Weight"
                variant="outlined"
                defaultValue={this.state.grossWeight}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={this.updateData && this.state.onClose}
              >
                Update
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={this.state.onClose}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    );
  }
}
export default AircraftEditView;
const aircraftCardStyle = {
  textAlign: "center",
  marginBottom: "50px",
  marginLeft: "5%",
  width: "650px",
};
