import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Aircraft from "./aircraftViewAdmin";
import Grid from "@material-ui/core/Grid";
import * as firebase from "firebase";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import c172 from "./c172.png";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
class AircraftCreateView extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitNewAircraft = this.submitNewAircraft.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      nNumber: props.nNumber,
      emptyWeight: props.emptyWeight,
      moment: props.moment,
      cg: props.cg,
      usefulLoad: props.usefulLoad,
      grossWeight: props.grossWeight,
      onClose: props.onClose,
      resourceID: props.resourceID,
      dismiss: props.dismiss,
    };
  }
  changeState(name, target) {
    this.setState({
      [name]: target,
    });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }
  submitNewAircraft() {
    var db = firebase.firestore();
    const acRef = db
      .collection("aircraft")
      .doc(this.state.resourceId)
      .set({
        CG: this.state.cg,
        nNumber: this.state.nNumber,
        emptyWeight: this.state.emptyWeight,
        grossWeight: this.state.grossWeight,
        usefulLoad: this.state.usefulLoad,
        moment: this.state.moment,
        aircraftType: this.state.aircraftType,
        hobbsTime : this.state.hobbs,
        tachTime : this.state.tach,
        isAssigned : false,
        isDowned : false,
        isRampedOut : false,
        status : 'Available'

      })
      .then(function () {
        console.log("Document successfully written!");
        window.location.reload(true);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  }
  handleChange(event) {
    console.log(event);
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log(target.name);
    this.setState({ [name]: value });
    console.log(this.state.aircraftType);
  }
  render() {
    return (
      <Card style={aircraftCardStyle}>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="resourceId"
                label="Resource ID"
                variant="outlined"
                style={resourceIdStyle}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Select
                labelId="demo-simple-select-label"
                id="aircraftType"
                name="aircraftType"
                value={this.state.aircraftType}
                onChange={this.handleChange}
              >
                <MenuItem value={"c172"}>C-172</MenuItem>
                <MenuItem value={"da42"}>DA-42</MenuItem>
                <MenuItem value={"c182"}>C-182</MenuItem>
                <MenuItem value={"decat"}>Decathalon</MenuItem>
                <MenuItem value = {"C172-ULTA-AATD"}>C172-ULTA-AATD</MenuItem>
                <MenuItem value = {"ground"}>Ground</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="nNumber"
                label="N Number"
                variant="outlined"
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="emptyWeight"
                label="Empty Weight"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="moment"
                label="Moment"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="cg"
                label="Center of Gravity"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="usefulLoad"
                label="Useful Load"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="grossWeight"
                label="Gross Weight"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="hobbs"
                label="Hobbs Time"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={this.handleInputChange}
                id="tach"
                label="Tach Time"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={this.submitNewAircraft}
              >
                Add
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={this.state.dismiss}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    );
  }
}
export default AircraftCreateView;
const aircraftCardStyle = {
  textAlign: "center",
  marginTop: "3%",
  marginLeft: "35%",
  width: "650px",
};
const resourceIdStyle = {
  marginTop: "2%",
};
