import React, { Component } from "react";
import * as firebase from "firebase";
import Card from "@material-ui/core/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Clock from "react-live-clock";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'
import OpsRequestObject from './opsRequestObject'
import DispatcherView from './dispatcherView'
class FlightSupervisor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metarRAW: "METAR ERROR",
      tafRAW: "TAF ERROR",
      pireps: [],
      status: "ERR",
      activities: [],
      opsRequests : false,
      opsRequestObjects : [],
      dbView : <DispatcherView></DispatcherView>
    };
    this.changeStatus = this.changeStatus.bind(this);
    this.refresh = this.refresh.bind(this)
  }

  componentDidMount() {
    let db = firebase.firestore();
    let a = this;
    db.collection("lineOps")
      .doc("METAR")
      .onSnapshot(function (doc) {
        a.setState({ metarRAW: doc.data().metarRaw });
      });
    db.collection("lineOps")
      .doc("TAF")
      .onSnapshot(function (doc) {
        a.setState({ tafRAW: doc.data().raw });
      });
    db.collection("lineOps")
      .doc("status")
      .get()
      .then(function (doc) {
        a.setState({ status: doc.data().flightStatus });
      });

    let d = new Date();
    var monthHolder = d.getMonth();
    var dayHolder = d.getDate();
    var yearHolder = d.getFullYear();
    var docRef = db
      .collection("schedules")
      .doc(monthHolder + 1 + "-" + dayHolder + "-" + yearHolder)
      .collection("activities");
    
    docRef.where("status", "==", "Ops-Request").get().then(function(querySnapshot){
      querySnapshot.forEach(function(doc){
        let holder = a.state.opsRequestObjects
        holder.push(<OpsRequestObject refresh = {a.refresh} course = {doc.data().course} endResTime = {doc.data().endResTime} endTime = {doc.data().endTime} instructor = {doc.data().instructor} startResTime = {doc.data().startResTime} startTime = {doc.data().startTime} student = {doc.data().student} unit = {doc.data().unit} resourceType={doc.data().resourceType} id = {doc.id}></OpsRequestObject>)
        a.setState({opsRequestObjects : holder})
        a.setState({opsRequests : true})
      })
    })
  }
  refresh(){
    
    this.setState({opsRequests : false})
    this.setState({opsRequestObjects : []})
    let db = firebase.firestore();
    let a = this;
    db.collection("lineOps")
      .doc("METAR")
      .onSnapshot(function (doc) {
        a.setState({ metarRAW: doc.data().metarRaw });
      });
    db.collection("lineOps")
      .doc("TAF")
      .onSnapshot(function (doc) {
        a.setState({ tafRAW: doc.data().raw });
      });
    db.collection("lineOps")
      .doc("status")
      .get()
      .then(function (doc) {
        a.setState({ status: doc.data().flightStatus });
        a.setState({dbView : <DispatcherView></DispatcherView>})
      });

    let d = new Date();
    var monthHolder = d.getMonth();
    var dayHolder = d.getDate();
    var yearHolder = d.getFullYear();
    var docRef = db
      .collection("schedules")
      .doc(monthHolder + 1 + "-" + dayHolder + "-" + yearHolder)
      .collection("activities");
    
    docRef.where("status", "==", "Ops-Request").get().then(function(querySnapshot){
      querySnapshot.forEach(function(doc){
        let holder = a.state.opsRequestObjects
        holder.push(<OpsRequestObject course = {doc.data().course} endResTime = {doc.data().endResTime} endTime = {doc.data().endResTime} instructor = {doc.data().instructor} startResTime = {doc.data().startResTime} startTime = {doc.data().startTime} student = {doc.data().student} unit = {doc.data().unit} resourceType={doc.data().resourceType}></OpsRequestObject>)
        a.setState({opsRequestObjects : holder})
        a.setState({opsRequests : true})
      })
    })
    
    
  }
  changeStatus(event) {
    this.setState({ status: event.target.value });
    let db = firebase.firestore();
    let a = this;
    db.collection("lineOps").doc("status").update({
      flightStatus: event.target.value,
    });
  }

  render() {
    return (
      <Grid container >
         <Grid item xs = {12}>
        <Card style = {{textAlign : 'center', marginBottom : '25px'}} hidden={!this.state.opsRequests}>
          <h1>Ops Requests</h1>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Student</TableCell>
                <TableCell>Instructor</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>Res. Start Time</TableCell>
                <TableCell>Res. End Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Resource Type</TableCell>
                <TableCell>Approve</TableCell>
                <TableCell>Deny</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.opsRequestObjects}
            </TableBody>
          </Table>
        </Card>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ textAlign: "center"}}>
            <h1>PQ</h1>
            <h3>Metar</h3>
            <h3>{this.state.metarRAW}</h3>
            <h3>TAF</h3>
            <h3>{this.state.tafRAW}</h3>
            <Select
              labelId="changeStatus"
              id="changeStatus"
              value={this.state.status}
              onChange={this.changeStatus}
            >
              <MenuItem value={"GO"}>GO</MenuItem>
              <MenuItem value={"DUAL ONLY"}>DUAL ONLY</MenuItem>
              <MenuItem value={"LOCAL ONLY"}>LOCAL ONLY</MenuItem>
              <MenuItem value={"PATTERN ONLY"}>PATTERN ONLY</MenuItem>
              <MenuItem value={"HOLD"}>HOLD</MenuItem>
            </Select>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card style={{ textAlign: "center", marginTop : '25px' }}>
            <h1>Outlook</h1>
            {this.state.dbView}
          </Card>
        </Grid>
       
      </Grid>
    );
  }
}

var metarStruct = {};

export default FlightSupervisor;
