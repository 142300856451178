import React, { Component } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button'
import { Typography } from "@mui/material";
import Draggable from 'react-draggable'
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import * as firebase from "firebase";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import Moment from 'react-moment'
import SchedulingActivityEdit from "./schedulingActivityEdit";
import PersonIcon from '@mui/icons-material/Person';
class SchedulerActivity extends Component {
    constructor(props){
        super(props)
        this.state = {
            tailNumber : props.tailNumber,
            activites : [],
            hobbsTime : props.hobbsTime,
            isDowned : props.isDowned,
            nextEventTime : props.nextEventTime,
            tachTime : props.tachTime,
            acColor : 'rgba(128, 220, 128, .8)',
            scheduleDate : props.scheduleDate,
            instructor : props.instructor,
            student : props.student,
            course : props.course,
            resourceType : props.resourceType,
            comment : props.comment,
            activityBackdropOpen : false,
            docCarry : "",
            resourceType : "",
            preBreif : 0,
            acTime : 0,
            postBreif : 0,
            startTime : '',
            endTime : '',
            date : '',
            docID : '',
            activityDateRef : this.props.scheduleDate

        }
        this.openActivityEdit = this.openActivityEdit.bind(this)
        this.closeActivityEdit = this.closeActivityEdit.bind(this)
        this.updateActivityInfo = this.updateActivityInfo.bind(this)
        this.recauculateTimes = this.recauculateTimes.bind(this)
        this.savedButtonPressed = this.savedButtonPressed.bind(this)
        this.deleteActivity = this.deleteActivity.bind(this)
    }

    componentDidMount(){
        if(this.state.isDowned == true){
                this.setState({acColor : '#d9534f'})
        }else{
            if(this.state.nextEventTime < 10){
                this.setState({acColor : '#d2d94f'})
            }
        }

        let a = this
        var floatActivities = []
        let db = firebase.firestore()
        let activityRef = db.collection('schedules').doc(this.props.scheduleDate).collection('activities').where('resource', '==', this.state.tailNumber)
        activityRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                console.log(doc.data())
                let startTime = new Date(doc.data().startResTime["seconds"] * 1000)
                a.setState({docID : doc.id})
                
                
                console.log(startTimeCarry)


                let checkInTime = new Date(doc.data().startTime["seconds"] * 1000)
                var startTimeCarry = String(checkInTime.getHours()).padStart(2, "0") + ':' + String(checkInTime.getMinutes()).padStart(2, "0");
                let activityEndTime = new Date(doc.data().endTime["seconds"] * 1000)
                var endTimeCarry = String(activityEndTime.getHours()).padStart(2, "0") + ':' + String(activityEndTime.getMinutes()).padStart(2, "0");
                let endTime = new Date(doc.data().endResTime["seconds"] * 1000)
                
                let activityTimeMins = (((doc.data().endResTime["seconds"]-doc.data().startResTime["seconds"])*1000)/60)/1000
                let activityTimePixles = activityTimeMins.toString() + 'px'
                //console.log(startTime)
                let minsFromMidnight = startTime.getHours()*60 + startTime.getMinutes()
                let minsFromFive = minsFromMidnight - 300
                let activityPosX = (startTime.getHours()-5)*60 + startTime.getMinutes()
                let margin = activityPosX.toString() + 'px'
                console.log(margin)
                var bcColor = 'rgba(120, 120, 120, 0.9)'
                if(doc.data().unit.includes('DUAL')){
                    bcColor = '#337ab7'
                }

                if(doc.data().unit.includes('EVAL')){
                    bcColor = '#9433b7'
                }
                if(doc.data().unit.includes('SOLO')){
                    bcColor = '#b76333'
                }
                if(doc.data().course == "ADMIN"){
                    bcColor = '#b73356'
                }

                var dateCarry = startTime.getFullYear() + '-' + (startTime.getMonth()+1) + '-' + startTime.getDate()
                console.log(dateCarry)
                let title = "Instructor : " + doc.data().instructor + " Student : " + doc.data().student + ' Course : ' + doc.data().course + ' Unit : ' + doc.data().unit
                let style = {marginLeft : margin, backgroundColor : bcColor, width : activityTimePixles, height : '75%'}
                floatActivities.push(<Tooltip title = {title}><div style = {style}><Button onClick = {()=>a.openActivityEdit(doc.data())}><Typography variant="body2" style = {{color : 'white'}}><Moment format="HH:mm">{startTime}</Moment> <Moment format="HH:mm">{endTime}</Moment></Typography></Button></div></Tooltip>)
                a.setState({startTime : startTimeCarry, endTime : endTimeCarry, date : dateCarry, startTimeRaw : doc.data().startTime, endTimeRaw : doc.data().endTime, activites : floatActivities, resourceType : doc.data().resourceType, instructor : doc.data().instructor, student : doc.data().student, preBreif : doc.data().preBreif, postBreif : doc.data().postBreif, acTime : parseFloat(doc.data().acTime,)})
            })
        })
        
    }

    openActivityEdit(docRef){
        console.log(docRef)
        if(this.state.activityBackdropOpen == false){
            this.setState({activityBackdropOpen : true, docCarry : docRef})
        }
    }
    closeActivityEdit(){
        this.setState({activityBackdropOpen : false})
    }
    updateActivityInfo(event){
        this.setState({[event.target.name] : event.target.value},()=>this.recauculateTimes())
    }
    changeActivityStartTime(event){
        console.log(event.target.value)
    }
    recauculateTimes(){
        let startTime = new Date()
        console.log(this.state.startTime)
        startTime.setHours(this.state.startTime.substring(0,2))
        startTime.setMinutes(this.state.startTime.substring(3,5))
        console.log(startTime)
        let activitySeconds = (parseFloat(this.state.preBreif)*60*60) + (parseFloat(this.state.acTime)*60*60) + (parseFloat(this.state.postBreif)*60*60) 
        console.log(activitySeconds)
        let endTime = new Date(startTime.getTime()+activitySeconds*1000)
        console.log(endTime)
        let endTimeCarry = String(endTime.getHours()).padStart(2, "0") + ':' + String(endTime.getMinutes()).padStart(2, "0");
        this.setState({endTime : endTimeCarry})
    }   
    savedButtonPressed(){
        let db=firebase.firestore()
        console.log(this.state.activityDateRef)
        let docRef = db.collection('schedules').doc(this.state.activityDateRef).collection('activities').doc(this.state.docID)
        let startTime = new Date(this.state.startTimeRaw.seconds*1000)
        console.log(startTime)
        startTime.setHours(this.state.startTime.substring(0,2))
        startTime.setMinutes(this.state.startTime.substring(3,5))
        let startResTime = new Date(startTime.getTime()+(parseFloat(this.state.preBreif)*60*60)*1000)
        
        console.log(startResTime)
        let endTime = new Date(this.state.endTimeRaw.seconds*1000)
        endTime.setHours(this.state.endTime.substring(0,2))
        endTime.setMinutes(this.state.endTime.substring(3,5))
        let endResTime = new Date(startTime.getTime()+((parseFloat(this.state.preBreif)*60*60) + (parseFloat(this.state.acTime)*60*60))*1000)
       console.log(startResTime)
       console.log(endResTime)
        docRef.update({
            startTime : startTime,
            startResTime : startResTime,
            endTime : endTime,
            endResTime : endResTime,
            preBreif : this.state.preBreif,
            acTime : this.state.acTime,
            postBreif : this.state.postBreif,
            resourceType : this.state.resourceType

        }).then(this.setState({activityBackdropOpen : false}))
        
    }
    deleteActivity(){
        let db=firebase.firestore()
        let docRef = db.collection('schedules').doc(this.state.activityDateRef).collection('activities').doc(this.state.docID)
        docRef.delete()
    }
    render(){
        return(<Grid container>
            <Grid item xs={1}>
              <Card style = {{backgroundColor : this.state.acColor}}>
              <Grid container>
              <Grid item xs = {6}><h3>{this.state.tailNumber}</h3></Grid>
              <Grid item xs = {6}></Grid>
              </Grid>
              </Card>
            </Grid>
            <Grid item xs={11} >
                
                {this.state.activites}
                
            </Grid>










            <Backdrop open = {this.state.activityBackdropOpen} onClose = {this.closeActivityEdit}>
            <Card style={{width: "75%",align: "center",marginLeft: "15%"}}>
            <div style = {{marginTop : '1%'}}>
                <Grid container>
                    <Grid item xs = {11}>
                        <h1>Edit Activity</h1>
                    </Grid>
                    <Grid item xs = {1}>
                        <IconButton onClick = {this.closeActivityEdit}><CloseIcon></CloseIcon></IconButton>
                    </Grid>
                    <Grid item xs = {6}><h3>Request Date</h3><TextField type = "date" value = {this.state.date} disabled style = {{marginBotton : '5px'}}></TextField></Grid>
                    <Grid item xs = {6}><h3>Resource Type</h3><Select label = "Request Type" onChange = {this.updateActivityInfo} name = {"resourceType"} value = {this.state.resourceType}>
                            <MenuItem value={'C172-ANY'} name = {"resourceType"}>C172(ANY)</MenuItem>
                            <MenuItem value={'C172-SPIN'} name = {"resourceType"}>C172-SPIN</MenuItem>
                            <MenuItem value={'C172-TAA'} name = {"resourceType"}>C172-TAA</MenuItem>
                            <MenuItem value={'C172-NAVIII'} name = {"resourceType"}>C172-NAVIII</MenuItem>
                            <MenuItem value={'C172-WESTRAMP'} name = {"resourceType"}>C172-WESTRAMP</MenuItem>
                            <MenuItem value={'C182RG'} name = {"resourceType"}>C182RG</MenuItem>
                            <MenuItem value={'C150'} name = {"resourceType"}>C150</MenuItem>
                            <MenuItem value={'DA42NG'} name = {"resourceType"}>DA42NG</MenuItem>
                            <MenuItem value={'8KCAB'} name = {"resourceType"}>8KCAB</MenuItem>
                            <MenuItem value={'CESSNA-CPT'} name = {"resourceType"}>CESSNA-CPT</MenuItem>
                            <MenuItem value={'CLASSROOM'} name = {"resourceType"}>CLASSROOM</MenuItem>
                            <MenuItem value={'CONFERENCE-ROOM'} name = {"resourceType"}>CONFERENCE-ROOM</MenuItem>
                            <MenuItem value={'FTD-BAYS'} name = {"resourceType"}>FTD-BAYS</MenuItem>
                            <MenuItem value={'ORAL-ROOM'} name = {"resourceType"}>ORAL-ROOM</MenuItem>
                            <MenuItem value={'C172-LITE-AATD'} name = {"resourceType"}>C172-LITE-AATD</MenuItem>
                            <MenuItem value={'C172-RTD-AATD'} name = {"resourceType"}>C172-RTD-AATD</MenuItem>
                            <MenuItem value={'C172-TRUFLT AATD'} name = {"resourceType"}>172-TRUFLT AATD</MenuItem>
                            <MenuItem value={'C172-ULTRA-AATD'} name = {"resourceType"}>C172-ULTRA-AATD</MenuItem>
                            <MenuItem value={'DA42-TRUFLT-AATD'} name = {"resourceType"}>DA42-TRUFLT-AATD</MenuItem>
                            <MenuItem value={'DA-42-ULTRA-AATD'} name = {"resourceType"}>DA-42-ULTRA-AATD</MenuItem>
                        </Select></Grid>
                    <h4> </h4>
                    <Grid itexm xs = {4}><h3>PIC/Inst</h3><TextField disabled value = {this.state.instructor}></TextField><IconButton><PersonIcon/></IconButton></Grid>
                    <Grid itexm xs = {4}><h3>"Student/Obs.</h3><TextField disabled value = {this.state.student}></TextField><IconButton><PersonIcon/></IconButton></Grid>
                    <Grid itexm xs = {4}><h3>Student/Obs.2</h3><TextField disabled></TextField><IconButton><PersonIcon/></IconButton></Grid>
                    <Grid item xs = {2.4}><h4>Start</h4><TextField name = {'startTime'} type = "time" onChange = {this.updateActivityInfo} value = {this.state.startTime}></TextField></Grid>
                    <Grid item xs = {2.4}><h4>Pre-Breif</h4><TextField  name = {'preBreif'} id = "adminPreBreif" value={this.state.preBreif} onChange = {this.updateActivityInfo} type="number" inputProps = {{step : .1}}></TextField></Grid>
                    <Grid item xs = {2.4}><h4>A/C Time</h4><TextField name = {'acTime'} value={this.state.acTime} id = "adminACTime" onChange = {this.updateActivityInfo} type="number" inputProps = {{step : .1}}></TextField></Grid>
                    <Grid item xs = {2.4}><h4>Post-Brief</h4><TextField name = {'postBreif'} value={this.state.postBreif} id = "adminPostBreif" type="number" inputProps = {{step : .1}}></TextField></Grid>
                    <Grid item xs = {2.4}><h4>End</h4><TextField type = "time" value = {this.state.endTime}></TextField></Grid>
                    <h4>Comments</h4>
                        <TextField style = {{width : '100%'}} onChange = {this.changeAdminParams} value = {this.state.docCarry.comment} name = "adminComment" disabled></TextField>
                    </Grid>
                    <Button variant = 'contained' color = 'error' onClick = {this.deleteActivity}>Delete</Button>
                    <Button variant = 'contained' style = {{marginLeft : '87%'}} onClick = {this.savedButtonPressed}>Save</Button>
                    
                </div>
                
            </Card>
            </Backdrop>

            </Grid>)
    }
}

export default SchedulerActivity