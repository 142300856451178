import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
class CouseCreateObjectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objs: 0,
      id: this.props.id,
      pushBack: this.props.pushBack,
      isGraded: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.id;
    this.setState({ [name]: value }, () =>
      this.props.pushBack(
        this.state.id,
        this.state.objName,
        this.state.isGraded
      )
    );
    console.log(
      "something was inputted : " + value + " " + name + event.target
    );

    if (value == "false") {
      this.setState({ isGraded: false }, () =>
        this.props.pushBack(
          this.state.id,
          this.state.objName,
          this.state.isGraded
        )
      );
    }
    if (value == "true") {
      this.setState({ isGraded: true }, () =>
        this.props.pushBack(
          this.state.id,
          this.state.objName,
          this.state.isGraded
        )
      );
    }
  }

  render() {
    return (
      <Card style={cardStyle}>
        <Grid container>
          <TextField
            style={courseNameStyle}
            onChange={this.handleInputChange}
            id="objName"
            label="Objective Name"
            variant="outlined"
          ></TextField>
          <RadioGroup style={courseRadioStyle}>
            <Grid>
              <FormControlLabel
                checked={this.state.isGraded}
                value="true"
                id="isGraded"
                onChange={this.handleInputChange}
                control={<Radio color="default" />}
                label="OGMUI"
              />
              <FormControlLabel
                checked={this.state.isGraded === false}
                value="false"
                id="isGraded"
                onChange={this.handleInputChange}
                control={<Radio color="default" />}
                label="C/I"
              />
            </Grid>
          </RadioGroup>
        </Grid>
      </Card>
    );
  }
}
const cardStyle = {
  marginTop: "10px",
  marginLeft: "20px",
  width: "1000",
  marginBottom: "10px",
};
const courseNameStyle = {
  marginTop: "10px",
  align: "left",
  marginBottom: "10px",
};
const courseRadioStyle = {
  marginTop: "15px",
  align: "left",
  marginBottom: "10px",
};

export default CouseCreateObjectContainer;
