import React, { Component } from 'react';
import * as firebase from 'firebase';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableFooter  from '@material-ui/core/TableFooter'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Modal from "@material-ui/core/Modal"
import Backdrop from '@material-ui/core/Backdrop'
import { CSVReader } from 'react-papaparse'
import DatePicker from 'react-date-picker';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSVLink, CSVDownload } from "react-csv";
const csvData2 = [
    ['Foreflight Logbook Import'],
    [],
    ['Aircraft Table'],
    ['Text','Text','Text','YYYY','Text','Text','Text','Text','Text','Text','Boolean','Boolean','Boolean'],
    ['AircraftID','EquipmentType','TypeCode','Year','Make','Model','Category','Class','GearType','EngineType','Complex','HighPerformance','Pressurized'],
    [],
    ['Flights Table',,,,,,,,,,,,,,,,,,,,,,,,,,,,,,'#;type;runway;airport;comments',,,,,,,,,,,,'name;role;email',,,,,,,,,,,,,,,],
    ['Date','Text','Text','Text','Text','hhmm','hhmm','hhmm','hhmm','hhmm','hhmm','Decimal','Decimal','Decimal','Decimal','Decimal','Decimal','Decimal','Number','Number','Number','Number','Number','Decimal','Decimal','Decimal','Decimal','Decimal','Decimal','Number','Packed Detail','Packed Detail','Packed Detail','Packed Detail','Packed Detail','Packed Detail','Decimal','Decimal','Decimal','Decimal','Text','Text','Packed Detail','Packed Detail','Packed Detail','Packed Detail','Packed Detail','Packed Detail','Boolean','Boolean','Boolean','Text','Decimal','Decimal','Number','Date','Boolean','Text'  ],
    ['Date','AircraftID','From','To','Route','TimeOut','TimeOff','TimeOn','TimeIn','OnDuty','OffDuty','TotalTime','PIC','SIC','Night','Solo','CrossCountry','Distance','DayTakeoffs','DayLandingsFullStop','NightTakeoffs','NightLandingsFullStop','AllLandings','ActualInstrument','SimulatedInstrument','HobbsStart','HobbsEnd','TachStart','TachEnd','Holds','Approach1','Approach2','Approach3','Approach4','Approach5','Approach6','DualGiven','DualReceived','SimulatedFlight','GroundTraining','InstructorName','InstructorComments','Person1','Person2','Person3','Person4','Person5','Person6','FlightReview','Checkride','IPC','[Text]CustomFieldName','[Numeric]CustomFieldName','[Hours]CustomFieldName','[Counter]CustomFieldName','[Date]CustomFieldName','[Toggle]CustomFieldName','PilotComments'  ], 
    ['01-01-2020','N643ER','KPRC','KPRC',,,,,,,,'1.6','1.6','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','1.6','0','0','Reesj4',,,,,,,,'false','false','false',,,,,,,'TEST IMPORT'  ]
]
class Logbook extends Component {

    constructor(props){
        super(props)
        this.state = {
            logEntries : [],
            totalASEL : 0,
            totalAMEL : 0,
            totalGroundTrainer : 0,
            totalDualReceived : 0,
            totalPIC : 0,
            totalDay : 0,
            totalNight : 0,
            totalXC : 0,
            totalIFRAct : 0,
            totalIFRSim : 0,
            totalApproaches : 0,
            totalDayLandings : 0,
            totalNightLandings : 0,
            totalFlightTime : 0,
            importActivities : [],
            importHoldActivities : [],
            importDialogOpen : false,
            startDate : new Date(),
            endDate : new Date(),
            exportDialogOpen : false
            
        }
        this.processLogbookCSV = this.processLogbookCSV.bind(this)
        this.simplehash = this.simplehash.bind(this)
        this.importSubmit = this.importSubmit.bind(this)
        this.importButtonClicked = this.importButtonClicked.bind(this)
        this.closeImportModal = this.closeImportModal.bind(this)
        this.setStartDate = this.setStartDate.bind(this)
        this.setEndDate = this.setEndDate.bind(this)
        this.getLogbookActivities = this.getLogbookActivities.bind(this)
        this.exportButtonClicked = this.exportButtonClicked.bind(this)
        this.dismissExportDialog = this.dismissExportDialog.bind(this)
        this.exportLogbook = this.exportLogbook.bind(this)
    }

    componentWillMount(){
        let userName = firebase.auth().currentUser.displayName
        let db = firebase.firestore()
        let endDate = new Date()
        let startDate = new Date()
        startDate.setMonth(startDate.getMonth() - 3)
        endDate.setDate(endDate.getDate() + 1)
        this.setState({startDate : startDate})
        var docRef = db.collection('users').doc(userName).collection('logbook').orderBy("date").where("date", ">", startDate).where("date", "<", endDate)
        let a = this
        docRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                var holder = a.state.logEntries
                console.log(doc.data().date)
                var dateHolder = new Date(doc.data().date.seconds*1000)
                if(doc.data().aircraftMake != null){
                    holder.push( <TableRow>
                        <TableCell>{(dateHolder.getMonth() + 1) + '-' + dateHolder.getDate() + '-' + dateHolder.getFullYear()}</TableCell>
                        <TableCell>{doc.data().aircraftMake}</TableCell>
                        <TableCell>{doc.data().aircraftID}</TableCell>
                        <TableCell>{doc.data().routeOfFlight}</TableCell>
                        <TableCell>{doc.data().ASEL}</TableCell>
                        <TableCell>{doc.data().AMEL}</TableCell>
                        <TableCell>{doc.data().groundTrainer}</TableCell>
                        <TableCell>{doc.data().dualReceived}</TableCell>
                        <TableCell>{doc.data().PIC}</TableCell>
                        <TableCell>{doc.data().day}</TableCell>
                        <TableCell>{doc.data().night}</TableCell>
                        <TableCell>{doc.data().XC}</TableCell>
                        <TableCell>{doc.data().IFRAct}</TableCell>
                        <TableCell>{doc.data().IFRSim}</TableCell>
                        <TableCell>{doc.data().Approaches}</TableCell>
                        <TableCell>{doc.data().dayLanding}</TableCell>
                        <TableCell>{doc.data().nightLanding}</TableCell>
                        <TableCell>{parseFloat(doc.data().ASEL) + parseFloat(doc.data().AMEL)}</TableCell>
                        </TableRow>)
                    a.setState({logEntries : holder})
                    }
                

               
                if(doc.data().ASEL != null){
                    var totalASEL = a.state.totalASEL
                    totalASEL = totalASEL + parseFloat(doc.data().ASEL)
                    a.setState({totalASEL : totalASEL})
                    var totalFlightHolder = a.state.totalFlightTime
                    totalFlightHolder = totalFlightHolder + parseFloat(doc.data().ASEL)
                    a.setState({totalFlightTime : totalFlightHolder})
                }

                if(doc.data().AMEL != null){
                    var totalAMEL = a.state.totalAMEL
                    totalAMEL = totalAMEL + parseFloat(doc.data().AMEL)
                    a.setState({totalAMEL : totalAMEL})
                    var totalFlightHolder = a.state.totalFlightTime
                    totalFlightHolder = totalFlightHolder + parseFloat(doc.data().AMEL)
                    a.setState({totalFlightTime : totalFlightHolder})
                }
                
                if(doc.data().groundTrainer != null){
                    var totalGroundTrainer = a.state.totalGroundTrainer
                    totalGroundTrainer = totalGroundTrainer + parseFloat(doc.data().groundTrainer)
                    a.setState({totalGroundTrainer : totalGroundTrainer})
                }
                if(doc.data().dualReceived != null){
                    var totalDualReceived = a.state.totalDualReceived
                    totalDualReceived = totalDualReceived + parseFloat(doc.data().dualReceived)
                    a.setState({totalDualReceived : totalDualReceived})
                }

                if(doc.data().PIC != null){
                    var totalPIC = a.state.totalPIC
                    totalPIC = totalPIC + parseFloat(doc.data().PIC)
                    a.setState({totalPIC : totalPIC})
                }

                if(doc.data().day != null){
                    var totalDay = a.state.totalDay
                    totalDay = totalDay + parseFloat(doc.data().day)
                    a.setState({totalDay : totalDay})
                }

                if(doc.data().night != null){
                    var totalNight = a.state.totalNight
                    totalNight = totalNight + parseFloat(doc.data().night)
                    a.setState({totalNight : totalNight})
                }

                if(doc.data().XC != null){
                    var totalXC = a.state.totalXC
                    totalXC = totalXC + parseFloat(doc.data().XC)
                    a.setState({totalXC : totalXC})
                }

                if(doc.data().IFRAct != null){
                    var totalIFRAct = a.state.totalIFRAct
                    totalIFRAct = totalIFRAct + parseFloat(doc.data().IFRAct)
                    a.setState({totalIFRAct : totalIFRAct})
                }

                if(doc.data().IFRSim != null){
                    var totalIFRSim = a.state.totalIFRSim
                    totalIFRSim = totalIFRSim + parseFloat(doc.data().IFRSim)
                    a.setState({totalIFRSim : totalIFRSim})
                }

                if(doc.data().approaches != null){
                    var totalApproaches = a.state.totalApproaches
                    totalApproaches = totalApproaches + parseFloat(doc.data().approaches)
                    a.setState({totalApproaches : totalApproaches})
                }
                
                if(doc.data().dayLanding != null){
                    var totalDayLandings = a.state.totalDayLandings
                    totalDayLandings = totalDayLandings + parseFloat(doc.data().dayLanding)
                    a.setState({totalDayLandings : totalDayLandings})
                }

                if(doc.data().nightLanding != null){
                    var totalNightLandings = a.state.totalNightLandings
                    totalNightLandings = totalNightLandings + parseFloat(doc.data().nightLanding)
                    a.setState({totalNightLandings : totalNightLandings})
                }
            })
        })
    }


    getLogbookActivities(){
        let userName = firebase.auth().currentUser.displayName
        this.setState({logEntries : []})
        this.setState({totalASEL : 0})
        this.setState({totalAMEL : 0})
        this.setState({totalGroundTrainer : 0})
        this.setState({totalDualReceived : 0})
        this.setState({totalPIC : 0})
        this.setState({totalDay : 0})
        this.setState({totalNight : 0})
        this.setState({totalXC : 0})
        this.setState({totalIFRAct : 0})
        this.setState({totalIFRSim : 0})
        this.setState({totalApproaches : 0})
        this.setState({totalDayLandings : 0})
        this.setState({totalnightLandings : 0})
        this.setState({totalFlightTime : 0})
        console.log("GETTING LOGBOOK ACTIVITIES")
        let db = firebase.firestore()
        let startDate = this.state.startDate
        let endDate = this.state.endDate
        var docRef = db.collection('users').doc(userName).collection('logbook').orderBy("date").where("date", ">", startDate).where("date", "<", endDate)
        let a = this
        docRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                var holder = a.state.logEntries
                console.log(doc.data().date)
                var dateHolder = new Date(doc.data().date.seconds*1000)
                if(doc.data().aircraftMake != null){
                holder.push( <TableRow>
                    <TableCell>{(dateHolder.getMonth() + 1) + '-' + dateHolder.getDate() + '-' + dateHolder.getFullYear()}</TableCell>
                    <TableCell>{doc.data().aircraftMake}</TableCell>
                    <TableCell>{doc.data().aircraftID}</TableCell>
                    <TableCell>{doc.data().routeOfFlight}</TableCell>
                    <TableCell>{doc.data().ASEL}</TableCell>
                    <TableCell>{doc.data().AMEL}</TableCell>
                    <TableCell>{doc.data().groundTrainer}</TableCell>
                    <TableCell>{doc.data().dualReceived}</TableCell>
                    <TableCell>{doc.data().PIC}</TableCell>
                    <TableCell>{doc.data().day}</TableCell>
                    <TableCell>{doc.data().night}</TableCell>
                    <TableCell>{doc.data().XC}</TableCell>
                    <TableCell>{doc.data().IFRAct}</TableCell>
                    <TableCell>{doc.data().IFRSim}</TableCell>
                    <TableCell>{doc.data().Approaches}</TableCell>
                    <TableCell>{doc.data().dayLanding}</TableCell>
                    <TableCell>{doc.data().nightLanding}</TableCell>
                    <TableCell>{parseFloat(doc.data().ASEL) + parseFloat(doc.data().AMEL)}</TableCell>
                    </TableRow>)
                a.setState({logEntries : holder})
                }

               
                if(doc.data().ASEL != null){
                    var totalASEL = a.state.totalASEL
                    totalASEL = totalASEL + parseFloat(doc.data().ASEL)
                    a.setState({totalASEL : totalASEL})
                    var totalFlightHolder = a.state.totalFlightTime
                    totalFlightHolder = totalFlightHolder + parseFloat(doc.data().ASEL)
                    a.setState({totalFlightTime : totalFlightHolder})
                }

                if(doc.data().AMEL != null){
                    var totalAMEL = a.state.totalAMEL
                    totalAMEL = totalAMEL + parseFloat(doc.data().AMEL)
                    a.setState({totalAMEL : totalAMEL})
                    var totalFlightHolder = a.state.totalFlightTime
                    totalFlightHolder = totalFlightHolder + parseFloat(doc.data().AMEL)
                    a.setState({totalFlightTime : totalFlightHolder})
                }
                
                if(doc.data().groundTrainer != null){
                    var totalGroundTrainer = a.state.totalGroundTrainer
                    totalGroundTrainer = totalGroundTrainer + parseFloat(doc.data().groundTrainer)
                    a.setState({totalGroundTrainer : totalGroundTrainer})
                }
                if(doc.data().dualReceived != null){
                    var totalDualReceived = a.state.totalDualReceived
                    totalDualReceived = totalDualReceived + parseFloat(doc.data().dualReceived)
                    a.setState({totalDualReceived : totalDualReceived})
                }

                if(doc.data().PIC != null){
                    var totalPIC = a.state.totalPIC
                    totalPIC = totalPIC + parseFloat(doc.data().PIC)
                    a.setState({totalPIC : totalPIC})
                }

                if(doc.data().day != null){
                    var totalDay = a.state.totalDay
                    totalDay = totalDay + parseFloat(doc.data().day)
                    a.setState({totalDay : totalDay})
                }

                if(doc.data().night != null){
                    var totalNight = a.state.totalNight
                    totalNight = totalNight + parseFloat(doc.data().night)
                    a.setState({totalNight : totalNight})
                }

                if(doc.data().XC != null){
                    var totalXC = a.state.totalXC
                    totalXC = totalXC + parseFloat(doc.data().XC)
                    a.setState({totalXC : totalXC})
                }

                if(doc.data().IFRAct != null){
                    var totalIFRAct = a.state.totalIFRAct
                    totalIFRAct = totalIFRAct + parseFloat(doc.data().IFRAct)
                    a.setState({totalIFRAct : totalIFRAct})
                }

                if(doc.data().IFRSim != null){
                    var totalIFRSim = a.state.totalIFRSim
                    totalIFRSim = totalIFRSim + parseFloat(doc.data().IFRSim)
                    a.setState({totalIFRSim : totalIFRSim})
                }

                if(doc.data().approaches != null){
                    var totalApproaches = a.state.totalApproaches
                    totalApproaches = totalApproaches + parseFloat(doc.data().approaches)
                    a.setState({totalApproaches : totalApproaches})
                }
                
                if(doc.data().dayLanding != null){
                    var totalDayLandings = a.state.totalDayLandings
                    totalDayLandings = totalDayLandings + parseFloat(doc.data().dayLanding)
                    a.setState({totalDayLandings : totalDayLandings})
                }

                if(doc.data().nightLanding != null){
                    var totalDayLandings = a.state.totalDayLandings
                    totalDayLandings = totalDayLandings + parseFloat(doc.data().dayLanding)
                    a.setState({totalDayLandings : totalDayLandings})
                }
            })
        })
    }

    processLogbookCSV(acceptedFiles){
        
        if(acceptedFiles[0].type == "application/vnd.ms-excel"){

        }else{
            alert("Error : Wrong File Type")
        }
    }



    handleOnDrop = (data) => {
        
        
        let a = this
        if(data[0]["data"][0].toString() == "ETA " || data[0]["data"][0].toString() == "ETA"){
            for(var i = 0; i < data.length; i++){
                var holder = a.state.importActivities
                holder.push({
                    date : new Date(data[i]["data"][27]),
                    ACType : data[i]["data"][28],
                    ACId : data[i]["data"][29],
                    IP : data[i]["data"][30],
                    STU1 : data[i]["data"][31],
                    STU2 : data[i]["data"][32],
                    ASEL : data[i]["data"][33],
                    AMEL : data[i]["data"][34],
                    Night : data[i]["data"][35],
                    IFRACT : data[i]["data"][36],
                    IFRSim : data[i]["data"][37],
                    FLTSim : data[i]["data"][38],
                    XC : data[i]["data"][39],
                    FLTINSTRUCTION : data[i]["data"][40],
                    DAULREC : data[i]["data"][41],
                    PIC : data[i]["data"][42],
                    TOTALFLT : data[i]["data"][43],
                    docID : a.simplehash(data[i]["data"][27] + data[i]["data"][28] + data[i]["data"][29] + data[i]["data"][30] + data[i]["data"][31] + data[i]["data"][32] + data[i]["data"][33] + data[i]["data"][34] + data[i]["data"][35] + data[i]["data"][36] + data[i]["data"][37] + data[i]["data"][38] + data[i]["data"][39] + data[i]["data"][40] + data[i]["data"][41] + data[i]["data"][42] + data[i]["data"][43])
                })
                console.log(holder[i].date)
                a.setState({importActivities : holder})
                

                var tableholder = a.state.importHoldActivities
                var dateHolder = new Date(data[i]["data"][27])
                tableholder.push( <TableRow>
                    <TableCell>{dateHolder.toString()}</TableCell>
                    <TableCell>{data[i]["data"][28]}</TableCell>
                    <TableCell>{data[i]["data"][29]}</TableCell>
                    <TableCell>{data[i]["data"][30]}</TableCell>
                    <TableCell>{data[i]["data"][31]}</TableCell>
                    <TableCell>{data[i]["data"][32]}</TableCell>
                    <TableCell>{data[i]["data"][33]}</TableCell>
                    <TableCell>{data[i]["data"][34]}</TableCell>
                    <TableCell>{data[i]["data"][38]}</TableCell>
                    <TableCell>{data[i]["data"][41]}</TableCell>
                    <TableCell>{data[i]["data"][40]}</TableCell>
                    <TableCell>{data[i]["data"][42]}</TableCell>
                    <TableCell>{data[i]["data"][35]}</TableCell>
                    <TableCell>{data[i]["data"][39]}</TableCell>
                    <TableCell>{data[i]["data"][36]}</TableCell>
                    <TableCell>{data[i]["data"][37]}</TableCell>
                    <TableCell>{data[i]["data"][43]}</TableCell>
                    </TableRow>)
            }
        }else{
            alert('DOC UNSUPPORTED')
        }
        
      }

      simplehash(info){
        var len = info.length;

	    var hash = 0;
        for (var i = 0; i < info.length; i++) {
            var char = info.charCodeAt(i);
            hash = ((hash<<5)-hash)+char;
            hash = hash & hash; // Convert to 32bit integer
        }
        
        var hashStr = (hash.toString(36))
        if(hash < 0){
            hash = hash * -1
            hashStr = 'a' + (hash.toString(36))
        }
        
        
	    return hashStr;
        
      }
     
      handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
      }
     
      handleOnRemoveFile = (data) => {
        
        this.setState({importHoldActivities : []})
        this.setState({importActivities : []})
      }

    importSubmit(){
        let userName = firebase.auth().currentUser.displayName
        let db = firebase.firestore()
        let docRef = db.collection("users").doc(userName).collection("logbook")
        
        let a = this
        for(var i = 0; i < a.state.importActivities.length; i++){
            
            if(a.state.importActivities[i].docID != 0){
                docRef.doc(a.state.importActivities[i].docID.toString()).set({
                    ASEL : a.state.importActivities[i].ASEL,
                    AMEL : a.state.importActivities[i].AMEL,
                    IFRAct : a.state.importActivities[i].IFRACT,
                    IFRSim : a.state.importActivities[i].IFRSim,
                    PIC : a.state.importActivities[i].PIC,
                    XC : a.state.importActivities[i].XC,
                    aircraftID : a.state.importActivities[i].ACId,
                    aircraftMake : a.state.importActivities[i].ACType,
                    date : a.state.importActivities[i].date,
                    dualReceived : a.state.importActivities[i].DAULREC,
                    durationOfFlight : a.state.importActivities[i].TOTALFLT,
                    night : a.state.importActivities[i].Night,
                    groundTrainer : a.state.importActivities[i].FLTSim
                })
            }
        }
        
    }

    importButtonClicked(){
        this.setState({importDialogOpen : true})
    }

    closeImportModal(){
        this.setState({importDialogOpen : false})
    }

    setStartDate(date){
        console.log(date)
        this.setState({startDate : date}, () => this.getLogbookActivities)
        
    }

    setEndDate(date){
        
        this.setState({endDate : date}, () => this.getLogbookActivities)
    }

    exportButtonClicked(){
        this.setState({exportDialogOpen : true})
    }

    dismissExportDialog(){
        this.setState({exportDialogOpen : false})
    }


    exportLogbook(){
        var csvData = [
            ['ForeFlight Logbook Import'],
            [],
            ['Aircraft Table'],
            ['Text','Text','Text','YYYY','Text','Text','Text','Text','Text','Text','Boolean','Boolean'],
            ['AircraftId','EquipmentType','TypeCode','Year','Make','Model','Categoty','Class','GearType','EngineType','Complex','HighPerformance','Pressurized'],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            ['Flights Table'],
            ['Date','Text','Text','']
        ]

        var csvData3 = [
            ["Foreflight Logbook Import"],
            ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""  ],
            ["Aircraft Table","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""  ],
            ["Text","Text","Text","YYYY","Text","Text","Text","Text","Text","Text","Boolean","Boolean","Boolean","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""  ],
            ["AircraftID","EquipmentType","TypeCode","Year","Make","Model","Category","Class","GearType","EngineType","Complex","HighPerformance","Pressurized","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""  ],
            ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""  ],
            ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""  ],
            ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""  ],
            ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""  ],
            ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""  ],
            ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""  ],
            ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""  ],
            ["Flights Table","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","#;type;runway;airport;comments","","","","","","","","","","","","name;role;email","","","","","","","","","","","","","","",""  ],
            ["Date","Text","Text","Text","Text","hhmm","hhmm","hhmm","hhmm","hhmm","hhmm","Decimal","Decimal","Decimal","Decimal","Decimal","Decimal","Decimal","Number","Number","Number","Number","Number","Decimal","Decimal","Decimal","Decimal","Decimal","Decimal","Number","Packed Detail","Packed Detail","Packed Detail","Packed Detail","Packed Detail","Packed Detail","Decimal","Decimal","Decimal","Decimal","Text","Text","Packed Detail","Packed Detail","Packed Detail","Packed Detail","Packed Detail","Packed Detail","Boolean","Boolean","Boolean","Text","Decimal","Decimal","Number","Date","Boolean","Text"  ],
            ["Date","AircraftID","From","To","Route","TimeOut","TimeOff","TimeOn","TimeIn","OnDuty","OffDuty","TotalTime","PIC","SIC","Night","Solo","CrossCountry","Distance","DayTakeoffs","DayLandingsFullStop","NightTakeoffs","NightLandingsFullStop","AllLandings","ActualInstrument","SimulatedInstrument","HobbsStart","HobbsEnd","TachStart","TachEnd","Holds","Approach1","Approach2","Approach3","Approach4","Approach5","Approach6","DualGiven","DualReceived","SimulatedFlight","GroundTraining","InstructorName","InstructorComments","Person1","Person2","Person3","Person4","Person5","Person6","FlightReview","Checkride","IPC","[Text]CustomFieldName","[Numeric]CustomFieldName","[Hours]CustomFieldName","[Counter]CustomFieldName","[Date]CustomFieldName","[Toggle]CustomFieldName","PilotComments"  ]
        ]

        
    }
    render(){
        return(
            <div>
                
            <Card>
                <Grid container>
                <Grid item xs={3}>
                <h4>Start Date:</h4>
                <DatePicker
                    onChange={this.setStartDate}
                    value={this.state.startDate}
                />
                </Grid>
                <Grid item xs ={3}>
                    <h4>End Date:</h4>
                    <DatePicker
                    onChange={this.setEndDate}
                    value={this.state.endDate}
                />
                </Grid>
                <Grid item xs ={3}>
                    <Button onClick = {this.getLogbookActivities}>Filter</Button>
                </Grid>
                <Grid item xs={3} style = {{align : "right"}}>
                    <Button onClick = {this.importButtonClicked}>Import</Button>
                    <Button onClick = {this.exportButtonClicked}>Export</Button>
                </Grid>
                </Grid>
            </Card>
            <div>
                <h1></h1>
            </div>
            <Paper>
                <TableContainer>
                    <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Aircraft Make</TableCell>
                        <TableCell>Aircraft ID</TableCell>
                        <TableCell>Route</TableCell>
                        <TableCell>ASEL</TableCell>
                        <TableCell>AMEL</TableCell>
                        <TableCell>Ground Trainer</TableCell>
                        <TableCell>Dual Received</TableCell>
                        <TableCell>PIC</TableCell>
                        <TableCell>Day</TableCell>
                        <TableCell>Night</TableCell>
                        <TableCell>XC</TableCell>
                        <TableCell>IFR Act.</TableCell>
                        <TableCell>IFR Sim.</TableCell>
                        <TableCell>Approaches</TableCell>
                        <TableCell>Day Landings</TableCell>
                        <TableCell>Night Landings</TableCell>
                        <TableCell>Total Flight Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{parseFloat(this.state.totalASEL).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalAMEL).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalGroundTrainer).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalDualReceived).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalPIC).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalDay).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalNight).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalXC).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalIFRAct).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalIFRSim).toFixed(1)}</TableCell>
                        <TableCell>{this.state.totalApproaches}</TableCell>
                        <TableCell>{this.state.totalDayLandings}</TableCell>
                        <TableCell>{this.state.totalNightLandings}</TableCell>
                        <TableCell>{parseFloat(this.state.totalFlightTime).toFixed(1)}</TableCell>
                        </TableRow>
                    </TableHead>
                    
                    <TableBody>
                       {this.state.logEntries}
                    </TableBody>
                    
                    <TableHead>
                    <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{parseFloat(this.state.totalASEL).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalAMEL).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalGroundTrainer).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalDualReceived).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalPIC).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalDay).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalNight).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalXC).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalIFRAct).toFixed(1)}</TableCell>
                        <TableCell>{parseFloat(this.state.totalIFRSim).toFixed(1)}</TableCell>
                        <TableCell>{this.state.totalApproaches}</TableCell>
                        <TableCell>{this.state.totalDayLandings}</TableCell>
                        <TableCell>{this.state.totalNightLandings}</TableCell>
                        <TableCell>{parseFloat(this.state.totalFlightTime).toFixed(1)}</TableCell>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Aircraft Make</TableCell>
                        <TableCell>Aircraft ID</TableCell>
                        <TableCell>Route</TableCell>
                        <TableCell>ASEL</TableCell>
                        <TableCell>AMEL</TableCell>
                        <TableCell>Ground Trainer</TableCell>
                        <TableCell>Dual Received</TableCell>
                        <TableCell>PIC</TableCell>
                        <TableCell>Day</TableCell>
                        <TableCell>Night</TableCell>
                        <TableCell>XC</TableCell>
                        <TableCell>IFR Act.</TableCell>
                        <TableCell>IFR Sim.</TableCell>
                        <TableCell>Approaches</TableCell>
                        <TableCell>Day Landings</TableCell>
                        <TableCell>Night Landings</TableCell>
                        <TableCell>Total Flight Time</TableCell>
                        </TableRow>
                    </TableHead>
                    </Table>
                </TableContainer>
            </Paper>

            <Modal aria-labelledby="simple-dialog-title" open={this.state.importDialogOpen}  BackDropComponent={Backdrop} onClose = {this.closeImportModal}>
                <Card style = {{width : '75%', textAlign : "center", overflowX : "auto"}}>
                    <h1>Import Logbook</h1>
                    <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        onRemoveFile={this.handleOnRemoveFile}
                    >
                    <span>Drop CSV file here or click to upload.</span>
                    </CSVReader>
                    <Paper style = {{
                        height: 300,
                        width: '100%',
                        overflow: 'auto',
                    }}>
                    <Table style = {{minWidth : 750, overflowX: 'auto'}}> 
                    <TableHead>
                        <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Aircraft Make</TableCell>
                        <TableCell>Aircraft ID</TableCell>
                        <TableCell>IP</TableCell>
                        <TableCell>Student 1</TableCell>
                        <TableCell>Student 2</TableCell>
                        <TableCell>ASEL</TableCell>
                        <TableCell>AMEL</TableCell>
                        <TableCell>Ground Trainer/Flight Sim</TableCell>
                        <TableCell>Dual Received</TableCell>
                        <TableCell>Dual Given</TableCell>
                        <TableCell>PIC</TableCell>
                        <TableCell>Night</TableCell>
                        <TableCell>XC</TableCell>
                        <TableCell>IFR Act.</TableCell>
                        <TableCell>IFR Sim.</TableCell>
                        <TableCell>Total Flight Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.importHoldActivities}
                    </TableBody>
                    </Table>
                    </Paper>
                    <Button style = {{width : '100%'}} onClick = {this.importSubmit}>Submit</Button>
                </Card>
            </Modal>


            <Modal aria-labelledby="simple-dialog-title" open={this.state.exportDialogOpen}  BackDropComponent={Backdrop} onClose = {this.dismissExportDialog}>
                    <Card style = {{textAlign : "center"}}>
                        <h1>Export Logbook</h1>
                        <h4>Start Date</h4>
                        <DatePicker onChange={this.setStartDate} value={this.state.startDate}/>
                        <h4>End Date</h4>
                        <DatePicker onChange={this.setEndDate} value={this.state.endDate}/>
                        <h4>Format</h4>
                        <CSVLink data={csvData2}>Download</CSVLink>
                    </Card>
            </Modal>




            






            </div>
        );
    }
}

export default Logbook;
