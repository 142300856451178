import React, { Component } from "react";
import * as firebase from "firebase";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Clock from "react-live-clock";
class HomeBase extends Component {
  render() {
    return (
      <Card>
        <Grid container>
          <h1>Current Status : HOLD </h1>
          <h1>
            <Clock format={"HH:mm:ss"} ticking={true} timezone={"US/Arizona"} />
          </h1>
        </Grid>
      </Card>
    );
  }
}

export default HomeBase;
