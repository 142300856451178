import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AircraftEditView from "./aircraftEditView";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import c172 from "./c172.png";
import AircraftDeleteView from "./aircraftDeleteConform";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function AircraftAdmin(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  let a = this;
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleToggleDelete = () => {
    setOpenDelete(!openDelete);
  };
  function dismiss() {
    setOpen(false);
  }
  var type = props.type
  if(type != "c172" && type!="da42" && type!="8KCAB"){
    type = "Seer_logo"
  }
  return (
    <Card style={aircraftCardStyle}>
      <h1>{props.callsign}</h1>
      <img src={require("./" + type + ".png")} width="75%"></img>
      <h2>{props.formalName}</h2>
      <h2>{props.tailNumber}</h2>

      <h2>Empty Weight : {props.emptyWeight}</h2>
      <h2>Moment : {props.moment}</h2>
      <h2>CG : {props.CG}</h2>
      <h2>Useful Load : {props.usefulLoad}</h2>
      <h2>Gross Weight : {props.grossWeight}</h2>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleToggle}
      >
        Edit
      </Button>
      <Button
        variant="contained"
        color="Secondary"
        size="large"
        onClick={handleToggleDelete}
      >
        Delete
      </Button>
      <Backdrop className={classes.backdrop} open={open}>
        <AircraftEditView
          onClose={handleClose}
          resourceID={props.callsign}
          nNumber={props.tailNumber}
          emptyWeight={props.emptyWeight}
          moment={props.moment}
          cg={props.CG}
          usefulLoad={props.usefulLoad}
          grossWeight={props.grossWeight}
        ></AircraftEditView>
      </Backdrop>
      <Backdrop className={classes.backdrop} open={openDelete}>
        <AircraftDeleteView
          resourceID={props.callsign}
          onClose={handleCloseDelete}
        ></AircraftDeleteView>
      </Backdrop>
    </Card>
  );
}

const aircraftCardStyle = {
  textAlign: "center",
  marginBottom: "50px",
  marginLeft: "5%",
  width: "400px",
};
