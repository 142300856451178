import React, { Component } from "react";
import CouseCreateUnit from "./courseCreateUnit";
class CouseCreateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      units: this.props.unit,
      generatedUnits: [],
      hasBeenRendered: false,
      pushBack: this.props.pushback,
    };
    this.generateCourseUnits = this.generateCourseUnits.bind(this);
    this.generateUnit = this.generateUnit.bind(this);
  }
  generateCourseUnits() {
    if (this.state.units != 0) {
      var coms = [];
      for (var i = 0; i < this.state.units; i++) {
        coms.push(
          <CouseCreateUnit
            id={i}
            pushBack={this.generateUnit}
          ></CouseCreateUnit>
        );
      }
      return coms;
    } else {
      return null;
    }
  }
  componentWillReceiveProps(props) {
    this.setState({ units: props.units });
  }
  generateUnit(index, type, unitName, data) {
    var holder = this.state.generatedUnits;
    holder[index] = [unitName, type, data];
    this.setState({ generatedUnits: holder }, () =>
      this.state.pushBack(this.state.generatedUnits)
    );
    console.log(this.state.generatedUnits);
  }
  render() {
    return <div>{this.generateCourseUnits()}</div>;
  }
}

export default CouseCreateContainer;
